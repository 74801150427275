import { useSelector } from 'react-redux';
import { useGetSellerStockOutItemsMutation } from '../../features/auth/authApiSlice';
import { selectCurrentUserId } from '../../features/auth/authSlice';
import { useEffect, useState } from 'react';
import SellerCard from './sellerCard';
import { useTranslation } from 'react-i18next';

function StockOutProducts({ selectedSidebarItem, onSelectSidebarItem }) {
  const sellerId = useSelector(selectCurrentUserId);
  const [fetchedItems, setFetchedItems] = useState([]);
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const [getSellerStockOutItems, { isSellerLoading }] =
    useGetSellerStockOutItemsMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const result = await getSellerStockOutItems({
          sellerId: sellerId,
        }).unwrap();
        

        setFetchedItems(result);

        setIsFetching(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [sellerId]);

  const rerender = async () => {
    try {
      setIsFetching(true);
      const result = await getSellerStockOutItems({
        sellerId: sellerId,
      }).unwrap();
      

      setFetchedItems(result);

      setIsFetching(false);
      // Update state with the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const currentPage = 1;
  return (
    <>
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`  px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
        >
          <div className="w-full mb-4 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {t('stockOutProducts')}
          </div>
          <div className="flex md:flex-wrap gap-4 scrollpart">
            {fetchedItems.map((item, index) => (
              <SellerCard
                key={index}
                item={item}
                selectedSidebarItem={selectedSidebarItem}
                onSelectSidebarItem={onSelectSidebarItem}
                handlePageChange={rerender}
                page={currentPage}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default StockOutProducts;
