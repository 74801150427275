import { useTranslation } from "react-i18next";
import { setProductToBeDetailedId } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SliderItem = ({ slide, index }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="w-full h-full py-4 sm:py-0">
        {slide.discountAmount ? (
          <div className="slide-img grid grid-cols-2 gap-2 justify-items-center items-center">
            <div className="flex flex-col justify-items-center pl-5 md:pl-2">
              <div className=" text-black text-[24px] md:text-[45px] font-medium font-['Poppins'] leading-12 tracking-tightl">
                {`${slide.discountAmount}% ${t("off")}`}
              </div>
              <div className="text-black text-[26px] mt-2 font-normal font-['Poppins'] leading-7 tracking-tight pt-2 pb-6 lg:pb-10">
                {slide.itemName}
              </div>
              <button
                onClick={() => {
                  dispatch(
                    setProductToBeDetailedId({
                      productId: slide._id,
                    })
                  );
                  navigate("/productdetail");
                }}
                className="text-white text-[17px] font-semibold font-['Poppins'] leading-7 tracking-tight w-[180px] h-[52px] px-[20px] py-3 bg-red-500 rounded-[10px] justify-center items-center gap-2.5 inline-flex    hover:bg-red-400"
              >
                {t("getitnow")}
              </button>
            </div>
            <div className="p-6">
              <img
                className="64 md:max-h-72 w-auto"
                src={slide.avatarUrl[0]}
                alt={slide.itemName}
              />
            </div>
          </div>
        ) : undefined}
        {!slide.discountAmount ? (
          <img
            className="slide-img"
            src={slide.imageSrc}
            alt={`Slide ${index + 1}`}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default SliderItem;
