import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useGetCatagoriesByTypeMutation,
  useGetItemsByChildCatagoryMutation,
  useGetItemsByMainCatagoryMutation,
  useGetItemsBySubCatagoryMutation,
} from '../features/auth/authApiSlice';
import categoriesData from '../assets/json/categories';
import { useTranslation } from 'react-i18next';

function NavCategories() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getItemsBySubCatagory, { isSubLoading }] =
    useGetItemsBySubCatagoryMutation();
  const [getItemsByChildCatagory, { isChildLoading }] =
    useGetItemsByChildCatagoryMutation();
  const [getItemsByMainCatagory, { isMainCatagoryLoading }] =
    useGetItemsByMainCatagoryMutation();

  const [getCatagoriesByType, { isSellerByPageLoading }] =
    useGetCatagoriesByTypeMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [GlobalCatagoryData, setGlobalCatagoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const mainCatagories = await getCatagoriesByType({
          type: 'mainCatagory',
        }).unwrap();

        const subCatagories = await getCatagoriesByType({
          type: 'subCatagory',
        }).unwrap();

        const childcatagories = await getCatagoriesByType({
          type: 'childCatagory',
        }).unwrap();

        const catagoryData = [];
        // console.log(childcatagories[0].parent);
        // console.log(subCatagories[0].id);
        mainCatagories.map((mainCatagory) => {
          const subCatagoriesList = [];
          const childcatagoriesList = [];
          subCatagories.map((subCatagory) => {
            console.log(mainCatagory);
            if (subCatagory.parent === mainCatagory._id) {
              const subitem = {
                name: subCatagory.name,
                param: subCatagory.name.slice(0, -1),
              };
              if (subCatagoriesList.length < 5) {
                subCatagoriesList.push(subitem);
              }

              childcatagories.map((childCatagory) => {
                if (childCatagory.parent === subCatagory._id) {
                  const childItem = {
                    name: childCatagory.name,
                    param: childCatagory.name.slice(0, -1),
                  };
                  if (childcatagoriesList.length < 5) {
                    childcatagoriesList.push(childItem);
                  }
                }
              });
            }

            
          });

          const catagoryDataItem = {
            name: mainCatagory.name,
            t: mainCatagory.name.toLowerCase(),
            subcategories: subCatagoriesList,
            brands: childcatagoriesList,
          };
          if (catagoryData.length < 12) {
            catagoryData.push(catagoryDataItem);
          }
        });

        console.log('catagoryData');
        console.log(catagoryData);
        setGlobalCatagoryData(catagoryData);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const handlegetMainCatagoryItems = async (mainCatagory) => {
    //console.log('searching...');
    //console.log(searchQuery);
    const SearchResults = await getItemsByMainCatagory({
      mainCatagory: mainCatagory,
    });
    //console.log('SearchResults');
    //console.log(SearchResults);

    navigate('/searchResultsPage', {
      state: {
        searchResults: SearchResults.data.items,
        query: mainCatagory,
        subcatagories: SearchResults.data.subCatagories,
      },
    });
    // Add logic here to handle the search action based on the searchQuery.
  };

  const handlegetSubCatagoryItems = async (subCatagory) => {
    //console.log('searching...');
    //console.log(searchQuery);
    const SearchResults = await getItemsBySubCatagory({
      subCatagory: subCatagory,
    });
    //console.log('SearchResults');
    //console.log(SearchResults);

    navigate('/searchResultsPage', {
      state: {
        searchResults: SearchResults.data.items,
        query: subCatagory,
        subcatagories: SearchResults.data.subCatagories,
      },
    });
    // Add logic here to handle the search action based on the searchQuery.
  };

  const handlegetChildCatagoryItems = async (childCatagory) => {
    //console.log('searching...');
    //console.log(searchQuery);
    const SearchResults = await getItemsByChildCatagory({
      childCatagory: childCatagory,
    });
    //console.log('SearchResults');
    //console.log(SearchResults);

    navigate('/searchResultsPage', {
      state: { searchResults: SearchResults.data, query: childCatagory },
    });
    // Add logic here to handle the search action based on the searchQuery.
  };

  return (
    <div className="scrollable mt-8 mb-2 pb-1 pl-8 w-full border-gray-600 border-b">
      <ul className="flex text-black text-[13px] text-sm gap-8 px-[4%]">
        <li className="block w-max">
          <Link
            className="w-max block hover:text-purple-600 hover:border-b hover:border-purple-600"
            to="/"
          >
            {t('home')}
          </Link>
        </li>

        {GlobalCatagoryData?.map((item) => (
          <li className="block w-max">
            <Link
              className="hover:text-purple-600 hover:border-b-2 hover:border-purple-600"
              onClick={() => handlegetMainCatagoryItems(item.name)}
            >
              {t(item.t)}
            </Link>
            <ul className="dropdown-content px-3 pb-3 bg-slate-50 leading-7 rounded-sm">
              <li className="my-2 w-16 border-gray-600 border-b">
                {t('categories')}
              </li>
              <>
                {item.subcategories.map((item) => (
                  <li
                    className="hover:text-purple-600 cursor-pointer"
                    onClick={() => handlegetSubCatagoryItems(item.param)}
                  >
                    {t(item.name)}
                  </li>
                ))}
              </>
              <li className="my-2 w-16 border-gray-600 border-b">
                {t('brands')}
              </li>
              {item.brands.map((item) => (
                <li
                  className="hover:text-purple-600 cursor-pointer"
                  onClick={() => handlegetChildCatagoryItems(item.param)}
                >
                  {t(item.name)}
                </li>
              ))}
            </ul>
          </li>
        ))}

        <li className="block w-max">
          <Link
            className="hover:text-purple-600 hover:border-b hover:border-purple-600"
            to="/signupseller"
          >
            {t('sell')}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NavCategories;
