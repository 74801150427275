import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PrivacyText from "./PrivacyText";
import { useGetPrivacyPolicyyMutation } from "../features/auth/authApiSlice";
import LoadingPlaceholder from "../components/LoadingPlaceholder";

const PrivacyPolicy = () => {
  const [getPrivacyPolicyy, { isLoading: isGettiing }] =
    useGetPrivacyPolicyyMutation();
  const [privacyPolicies, setPrivacyPolicy] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getPrivacyPolicyy({
          type: "privacyPolicy",
        }).unwrap();
        console.log("result");
        console.log(result);

        setPrivacyPolicy(result);

        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const linkify = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div>
      <Navbar />
      {isGettiing ? (
        <LoadingPlaceholder />
      ) : (
        <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
          <div className="container mx-auto my-8 p-4">
            {privacyPolicies &&
              privacyPolicies?.map((policy) => (
                <div className="mb-4">
                  <h2 className="text-lg">
                    <strong>{policy.title}</strong>
                  </h2>
                  {policy.paragraph.map((paragraph) => (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {linkify(paragraph)}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
