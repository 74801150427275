import { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PiShoppingCartFill } from "react-icons/pi";
import Cookies from "js-cookie";

import {
  FaHome,
  FaListAlt,
  FaChevronUp,
  FaChevronDown,
  FaMoneyBillWave,
} from "react-icons/fa";
import { SiProducthunt } from "react-icons/si";
import { LiaFirstOrder } from "react-icons/lia";
import { RiCouponFill, RiLuggageDepositFill } from "react-icons/ri";
import "../../styles/sellerDashboard.css";
import Dropdown from "./dropdown";
import SalesChart from "./SalesChart";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCanceledOrders,
  selectCurrentDeliveredOrders,
  selectCurrentEmail,
  selectCurrentName,
  selectCurrentOntheWayOrders,
  selectCurrentPendingOrders,
  selectCurrentPreparingOrders,
  selectCurrentSellerAdminData,
  selectCurrentUserImgUrl,
  setAllOrderStatus,
  setBuyer,
  setCredentials,
  setSelectedItem,
  setSellerAdminData,
  setUser,
} from "../../features/auth/authSlice";
import AddProduct from "./AddProduct";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import NotifyOrder from "./Sockeio.realtime";
import OrderDetailsPage from "./OrderDetailsPage";
import CreateCoupon from "./CreateCoupon";
import MyCouponsPage from "./MyCoupons";
import EditCouponPage from "./EditCouponPage";
import SellerEditProfile from "./EditProfile";
import AllProduct from "./AllProducts";
import EditProduct from "./EditProduct";
import StockOutProducts from "./StockOutProducts";
import { useTranslation } from "react-i18next";
import ResetPasswordBuyer from "../Buyer/ResetPaswordBuyer";
import LanguageSwitcher from "../languageSwitcher";
function SellerNavbar() {
  const location = useLocation();
  const sellerData = useSelector(selectCurrentSellerAdminData);

  const { t } = useTranslation();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState("Dashboard");

  const monthlySalesData = [
    100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650,
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const categoriesDropdownProps = {
    title: {
      text: t("profile"),
      icon: (
        <FaListAlt className="opacity-70 ml-4" style={{ fontSize: "24px" }} />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [t("edit_profile"), "Reset Password"],
    selectedSidebarItem: selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const productsDropdownProps = {
    title: {
      text: t("products"),
      icon: (
        <SiProducthunt
          className="opacity-70 ml-4"
          style={{ fontSize: "24px" }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [t("addProduct"), t("allProducts"), t("stockOutProducts")],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const ordersDropdownProps = {
    title: {
      text: "Orders",
      icon: (
        <LiaFirstOrder
          className="opacity-70 ml-4"
          style={{ fontSize: "24px" }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [
      "All Orders",
      "Pending Orders",
      "Progress Orders",
      "Delivered Orders",
      "Canceled Orders",
    ],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };
  const CouponsDropdownProps = {
    title: {
      text: t("coupons"),
      icon: (
        <RiCouponFill
          className="opacity-70 ml-4"
          style={{ fontSize: "24px" }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [t("createCoupon"), t("myCoupons")],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const LogoutDropdownProps = {
    title: {
      text: t("logout"),
      icon: (
        <RiCouponFill
          className="opacity-70 ml-4"
          style={{ fontSize: "24px" }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [t("logout")],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const handelLogout = () => {
    dispatch(
      setCredentials({
        user: null,
        accessToken: null,
        refreshtoken: null,
      })
    );
    dispatch(
      setUser({
        id: null,
        email: null,
        name: null,
        role: null,
        accountStatus: null,
        cart: null,
      })
    );
    dispatch(
      setBuyer({
        country: null,
        region: null,
        city: null,
        streetAddress: null,
        phoneNumber: null,
        postalCode: null,
        homeNumber: null,
        additionalStreetAddress: null,
        zipCode: null,
      })
    );

    const sellerData = {
      dailysales: null,
      weeklysales: null,
      monthlysales: null,
      yearlysales: null,
      monthlySaleList: null,
      totalSellerItemlength: null,
    };
    dispatch(setSellerAdminData({ data: sellerData }));
    dispatch(setAllOrderStatus());

    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    navigate("/");
  };

  const salesDropdownProps = {
    title: {
      text: t("sales"),
      icon: (
        <RiLuggageDepositFill
          className="opacity-70 ml-4"
          style={{ fontSize: "24px" }}
        />
      ),
      openIcon: (
        <FaChevronUp className="opacity-70 mr-2" style={{ fontSize: "14px" }} />
      ),
      closeIcon: (
        <FaChevronDown
          className="opacity-70 mr-2"
          style={{ fontSize: "14px" }}
        />
      ),
    },
    items: [
      "Total Sales",
      "Todays Sales",
      "This Month Sales",
      "This Year Sales",
    ],
    selectedSidebarItem,
    onSelectSidebarItem: setSelectedSidebarItem,
  };

  const pendingOrders = useSelector(selectCurrentPendingOrders);
  const preparingOrders = useSelector(selectCurrentPreparingOrders);
  const onthewayOrders = useSelector(selectCurrentOntheWayOrders);
  const canceledOrders = useSelector(selectCurrentCanceledOrders);
  const deliveredOrders = useSelector(selectCurrentDeliveredOrders);
  const sellerName = useSelector(selectCurrentName);
  const sellerImg = useSelector(selectCurrentUserImgUrl);

  const renderContent = () => {
    switch (selectedSidebarItem) {
      case "Dashboard":
        return (
          <div
            className={`  px-6 py-4 bg-gray-100`}
            style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
          >
            {/* Add your list of items or content in this section */}
            <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
              {t("dashboard")}
            </div>

            <div className=" flex flex-wrap mt-4">
              <div
                onClick={() => {
                  setSelectedSidebarItem("Pending Orders");
                  dispatch(setSelectedItem({ selectedItem: "Pending Orders" }));
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="text-white h-16 w-16 bg-violet-700 rounded-md flex justify-center items-center">
                  <i className="bx bxs-cart-alt bx-md"></i>
                </div>
                <div>
                  <p className="pt-2 w-32">{t("pendingorders")}</p>
                  <p>{pendingOrders.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem("Preparing Orders");
                  dispatch(
                    setSelectedItem({ selectedItem: "Preparing Orders" })
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" text-white h-16 w-16 bg-violet-700 rounded-md flex justify-center items-center">
                  <i className="bx bxs-cart-alt bx-md"></i>
                </div>
                <div>
                  <p className="pt-2 w-32">{t("preparingorders")}</p>
                  <p>{preparingOrders.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem("OntheWay Orders");
                  dispatch(
                    setSelectedItem({ selectedItem: "OntheWay Orders" })
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" text-white h-16 w-16 bg-violet-700 rounded-md flex justify-center items-center">
                  <i className="bx bxs-cart-alt bx-md"></i>
                </div>
                <div>
                  <p className="pt-2 w-32">{t("ontheway")}</p>
                  <p>{onthewayOrders.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem("Delivered Orders");
                  dispatch(
                    setSelectedItem({ selectedItem: "Delivered Orders" })
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" text-white h-16 w-16 bg-violet-700 rounded-md flex justify-center items-center">
                  <i className="bx bxs-cart-alt bx-md"></i>
                </div>
                <div>
                  <p className="pt-2 w-32">{t("deliveredorders")}</p>
                  <p>{deliveredOrders.length}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setSelectedSidebarItem("Cancelled Orders");
                  dispatch(
                    setSelectedItem({ selectedItem: "Cancelled Orders" })
                  );
                }}
                className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className=" text-white h-16 w-16 bg-violet-700 rounded-md flex justify-center items-center">
                  <i className="bx bxs-cart-alt bx-md"></i>
                </div>
                <div>
                  <p className="pt-2 w-32">{t("cancelledorders")}</p>
                  <p>{canceledOrders.length}</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: "30px", color: "#fff" }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t("todaySales")}</p>
                  <p>{sellerData.dailysales}€</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: "30px", color: "#fff" }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t("thisWeeksSales")}</p>
                  <p>{sellerData.weeklysales}€</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: "30px", color: "#fff" }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t("thisMonthSales")}</p>
                  <p>{sellerData.monthlysales}€</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-blue-500 rounded-md flex justify-center items-center">
                  <FaMoneyBillWave
                    style={{ fontSize: "30px", color: "#fff" }}
                  />
                </div>
                <div>
                  <p className="pt-2 w-32">{t("thisYearSales")}</p>
                  <p>{sellerData.yearlysales}€</p>
                </div>
              </div>
              <div className=" flex gap-3 mb-4 h-28 w-56 mr-3 rounded-md bg-white justify-center items-center">
                <div className=" h-16 w-16 bg-red-500 rounded-md flex justify-center items-center">
                  <SiProducthunt style={{ fontSize: "30px", color: "#fff" }} />
                </div>
                <div>
                  <p className="pt-2 w-32">{t("totalProducts")}</p>
                  <p>{sellerData.totalSellerItemlength}</p>
                </div>
              </div>{" "}
            </div>
            <div>
              <h2>{t("monthlySalesProgress")}</h2>

              <div
                className="border-2 flex p-8 rounded-lg "
                style={{ width: "670px" }}
              >
                {" "}
                <div className="flex-col">
                  <SalesChart data={sellerData.monthlySaleList} />
                  <div className="flex">
                    <p className="pt-1 ml-2">Jan</p>
                    <p className="pt-1 ml-6">Feb</p>
                    <p className="pt-1 ml-6">Mar</p>
                    <p className="pt-1 ml-6">Apr</p>
                    <p className="pt-1 ml-6">May</p>
                    <p className="pt-1 ml-6">Jun</p>
                    <p className="pt-1 ml-6">Jul</p>
                    <p className="pt-1 ml-6">Aug</p>
                    <p className="pt-1 ml-6">Sep</p>
                    <p className="pt-1 ml-6">Oct</p>
                    <p className="pt-1 ml-6">Nov</p>
                    <p className="pt-1 ml-6">Dec</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "Main Categories":
        return <div></div>;
      case t("addProduct"):
        return <AddProduct />;
      case t("createCoupon"):
        return <CreateCoupon />;
      case "Edit Coupon":
        return <EditCouponPage />;
      case t("edit_profile"):
        return <SellerEditProfile />;
      case "Reset Password":
        return (
          <div className="w-full bg-gray-100">
            <div className="w-full mt-4 ml-4 mb-5 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
              Reset Password
            </div>
            <ResetPasswordBuyer></ResetPasswordBuyer>
          </div>
        );
      case t("allProducts"):
        return (
          <AllProduct
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      case t("stockOutProducts"):
        return (
          <StockOutProducts
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      case t("logout"):
        return (
          <div className="w-full bg-gray-100 flex-col">
            <div className=" w-96 h-32 rounded-md">
              <p className="pl-2 py-4 text-lg text-gray-700 text-center">
                {t("logout_confirmation")}
              </p>
              <div className="flex justify-center">
                <button
                  onClick={handelLogout}
                  className="w-24 px-2 text-center py-1 rounded-md hover:bg-red-500 bg-red-600 text-white"
                >
                  {t("logout")}
                </button>
              </div>
            </div>
          </div>
        );
      case "Edit Product":
        return <EditProduct />;
      // Add cases for other sidebar items as needed
      case t("myCoupons"):
        return (
          <MyCouponsPage
            selectedSidebarItem={selectedSidebarItem}
            onSelectSidebarItem={setSelectedSidebarItem}
          />
        );
      // Add cases for other sidebar items as needed
      case "Pending Orders":
        return <OrderDetailsPage orderStatus="pending"></OrderDetailsPage>;
      case "Preparing Orders":
        return <OrderDetailsPage orderStatus="preparing"></OrderDetailsPage>;
      case "OntheWay Orders":
        return <OrderDetailsPage orderStatus="ontheway"></OrderDetailsPage>;
      case "Delivered Orders":
        return <OrderDetailsPage orderStatus="delivered"></OrderDetailsPage>;
      case "Cancelled Orders":
        return <OrderDetailsPage orderStatus="cancelled"></OrderDetailsPage>;

      default:
        return null; // Render nothing by default
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex w-full bg-white h-12 pt-3 shadow-md"
        style={{ borderBottom: "1px solid #ccc", width: "100%" }}
      >
        <h5
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="ml-12 text-violet-800 text-[24px] font-bold font-['Poppins'] leading-7 tracking-tight"
        >
          Saletoeurope
        </h5>
        <div className="ml-20">
          <button
            onClick={toggleSidebar}
            className="block text-black-600 focus:outline-none mr-4"
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: "24px" }} />
          </button>
        </div>
        <div></div>
        <div className="ml-auto mr-8 z-40">
          <LanguageSwitcher></LanguageSwitcher>
          <NotifyOrder></NotifyOrder>
        </div>
      </div>
      <div className="w-full  fixed top-12 bottom-0">
        <div className="flex">
          {isSidebarOpen && (
            <div
              className={`h-screen  bg-white w-56 ${
                isSidebarOpen ? "show" : ""
              } shadow-md  sidebar-slide-in`}
              style={{
                flexShrink: 0,
              }}
            >
              <div
                className="flex py-3 pl-4"
                style={{ borderBottom: "1px solid #ccc", width: "100%" }}
              >
                <img
                  src={sellerImg}
                  className={`h-14 w-14 rounded-full ${
                    !sellerImg ? "bg-blue-500" : "" // Apply blue background if buyerImg is null
                  }`}
                />
                <div className="pl-2 pt-2">
                  <p className="text-black text-opacity-70 text-sm">
                    {sellerName}
                  </p>
                  <p className="text-sm pt-1">{t("administrator")}</p>
                </div>
              </div>

              <div className="pt-4 ">
                <div
                  className={`${
                    selectedSidebarItem == "Dashboard" ? "bg-gray-200" : ""
                  } flex gap-4 items-center hover:bg-gray-100 w-full py-2 cursor-pointer`}
                  onClick={() => {
                    setSelectedSidebarItem("Dashboard");
                    dispatch(setSelectedItem({ selectedItem: "Dashboard" }));
                  }}
                >
                  <FaHome
                    className="opacity-70 ml-4"
                    style={{ fontSize: "24px" }}
                  />
                  <p className="text-opacity-75" style={{ fontSize: "16px" }}>
                    Dashboard
                  </p>
                </div>

                {/* Categories Dropdown */}

                <Dropdown {...categoriesDropdownProps} />
                <Dropdown {...productsDropdownProps} />
                <Dropdown {...CouponsDropdownProps} />
                <Dropdown {...LogoutDropdownProps} />
              </div>
            </div>
          )}
          {renderContent()}
        </div>
      </div>
    </>
  );
}

export default SellerNavbar;
