import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { useGetPrivacyPolicyyMutation } from "../features/auth/authApiSlice";
import LoadingPlaceholder from "../components/LoadingPlaceholder";

function RightOfWithdrawal() {
  const { t } = useTranslation();
  const [getPrivacyPolicyy, { isLoading: isGettiing }] =
    useGetPrivacyPolicyyMutation();
  const [privacyPolicies, setPrivacyPolicy] = useState([]);

  useState(() => {
    const fetchData = async () => {
      try {
        const result = await getPrivacyPolicyy({
          type: "rightOfWithDrawal",
        }).unwrap();
        console.log("result");
        console.log(result);

        setPrivacyPolicy(result);

        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const linkify = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div>
      <Navbar />
      {isGettiing ? (
        <LoadingPlaceholder />
      ) : (
        <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
          <div className="container mx-auto my-8 p-4">
            {privacyPolicies &&
              privacyPolicies?.map((policy) => (
                <div className="mb-4">
                  <h2 className="text-lg">
                    <strong>{policy.title}</strong>
                  </h2>
                  {policy.paragraph.map((paragraph) => (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {linkify(paragraph)}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default RightOfWithdrawal;

{
  /* <div>
<Navbar />
<div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
  <div className="container mx-auto my-8 p-4">
    <h1 className="text-2xl font-bold mb-4">
      {t("legal.cancellationPolicy")}
    </h1>

    <p className="mb-4">{t("legal.consumerRights")}</p>

    <p className="mb-4">{t("legal.rightOfCancellation")}</p>

    <p className="mb-4">{t("legal.cancelContract")}</p>

    <p className="mb-4">{t("legal.cancelPeriod")}</p>

    <p className="mb-4">{t("legal.exerciseRight")}</p>

    <div className="mb-4">
      <p>{t("legal.saleToEurope")}</p>
      <p>{t("legal.city")}</p>
      <p>{t("legal.address")}</p>
      <p>{t("legal.telephone")}</p>
      <p>{t("legal.email")}</p>
    </div>

    <p className="mb-4">{t("legal.clearStatement")}</p>

    <p className="mb-4">{t("legal.meetDeadline")}</p>

    <p className="mb-4">{t("legal.additionalInformation")}</p>

    <p className="mb-4">{t("legal.consequencesOfCancellation")}</p>

    <p className="mb-4">{t("legal.cancelContract_2")}</p>

    <p className="mb-4">{t("legal.repaymentMethod")}</p>

    <p className="mb-4">{t("legal.refuseRepayment")}</p>

    <p className="mb-4">{t("legal.sendGoods")}</p>

    <div className="mb-4">
      <p>{t("legal.saleToEurope")}</p>
      <p>{t("legal.city")}</p>
      <p>{t("legal.address")}</p>
      <p>{t("legal.telephone")}</p>
      <p>{t("legal.email")}</p>
    </div>

    <p className="mb-4">{t("legal.returnOrHandover")}</p>

    <p className="mb-4">{t("legal.exceptionsToRightOfWithdrawal")}</p>

    <p className="mb-4">{t("legal.payForLossInValue")}</p>

    <p className="mb-4">{t("legal.rightDoesNotApply")}</p>

    <ul className="list-disc pl-8 mb-4">
      {t("legal.exceptionCases", { returnObjects: true }).map(
        (exception, index) => (
          <li key={index}>{exception}</li>
        )
      )}
    </ul>

    <h1 className="text-2xl font-bold mb-4">
      {t("legal.exclusionOfRightOfWithdrawal")}
    </h1>

    <p className="mb-4">
      {t("legal.prematureExpiryOfRightOfWithdrawal_1")}
    </p>

    <ul className="list-disc pl-8 mb-4">
      {t("legal.exceptionCases_2", { returnObjects: true }).map(
        (exception, index) => (
          <li key={index}>{exception}</li>
        )
      )}
    </ul>

    <p className="mb-4">
      {t("legal.prematureExpiryOfRightOfWithdrawal_2")}
    </p>

    <ul className="list-disc pl-8 mb-4">
      {t("legal.exceptionCases_3", { returnObjects: true }).map(
        (exception, index) => (
          <li key={index}>{exception}</li>
        )
      )}
    </ul>
  </div>
</div>
<Footer />
</div> */
}
