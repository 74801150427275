import { useNavigate } from "react-router-dom";
import { useGetItemsByMainCatagoryMutation } from "../features/auth/authApiSlice";
import { useState } from "react";

function CatagoryCard({ title, image }) {
  const navigate = useNavigate();

  const [getItemsByMainCatagory, { isMainCatagoryLoading }] =
    useGetItemsByMainCatagoryMutation();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    //('Hovered');
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handlegetMainCatagoryItems = async (mainCatagory) => {
    //console.log('searching...');
    //console.log(title);
    const SearchResults = await getItemsByMainCatagory({
      mainCatagory: mainCatagory,
    });
    //console.log('SearchResults');
    //console.log(SearchResults);

    navigate("/searchResultsPage", {
      state: {
        searchResults: SearchResults.data.items,
        query: mainCatagory,
        subcatagories: SearchResults.data.subCatagories,
      },
    });
    // Add logic here to handle the search action based on the searchQuery.
  };
  return (
    <>
      <div
        className="w-[160px] xl:w-[180px]  bg-white rounded-[7px] border border-stone-400 flex-col justify-start items-center pb-1 inline-flex  max-w-sm  overflow-hidden shadow-lg  relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ flexShrink: 0 }}
      >
        <div className="relative">
          <div className="justify-center items-center gap-2.5 inline-flex  bg-white  ">
            <img
              className="w-[200px] h-[200px] max-w-full max-h-full rounded-xl transform hover:scale-105 hover:duration-300"
              src={image}
              style={{ objectFit: "contain" }}
              alt="Card"
            />
          </div>

          <div className="px-2 ">
            <div className=" text-neutral-500 text-[16px]  mt-2 font-medium font-['Poppins'] leading-[13px]">
              {title}
            </div>
            <div
              onClick={() => handlegetMainCatagoryItems(title)}
              className={`${
                isHovered ? "visible" : "md:invisible"
              }  px-[20px] w-full z-50 mt-2 py-0.5 rounded-[3px] bg-purple-700 hover:bg-purple-600 justify-center items-center  inline-flex`}
            >
              <div
                className="text-white cursor-pointer text-xs font-semibold font-['Poppins'] leading-7 tracking-tight"
                style={{ fontSize: "12px" }}
              >
                Show Details
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CatagoryCard;
