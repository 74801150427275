import { useState, useEffect } from "react";
import {
  useChangePasswordMutation,
  useCheckResetPasswordMutation,
} from "./authApiSlice";

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmnewPassword, setConfirmNewPassword] = useState("");
  const [checkResetPassword] = useCheckResetPasswordMutation();

  const [isResetLoading, setIsResetLoading] = useState(false);

  const [currrentuser, setCurrentUser] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const [changePassWord, { isChangePasswordLoading }] =
    useChangePasswordMutation();

  const [isExpired, setIsExpired] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsResetLoading(true);

      // Extract reset token from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromURL = urlParams.get("resetToken");
      const idFromUrl = urlParams.get("id");
      const emailFromUrl = urlParams.get("email");

      // Call your asynchronous function (checkResetPassword) here
      const userData = await checkResetPassword({
        id: idFromUrl,
        email: emailFromUrl,
        resetToken: tokenFromURL,
      });

      if (userData.data.expired === "expired") {
        setIsExpired("Token Is expired");
      } else if (userData.data.invalid === "invalid token") {
        setIsExpired("Invalid Token");
      } else if (userData.data.email) {
        setCurrentEmail(userData.data.email);
        setCurrentId(userData.data._id);
        setCurrentUser(userData.data);
        setIsTokenValid(true);
        setIsResetLoading(false);
      }
      // Set the reset token in state for later use

      // Perform any additional validation or error handling here
      // For example, check if the token exists or if it's expired
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once on mount
  const [confirmPwdMsg, setConfirmPswMsg] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setConfirmPswMsg(null);
    if (newPassword !== confirmnewPassword) {
      setConfirmPswMsg("Password Must Match");
      return;
    }
    setIsProcessing(true);

    const userData = await changePassWord({
      id: currentId,
      email: currentEmail,
      role: currrentuser.role,

      password: newPassword,
    });

    if (userData.data.email === currentEmail) {
      setSuccessMsg("Password successfully Changed");
    } else {
      setSuccessMsg("An Error Occurred");
    }
    setIsProcessing(false);

    // Send a request to your NestJS backend to update the password
    // Use newPassword and resetToken in the request body
  };

  return (
    <>
      {isResetLoading ? (
        <p>Loadng...</p>
      ) : (
        <div>
          {isExpired && <div>{isExpired}</div>}
          {isTokenValid && (
            <div className="flex-col justify-center items-center  pl-5 pt-48">
              <label className="block">New Password:</label>
              <input
                type="password"
                value={newPassword}
                className="mt block w-11/12 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label className="block">Confirm Password:</label>
              <input
                type="password"
                className="mt block w-11/12 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                value={confirmnewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button
                onClick={handleResetPassword}
                className="block bg-violet-800 hover:bg-violet-700 text-white rounded-md px-2 py-1 mt-3"
              >
                {isProcessing ? "Processing..." : "Reset Password"}
              </button>
              {confirmPwdMsg && <p>{confirmPwdMsg}</p>}
              {successMsg && <p>{successMsg}</p>}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ResetPasswordPage;
