import { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { LuUser2 } from "react-icons/lu";
import "../styles/productDetail.css";
import { FaShareSquare } from "react-icons/fa";
import "../styles/welcome.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";

import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrenRole,
  selectCurrentCart,
  selectCurrentEmail,
  selectCurrentName,
  selectCurrentProductIdTobeDetailed,
  selectCurrentUserId,
  setUser,
  selectCurrentCountry,
  selectCurrentRegion,
  selectCurrentCity,
  selectCurrentStreetAddress,
  selectCurrentAdditionalStreetAddress,
  selectCurrentZipCode,
  selectCurrentPhoneNumber,
  selectCurrentPostalCode,
  selectCurrentHomeNumber,
} from "../features/auth/authSlice";
import {
  useAddCartMutation,
  useAddReviewMutation,
  useGetItemByIdMutation,
  useGetItemsByMainCatagoryMutation,
  useGetReviewsOfProductMutation,
  useGetShippingRulesMutation,
  useIsEligibleToReviewMutation,
  useStripePayMutation,
  useStripePayUsingCouponMutation,
} from "../features/auth/authApiSlice";
import { RiNurseFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchCard from "./searchCard";
import Footer from "./Footer";
import PaypalCheckoutButton from "./Buyer/paypalCheckout";
import SearchCard2 from "./searchCard2";

function LoadingPlaceholder() {
  return (
    <div className="animate-pulse bg-gray-300 w-full h-full rounded-md"></div>
  );
}

function ProductDetailsPage() {
  const [getShippingRules, { isShippingRulesLoading }] =
    useGetShippingRulesMutation();

  const urlParamsproductId = new URLSearchParams(window.location.search).get(
    "productId"
  );

  const country = useSelector(selectCurrentCountry);
  const region = useSelector(selectCurrentRegion);
  const city = useSelector(selectCurrentCity);
  const streetAddress = useSelector(selectCurrentStreetAddress);
  const additionalStreetAddress = useSelector(
    selectCurrentAdditionalStreetAddress
  );
  const zipCode = useSelector(selectCurrentZipCode);
  const phoneNumber = useSelector(selectCurrentPhoneNumber);
  const postalCode = useSelector(selectCurrentPostalCode);
  const homeNumber = useSelector(selectCurrentHomeNumber);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      setScrollLeft(container.scrollLeft);
    }
  };

  function formatPrice(price) {
    // Convert the price to a number
    const numericPrice = parseFloat(price);

    // Check if the price has cents
    if (Number.isInteger(numericPrice)) {
      // If it's a whole number, add .00
      return `${numericPrice.toFixed(2)}`;
    } else {
      // If it has cents, leave it as it is
      return `${numericPrice.toFixed(2)}`;
    }
  }
  function getadditionalPrice(shippingruls, itemresult) {
    let addtionalPrice = 0;
    if (
      currentRole &&
      currentRole === process.env.REACT_APP_BUYER_ROLE &&
      country
    ) {
      shippingruls?.map((rule, index) => {
        if (
          rule.country === country &&
          itemresult?.weight >= rule.from &&
          itemresult?.weight <= rule.to
        ) {
          addtionalPrice = rule.shippingFee;
        }
      });
    }
    return addtionalPrice;
  }

  const [isLoading, setIsLoading] = useState(false);

  const [itemFetched, setItemFetched] = useState(null);
  const [globalShippingRules, setGlobalShippingRules] = useState(null);
  const [fetchedItems, setFetchedItems] = useState(null);

  const [getItemsByMainCatagory, { isMainCatagoryLoading }] =
    useGetItemsByMainCatagoryMutation();

  const [hoveredImage, setHoveredImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState(null);
  const [price, setPrice] = useState(null);

  const handleShareClick = () => {
    const currentUrl = window.location.href;
    // const updatedUrl = `${currentUrl}?productId=${itemId}`;

    // Use the Clipboard API to copy the current URL to the clipboard
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        // Notify the user with a success toast
        toast.success("URL copied to clipboard!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error("Unable to copy URL to clipboard", error);
        // Notify the user with an error toast
        toast.error("Error copying URL to clipboard", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const [isColorSet, setIsColorSet] = useState(false);

  const { t } = useTranslation();
  const [quantityOptions, setQuantityOptions] = useState([]);

  const [description, setDescription] = useState(null);
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const ratingOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const itemId = useSelector(selectCurrentProductIdTobeDetailed);
  const [getItemById, { isSellerLoading }] = useGetItemByIdMutation();
  const [addReview, { isAddReviewLoading }] = useAddReviewMutation();
  const [GlobalColorQuantity, setGlobalColorQuantity] = useState(
    itemFetched?.quantity
  );

  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleButtonClick = (scrollOffset) => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        left: scrollOffset,
        behavior: "smooth",
      });
      setScrollLeft(scrollOffset);
    }
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [itemId]);

  /*itemId to be detailed*/
  const [itemIdTobedetailed, setItemIdToBeDetailed] = useState(itemId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        let result;
        const urlParams = new URLSearchParams(window.location.search);
        const productId = urlParams.get("productId");
        if (productId) {
          setItemIdToBeDetailed(productId);
          result = await getItemById({
            itemId: productId,
          }).unwrap();
        } else {
          result = await getItemById({
            itemId: itemId,
          }).unwrap();
        }

        // if (itemId) {
        //   result = await getItemById({
        //     itemId: itemId,
        //   }).unwrap();
        // } else {
        //   const urlParams = new URLSearchParams(window.location.search);
        //   const productId = urlParams.get('productId');

        // }

        const shippingRules = await getShippingRules().unwrap();
        setGlobalShippingRules(shippingRules);

        setItemFetched(result);
        setGlobalColorQuantity(result?.quantity);
        setShowReviewField(false);
        setSuccessMsg(null);
        setErrMsg(null);
        setisEligibleErrMsg(null);
        setDescription(null);
        setItemReviews(null);

        setHoveredImage(result?.avatarUrl[0]);
        const SearchResults = await getItemsByMainCatagory({
          mainCatagory: result?.mainCatagory,
        });
        setFetchedItems(SearchResults.data.items);
        setQuantityOptions(
          [...Array(result?.quantity).keys()].map((index) => index + 1)
        );

        setPrice(
          parseFloat(result?.price[0]) +
            parseFloat(getadditionalPrice(shippingRules, result))
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [itemId, urlParamsproductId]);

  const extractColorFromText = (text) => {
    const match = text.match(/\b(\w+)\b/); // Extracts the first word
    return match ? match[1] : "transparent";
  };

  const extractColor = (text) => {
    // Your logic to extract the color from the text
    const color = text.split(" ")[0]; // Assuming the color is the first word in the text
    return color;
  };

  const [userRating, setUserRating] = useState(1);

  const rating = 5;

  const renderRatingStars = (rating) => {
    if (!rating) {
      return null; // Return null if rating is null
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <svg
          key={i}
          width="20"
          height="19"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <svg
          key={filledStars}
          width="20"
          height="19"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <svg
          width="20"
          key={`empty-${i}`}
          height="19"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    return stars;
  };
  const renderRatingStars2 = (rating) => {
    if (!rating) {
      return null; // Return null if rating is null
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <svg
          key={i}
          width="16"
          height="15"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <svg
          key={filledStars}
          width="16"
          height="15"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <svg
          width="16"
          key={`empty-${i}`}
          height="15"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    return stars;
  };

  const handleQuantityChange = (event) => {
    let quantity = event.target.value;
    setQuantity(event.target.value);
    setPrice(itemFetched?.price[0] * quantity);
  };
  const extractQuantityFromText = (text) => {
    // Your logic to extract the quantity from the text
    const matches = text.match(/\((\d+)\)/); // Extracts the quantity within parentheses
    const quantity = matches ? parseInt(matches[1], 10) : null; // Parses the quantity as an integer
    return quantity;
  };

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    let quantity;
    itemFetched.color.map((color) => {
      const saj = extractColor(color);
      if (saj === selectedColor) {
        quantity = extractQuantityFromText(color);
      }
    });
    const color = extractColor(selectedColor);

    setColor(selectedColor);
    setGlobalColorQuantity(quantity);
    setQuantityOptions([...Array(quantity).keys()].map((index) => index + 1));
    // setPrice(itemFetched?.price[0] * quantity);
  };

  const [stripePay, { isStripeLoading }] = useStripePayMutation();
  const [stripePayUsingCoupon, { isStripePayCouponLoading }] =
    useStripePayUsingCouponMutation();

  const [isEligibleToReview, { isEligibleLoading }] =
    useIsEligibleToReviewMutation();

  const [getReviewsOfProduct, { areReviesLoading }] =
    useGetReviewsOfProductMutation();

  const [addCart, { isCartLoading }] = useAddCartMutation();
  const [couponCode, setCouponCode] = useState(null);
  const email = useSelector(selectCurrentEmail);
  const fullName = useSelector(selectCurrentName);
  const buyerId = useSelector(selectCurrentUserId);
  const cart = useSelector(selectCurrentCart);
  const [isAddingtocart, setIsAddingToCart] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const currentRole = useSelector(selectCurrenRole);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleAddToCart = async (itemId) => {
    if (!currentRole && currentRole !== process.env.REACT_APP_BUYER_ROLE) {
      navigate("/login");
      return;
    }
    setIsAddingToCart(true);
    if (cart.includes(itemId)) {
      setIsAdded(true);
      toast.success("Item Added to Cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsAddingToCart(false);

      return;
    }

    // Your logic for adding to cart goes here
    const userData = await addCart({
      fullName,
      email,
      cartItemId: itemId,
    }).unwrap();

    dispatch(
      setUser({
        id: userData._id,
        email: userData.email,
        name: userData.fullName,
        role: userData.role,
        accountStatus: userData.accountStatus,
        cart: userData.cart,
      })
    );
    setIsAdded(true);
    toast.success("Item Added to Cart!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setIsAddingToCart(false);
  };
  const [isCheckouting, setIsCheckOuting] = useState(false);

  const [paypalOrders, setPayPalOrders] = useState([]);
  const [paypalOrders2, setPayPalOrders2] = useState([]);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [coupnCodeNotFilled, setCouponCodeNotFilled] = useState(null);

  const paywithPayPal = async () => {
    if (!currentRole) {
      navigate("/login");
      return;
    }
    if (
      !country &&
      !region &&
      !city &&
      !streetAddress &&
      !additionalStreetAddress &&
      !zipCode &&
      !phoneNumber &&
      !postalCode &&
      !homeNumber
    ) {
      toast.warning("Please fill address info in your profile.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!(itemFetched?.color.length === 1 || !itemFetched?.color)) {
      if (!color) {
        setIsColorSet(true);
        setIsCheckOuting(false);
        return;
      }
    }
    const orders = [];

    const order = {
      itemId: itemFetched?._id,
      quantity: quantity,
      color: color,
      buyerId: buyerId,
      isUsingCoupon: true,
      couponId: "653d77701dc0d80c33c22746",
    };
    orders.push(order);

    const fetchedOrderList = orders.map((order, index) => {
      return {
        description: "Payment description",
        amount: {
          value:
            (itemFetched?.price[0] +
              parseFloat(
                getadditionalPrice(globalShippingRules, itemFetched)
              )) *
            order.quantity,
        },
      };
    });

    setPayPalOrders2(fetchedOrderList);

    setPayPalOrders(orders);
    setShowPayPalButton(!showPayPalButton);
  };

  const handleCheckoutButton = async () => {
    if (!currentRole) {
      navigate("/login");
      return;
    }
    if (
      !country &&
      !region &&
      !city &&
      !streetAddress &&
      !additionalStreetAddress &&
      !zipCode &&
      !phoneNumber &&
      !postalCode &&
      !homeNumber
    ) {
      toast.warning("Please fill address info in your profile.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setIsCheckOuting(true);

    if (!color) {
      setIsColorSet(true);
      setIsCheckOuting(false);
      return;
    }
    const orders = [];

    const order = {
      itemId: itemFetched?._id,
      quantity: quantity,
      color: color,
      buyerId: buyerId,
      isUsingCoupon: true,
      couponId: "653d77701dc0d80c33c22746",
    };
    orders.push(order);

    const userData = await stripePay({ orders }).unwrap();
    setIsCheckOuting(false);
    window.location = userData.url;
  };

  const handleCheckoutUsingCouponButton = async () => {
    if (!currentRole) {
      navigate("/login");
      return;
    }
    if (
      !country &&
      !region &&
      !city &&
      !streetAddress &&
      !additionalStreetAddress &&
      !zipCode &&
      !phoneNumber &&
      !postalCode &&
      !homeNumber
    ) {
      toast.warning("Please fill address info in your profile.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!couponCode) {
      setCouponCodeNotFilled("Please Fill Out this Filled");
      return;
    }
    setIsCheckOuting(true);
    if (!(itemFetched?.color.length === 1 || !itemFetched?.color)) {
      if (!color) {
        setIsColorSet(true);
        setIsCheckOuting(false);
        return;
      }
    }
    const orders = [];

    const order = {
      itemId: itemFetched?._id,
      quantity: quantity,
      color: color,
      buyerId: buyerId,
      isUsingCoupon: false,
      couponCode: couponCode,
    };
    orders.push(order);

    const userData = await stripePayUsingCoupon({ orders }).unwrap();
    setIsCheckOuting(false);
    window.location = userData.url;
  };

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);
  const [showReviewField, setShowReviewField] = useState(false);

  const handleStarClick = () => {
    // Scroll to the review section

    // Toggle the review section visibility
    setIsDescriptionOpen(false);

    setIsReviewOpen(true);
  };
  const handleDescriptionClick = () => {
    // Scroll to the review section

    // Toggle the review section visibility
    setIsReviewOpen(false);
    setIsDescriptionOpen(true);
  };
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [successMessage, setSuccessMsg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [isEligibleErrMsg, setisEligibleErrMsg] = useState(null);
  const [getItemsErrMsg, setGetItemsErrMsg] = useState(null);
  const [isEligiblecChecking, setisEligibleChecking] = useState(false);
  const [areProductReviewsLoading, setAreProductReviewsLoading] =
    useState(false);
  const [itemReviews, setItemReviews] = useState(null);

  const handleCouponCodeChange = (e) => setCouponCode(e.target.value);

  const handleGetReviews = async (e) => {
    e.preventDefault();

    setAreProductReviewsLoading(true);
    // const reviewSection = document.getElementById('review-section');
    // if (reviewSection) {
    //   const yOffset =
    //     reviewSection.getBoundingClientRect().top + window.scrollY;
    //   window.scrollTo({ top: yOffset, behavior: 'smooth' });
    // }
    try {
      const reviews = await getReviewsOfProduct({
        itemId: itemId,
      });

      setItemReviews(reviews.data);

      setIsDescriptionOpen(false);

      setIsReviewOpen(true);
    } catch {
      setGetItemsErrMsg("unable to fetch reviews");
    } finally {
      setAreProductReviewsLoading(false);
    }
  };

  const handleIsEligible = async (e) => {
    e.preventDefault();
    setisEligibleChecking(true);
    setisEligibleErrMsg(null);
    try {
      const isEligible = await isEligibleToReview({
        itemId: itemIdTobedetailed,
        buyerId: buyerId,
        buyerName: fullName,
      });

      if (isEligible.data === true) {
        setShowReviewField(!showReviewField);
      } else {
        setisEligibleErrMsg("You are not Eligible to review this product");
      }
    } catch {
      setisEligibleErrMsg("Unable to check you eligiblity");
    } finally {
      setisEligibleChecking(false);
    }
  };

  const handleAddReview = async (e) => {
    e.preventDefault();
    setSuccessMsg(null);
    setErrMsg(null);

    try {
      setIsAddingReview(true);

      const addedReview = await addReview({
        itemId: itemIdTobedetailed,
        buyerId: buyerId,
        buyerName: fullName,
        rating: userRating,
        feedbackMsg: description,
      });
      if (addedReview.data.buyerId === buyerId) {
        setSuccessMsg("Review Added successfully.");
      } else {
        setErrMsg("Unable to Add review");
      }
    } catch {
      setErrMsg("Unable to Add review");
    } finally {
      setIsAddingReview(false);
    }
  };
  const calculatePercentage = () => {
    if (itemFetched?.oldquantity) {
      return (itemFetched?.quantity / itemFetched?.oldquantity) * 100;
    } else {
      // If oldQuantity is not present, assume it to be 100
      return (itemFetched?.quantity / 100) * 100;
    }
  };

  const percentage = calculatePercentage();
  return (
    <>
      <div className="overflow-hidden">
        <Navbar></Navbar>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            {/* Loading placeholder for the entire product details page */}
            <LoadingPlaceholder />
          </div>
        ) : (
          <div className={`w-full  h-full pt-6 pb-24`}>
            <div className={`h-full pt-6 md:flex`}>
              <div className="flex md:flex-col ml-4">
                {itemFetched && itemFetched.avatarUrl && (
                  <>
                    {itemFetched.avatarUrl.map((url, index) => (
                      <div
                        className={`bg-white md:ml-12 p-1  mt-2 rounded-sm   ${
                          url === hoveredImage
                            ? " border"
                            : "border border-white"
                        }`}
                      >
                        <img
                          className="w-[80px] h-[80px] mt-2"
                          key={index}
                          src={url}
                          alt={`avatar-${index}`}
                          onMouseEnter={() => setHoveredImage(url)}
                          onMouseLeave={() => setHoveredImage(url)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div>
                {hoveredImage && (
                  <div className="ml-5 py-12 px-5  rounded-md bg-white">
                    <img
                      src={hoveredImage}
                      className="ml-4 w-96  h-96 mt-2"
                      alt="hovered-image"
                    />
                  </div>
                )}
              </div>
              <div className="felx-col   ml-5 ">
                <div className="flex">
                  <p className="text-neutral-600 mb-2 text-[23px] font-normal font-['Poppins'] leading-7 tracking-tight">
                    {itemFetched?.itemName}
                  </p>
                  <div
                    style={{ opacity: "0.7" }}
                    className="ml-auto"
                    onClick={handleShareClick}
                  >
                    <FaShareSquare size={24} />
                  </div>
                </div>
                <div className="flex gap-4">
                  {itemFetched?.isDiscounted && (
                    <div className="text-neutral-600 text-[23px] font-normal font-['Poppins'] line-through leading-7 tracking-tight">
                      €
                      {formatPrice(
                        itemFetched?.price /
                          (1 - itemFetched?.discountAmount / 100)
                      )}
                    </div>
                  )}

                  <div className="text-neutral-600 mb-2 text-[23px] font-normal font-['Poppins'] leading-7 tracking-tight">
                    €{formatPrice(itemFetched?.price)}
                  </div>
                </div>
                <div
                  className="flex items-center mb-2"
                  //onClick={handleGetReviews}
                  style={{ cursor: "pointer" }}
                >
                  {renderRatingStars(itemFetched?.rating)}
                </div>
                <div
                  className="text-neutral-600 mt-6  font-normal font-['Poppins'] leading-7 tracking-tight"
                  style={{ fontSize: "16px" }}
                >
                  Only {itemFetched?.quantity} left in stock.
                </div>

                <div className="w-[90%] md:w-[500px] h-2.5 mb-6 bg-zinc-100 justify-start items-center inline-flex">
                  <div
                    className=" h-2.5 relative bg-violet-700"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
                <p
                  className="text-opacity-70 md:w-[500px] "
                  style={{ opacity: "0.7" }}
                >
                  {itemFetched?.description}
                </p>
                {itemFetched?.colorImgList && (
                  <div>
                    {/* <p className="font-semibold">Available Colors:</p> */}
                    <ul className="flex my-6 flex-wrap">
                      {itemFetched.color.map((color9, index) => (
                        <li key={index}>
                          <div
                            key={index}
                            className={`rounded-full my-2 w-[29px] h-[29px]   border-purple-500 ${
                              color === extractColor(color9) ? "border-2" : ""
                            } `}
                            style={{
                              backgroundColor: extractColorFromText(color9),
                              // width: '20px',
                              // height: '20px',
                              marginRight: "8px",
                            }}
                            onClick={() => {
                              let quantity;
                              const colorse = extractColor(color9);

                              itemFetched.color.map((color2) => {
                                const saj = extractColor(color2);
                                if (saj === colorse) {
                                  quantity = extractQuantityFromText(color2);
                                }
                              });

                              setColor(colorse);
                              setGlobalColorQuantity(quantity);
                              setQuantity(1);
                              setQuantityOptions(
                                [...Array(quantity).keys()].map(
                                  (index) => index + 1
                                )
                              );
                            }}
                            onMouseEnter={() => {
                              if (itemFetched.colorImgList[index]) {
                                setHoveredImage(
                                  itemFetched.colorImgList[index]
                                );
                              }
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="flex">
                  <div className="bg-white rounded-[3px] border border-neutral-400 justify-start items-center gap-1.5 inline-flex">
                    <div
                      onClick={() => {
                        if (quantity > 0) {
                          let quantityModified = quantity - 1;
                          setQuantity(quantityModified);
                          setPrice(itemFetched?.price[0] * quantityModified);
                        }
                      }}
                      className="hover:cursor-pointer pl-3 pr-[15px] py-1.5 flex-col justify-center items-center gap-2.5 inline-flex"
                    >
                      <div className="w-[5px] text-neutral-400 text-[33px] font-['Poppins'] leading-7 tracking-tight">
                        -
                      </div>
                    </div>
                    <div className="px-[19px] py-1.5 border-l border-r border-neutral-400 flex-col justify-center items-center gap-2.5 inline-flex">
                      <div className="text-neutral-400 text-[22px] font-normal font-['Poppins'] leading-7 tracking-tight">
                        {quantity}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (quantity < Number(GlobalColorQuantity)) {
                          let quantityModified = quantity + 1;
                          setQuantity(quantityModified);
                          setPrice(itemFetched?.price[0] * quantityModified);
                        }
                      }}
                      className={` ${
                        quantity === Number(GlobalColorQuantity)
                          ? "disabled:opacity-40"
                          : ""
                      } hover:cursor-pointer pl-3 pr-[15px] py-1.5 flex-col justify-center items-center gap-2.5 inline-flex `}
                    >
                      <div className="text-neutral-400 text-[28px] font-['Poppins'] leading-7 tracking-tight">
                        +
                      </div>
                    </div>
                  </div>
                  <div className="ml-28 flex">
                    <span className="text-neutral-600 mb-2 text-[23px] font-normal font-['Poppins'] leading-7 tracking-tight">
                      {t("totalWithShipping")}: €{formatPrice(price)}
                    </span>
                  </div>{" "}
                </div>
                <div className=" flex md:gap-16 flex-wrap items-center ">
                  <div className="px-12 md:h-14  w-full md:w-[250px] mt-5 text-center py-3.5 bg-violet-700 hover:bg-violet-800 md:rounded-md rounded-[50px] justify-center items-center">
                    <div
                      className="text-white text-base font-semibold font-['Poppins']"
                      onClick={() => handleAddToCart(itemIdTobedetailed)}
                      style={{ cursor: "pointer" }}
                    >
                      {isAddingtocart ? "Adding..." : t("addtocart")}
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="px-12 hover:border-2 w-full md:w-[250px] md:h-14 mt-2 md:mt-5 text-center py-2.5 md:py-3.5 md:rounded-md rounded-[50px] border border-violet-700 justify-center items-center gap-2.5 md:inline-flex"
                  >
                    <div
                      onClick={handleCheckoutButton}
                      style={{ cursor: "pointer" }}
                      className="text-violet-700 text-base font-medium font-['Poppins'] leading-7 tracking-tight"
                    >
                      {isCheckouting ? "Processing..." : t("gotocheckout")}
                    </div>
                  </div>
                </div>

                {/* <p>{itemFetched?.itemStatus}</p>
              <p>Weight: {itemFetched?.weight}Kg</p>
              <p>price: US {itemFetched?.price}$</p>
              {itemFetched?.colorImgList && (
                <div className="">
                  <label htmlFor="color" className="text-gray-500">
                    {t('color')}:
                  </label>
                  <select
                    id="color"
                    className="md:ml-2 border border-gray-300 rounded-md w-20 h-8"
                    value={color}
                    onChange={handleColorChange}
                  >
                    <option value={null}>Select</option>
                    {itemFetched.color.map((colorOption, index) => {
                      const extactedColor = extractColor(colorOption);
                      return (
                        <option key={index} value={extactedColor}>
                          {extactedColor}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="flex mt-2">
                <label htmlFor="quantity" className="text-gray-500">
                  {t('qty')}:
                </label>
                {itemFetched?.quantity > 0 ? (
                  <select
                    id="quantity"
                    className="md:ml-2 border border-gray-300 rounded-md w-16 h-8"
                    value={quantity}
                    onChange={handleQuantityChange}
                  >
                    {quantityOptions.map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p className="ml-2 text-red-500">{t('unavailable')}</p>
                )}
                <p className="text-gray-400 rowMarigin md:mx-4">|</p>

                <a
                  className={`${
                    isAddingtocart ? 'disabled:bg-orange-300' : ''
                  } text-orange-500 hover:underline `}
                  onClick={() => handleAddToCart(itemIdTobedetailed)}
                >
                  {isAddingtocart ? 'Adding...' : t('addtocart')}
                </a>

                {/* {isAdded && <span className="ml-3 text-green-500">added</span>} */}
                {/* <p className="text-gray-400 rowMarigin md:mx-4">|</p>} */}

                {/* </div> */}

                <div
                  // style={{ borderTopWidth: '0.1px' }}
                  className="md:mt-5"
                >
                  {/* <div className="mt-5 mb-2">{t('paywithpaypal')}</div> */}
                  <div
                    style={{ cursor: "pointer" }}
                    className="px-12 hover:border-2 w-full md:w-[250px] mb-5 h-14 mt-2 md:mt-5 text-center py-1.5  mr-3 md:py-3.5  md:rounded-md rounded-[50px] border border-violet-700 justify-center items-center gap-2.5 inline-flex"
                  >
                    <button
                      onClick={paywithPayPal}
                      style={{ cursor: "pointer" }}
                      className="text-violet-700  text-base font-medium font-['Poppins'] leading-7 tracking-tight"
                    >
                      {t("paypal")}
                    </button>
                  </div>
                  {showPayPalButton && (
                    <div className={`${showPayPalButton ? "" : "hidden"}`}>
                      <div className="paypal-button-container">
                        <PaypalCheckoutButton
                          product={paypalOrders}
                          product2={paypalOrders2}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <p>{t("haveCouponCode")}</p>

                <input
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  autoComplete="off"
                  required
                  type="text"
                  placeholder={t("couponCode")}
                  className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                ></input>
                {coupnCodeNotFilled && (
                  <p className="text-red-600">Please Fill this Filled</p>
                )}
                <div
                  style={{ cursor: "pointer" }}
                  className="px-12 hover:border-2 mb-5 h-14 mt-5 text-center py-3.5 rounded-md border border-violet-700 justify-center items-center gap-2.5 inline-flex"
                >
                  <button
                    onClick={handleCheckoutUsingCouponButton}
                    style={{ cursor: "pointer" }}
                    className="text-violet-700  text-base font-medium font-['Poppins'] leading-7 tracking-tight"
                  >
                    {isCheckouting ? t("processing") : t("checkoutUsingCoupon")}
                  </button>
                </div>
                {!(itemFetched?.color.length === 1 || !itemFetched?.color) &&
                  isColorSet && (
                    <div className="text-red-500 mt-2">
                      choose a color First.
                    </div>
                  )}
                {/* <div className="checkOutwidthForSmall  sticky top-0 checkoutvarticalPad md:ml-8 bg-white md:w-1/4 py-5 rounded-md px-5 h-56 flex-col items-center  ">
                <button
                  onClick={handleCheckoutButton}
                  className={`bg-violet-800 hover:bg-violet-700 w-full  rounded-3xl px-4 py-1 text-center text-white text-lg ${
                    isCheckouting ? 'disabled:bg-violet-400' : ''
                  }`}
                  style={{ height: '45px' }}
                >
                  {isCheckouting ? 'Processing...' : t('gotocheckout')}
                </button>

                <div className="mt-2">
                  {t('items')} ({quantity})
                </div>
                {isColorSet && (
                  <div className="text-red-500 mt-2">choose a color First.</div>
                )}
                <div
                  className="flex  mt-8 pt-4"
                  style={{ borderTopWidth: '1px' }}
                >
                  <span className="text-xl ">{t('subtotal')}: </span>{' '}
                  <span className="text-xl justify-self-end  ml-auto">
                    US {price}$
                  </span>
                </div>

                
              </div> */}
              </div>
            </div>
            <div className="flex justify-center items-center h-[20px] gap-8 mt-20 md:mr-20">
              <div
                onClick={handleDescriptionClick}
                style={{ cursor: "pointer" }}
                className={`${
                  isDescriptionOpen ? "text-violet-700 " : "text-neutral-600"
                } text-lg font-normal font-['Poppins'] leading-7`}
              >
                {t("description")}
              </div>
              <div
                onClick={handleGetReviews}
                style={{ cursor: "pointer" }}
                className={`${
                  isReviewOpen ? "text-violet-700 " : "text-neutral-600"
                } text-lg font-normal font-['Poppins'] leading-7`}
              >
                {t("reviews")}{" "}
                {itemReviews && <span>({itemReviews?.length})</span>}
              </div>
            </div>
            {isDescriptionOpen && (
              <div className="flex mt-20  ml-2  items-center justify-center">
                <div className="w-[776px]  flex-col justify-start items-start gap-[23px] inline-flex">
                  <div className="text-neutral-800 text-[29px] font-medium font-['Poppins'] leading-7 tracking-tight">
                    {itemFetched?.itemName}
                  </div>
                  <div className="flex-col justify-start items-start gap-[15px] flex">
                    <div className="text-neutral-600 text-base font-normal font-['Poppins'] leading-7">
                      {t("dimension")}: {itemFetched?.dimentions}
                      <br />
                      {t("itemStatus")}: {itemFetched?.itemStatus}
                      <br />
                      {t("weight")}: {itemFetched?.weight}
                      <br />
                      {t("netQuantity")}: {itemFetched?.quantity}
                      <br />
                      {t("marketedBy")}: {itemFetched?.companyName}
                    </div>
                  </div>
                  <div className="text-stone-900 text-[25px] font-medium font-['Poppins'] leading-7">
                    {t("specialDescription")}:{" "}
                  </div>
                  <div className="text-neutral-600 text-base font-normal font-['Poppins'] leading-7">
                    {itemFetched?.description}
                  </div>
                </div>
              </div>
            )}

            <div
              id="review-section"
              className="mt-10 flex justify-center w-full"
              style={{ marginLeft: "" }}
            >
              {areProductReviewsLoading ? (
                <p></p>
              ) : (
                isReviewOpen && (
                  <div>
                    {" "}
                    <div className="bg-white px-10 rounded-md widthForLarge">
                      {/* <div className="text-lg font-semibold">
                      {t('reviewsOfThisProduct')}
                    </div> */}
                      {itemReviews?.length !== 0 ? (
                        itemReviews?.map((review, index) => (
                          <div className="mt-10" key={index}>
                            <div className="flex gap-4 items-center">
                              <div className="h-8 w-8 rounded-full bg-gray-400 flex justify-center items-center">
                                <LuUser2 color="#fff" size="24px" />
                              </div>
                              <p
                                className="text-neutral-600 text-lg mb-2 font-normal font-['Poppins'] leading-7"
                                style={{ opacity: "0.7" }}
                              >
                                {review.buyerName}
                              </p>
                            </div>
                            <div className="flex items-center mb-2">
                              {renderRatingStars2(review.rating)}
                            </div>
                            <p className="text-black text-base font-normal font-['Poppins'] leading-tight text-opacity-70">
                              {review.feedbackMsg}
                            </p>
                          </div>
                        ))
                      ) : (
                        <>
                          <div className="h-96">
                            <div>No Reviews For This Product</div>
                            <div className="px-4 md:px-0 md:w-96">
                              <div
                                style={{ cursor: "pointer" }}
                                className="px-12 hover:border-2 h-14 mt-5 text-center py-3.5 rounded-md border border-violet-700 justify-center items-center gap-2.5 inline-flex"
                              >
                                <div
                                  onClick={handleIsEligible}
                                  style={{ cursor: "pointer" }}
                                  className={`${
                                    isEligiblecChecking
                                      ? "disabled:bg-violet-400"
                                      : ""
                                  } text-violet-700 text-base font-medium font-['Poppins'] leading-7 tracking-tight`}
                                >
                                  {isEligiblecChecking
                                    ? "Processing..."
                                    : t("writeReview")}
                                </div>
                              </div>
                              {isEligibleErrMsg && (
                                <div className="mb-4 p-2 rounded-md bg-white text-red-600">
                                  {isEligibleErrMsg}
                                </div>
                              )}
                              {showReviewField && (
                                <div>
                                  <form onSubmit={handleAddReview}>
                                    <div>
                                      <div className="my-5">
                                        <p>
                                          {t("rateProduct")}
                                          <span className="font-light">
                                            {t("bad5good")}
                                          </span>
                                        </p>
                                        <select
                                          className="w-full h-8 bg-white border border-gray-300 rounded" // Add a border
                                          onChange={(e) =>
                                            setUserRating(e.target.value)
                                          }
                                          required
                                        >
                                          {ratingOptions.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="md:w-96 flex-col mb-4 ">
                                        <textarea
                                          id="description"
                                          name="description"
                                          className="w-96 h-36 border-gray-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 py-1"
                                          value={description}
                                          onChange={handleDescriptionChange}
                                          placeholder="Enter Customer Review..."
                                          style={{ borderWidth: "0.2px" }}
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="relative w-96">
                                      <button
                                        className={`bg-violet-800 hover:bg-violet-700 px-4 py-1 text-white rounded-md ml-auto absolute right-0 ${
                                          isAddingReview
                                            ? "disabled:bg-violet-700"
                                            : ""
                                        }`}
                                        style={{ marginLeft: "auto" }}
                                        type="submit"
                                      >
                                        {isAddingReview
                                          ? "Submitting..."
                                          : t("submit")}
                                      </button>
                                      {successMessage && (
                                        <div className="bg-white text-green-500">
                                          {successMessage}
                                        </div>
                                      )}
                                      {errMsg && (
                                        <div className="bg-white text-red-600">
                                          {errMsg}
                                        </div>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              )}
                            </div>{" "}
                          </div>{" "}
                        </>
                      )}
                      {itemReviews?.length !== 0 && (
                        <div className="px-4 md:px-0 md:w-96">
                          <div
                            style={{ cursor: "pointer" }}
                            className="px-12 hover:border-2 h-14 mt-5 text-center py-3.5 rounded-md border border-violet-700 justify-center items-center gap-2.5 inline-flex"
                          >
                            <div
                              onClick={handleIsEligible}
                              style={{ cursor: "pointer" }}
                              className={`${
                                isEligiblecChecking
                                  ? "disabled:bg-violet-400"
                                  : ""
                              } text-violet-700 text-base font-medium font-['Poppins'] leading-7 tracking-tight`}
                            >
                              {isEligiblecChecking
                                ? "Processing..."
                                : t("writeReview")}
                            </div>
                          </div>
                          {isEligibleErrMsg && (
                            <div className="mb-4 p-2 rounded-md bg-white text-red-600">
                              {isEligibleErrMsg}
                            </div>
                          )}
                          {showReviewField && (
                            <div>
                              <form onSubmit={handleAddReview}>
                                <div>
                                  <div className="my-5">
                                    <p>
                                      {t("rateProduct")}
                                      <span className="font-light">
                                        {t("bad5good")}
                                      </span>
                                    </p>
                                    <select
                                      className="w-full h-8 bg-white border border-gray-300 rounded" // Add a border
                                      onChange={(e) =>
                                        setUserRating(e.target.value)
                                      }
                                      required
                                    >
                                      {ratingOptions.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="md:w-96 flex-col mb-4 ">
                                    <textarea
                                      id="description"
                                      name="description"
                                      className="w-96 h-36 border-gray-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 py-1"
                                      value={description}
                                      onChange={handleDescriptionChange}
                                      placeholder="Enter Customer Review..."
                                      style={{ borderWidth: "0.2px" }}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="relative w-96">
                                  <button
                                    className={`bg-violet-800 hover:bg-violet-700 px-4 py-1 text-white rounded-md ml-auto absolute right-0 ${
                                      isAddingReview
                                        ? "disabled:bg-violet-700"
                                        : ""
                                    }`}
                                    style={{ marginLeft: "auto" }}
                                    type="submit"
                                  >
                                    {isAddingReview
                                      ? "Submitting..."
                                      : t("submit")}
                                  </button>
                                  {successMessage && (
                                    <div className="bg-white text-green-500">
                                      {successMessage}
                                    </div>
                                  )}
                                  {errMsg && (
                                    <div className="bg-white text-red-600">
                                      {errMsg}
                                    </div>
                                  )}
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="w-[90%] mb-5 md:mx-20 h-[38px] pt-2.5 border-b border-neutral-400 justify-start items-center gap-2.5 inline-flex">
              <div className="text-black text-[23px] font-medium font-['Poppins'] leading-7 tracking-tight">
                {t("related")}
              </div>
            </div>
            <div className="relative">
              {/* Left Arrow */}
              {scrollLeft > 0 && (
                <button
                  className="absolute left-[3%] z-50 top-1/2 transform -translate-y-1/2 w-[34px] h-[33.37px] px-3 py-2 rounded-[40px] border border-purple-500 justify-center items-center inline-flex"
                  onClick={() => handleButtonClick(scrollLeft - 800)}
                >
                  <FaLessThan size={25} color="purple" />
                </button>
              )}

              {/* Scrollable Container */}
              <div
                className="md:mx-20 flex gap-2 overflow-x-auto example"
                style={{
                  width: "90%",
                }}
                ref={containerRef}
                onScroll={handleScroll}
              >
                {fetchedItems?.map((item, index) => (
                  <SearchCard2 key={index} item={item} />
                ))}
              </div>

              {/* Right Arrow */}
              {containerRef.current &&
                containerRef.current.scrollWidth - scrollLeft >
                  containerRef.current.clientWidth && (
                  <button
                    className="absolute right-[1%] z-50 top-1/2 transform -translate-y-1/2 w-[34px] h-[33.37px] px-3 py-2 rounded-[40px] border border-purple-500 justify-center items-center inline-flex"
                    onClick={() => handleButtonClick(scrollLeft + 800)}
                  >
                    <FaGreaterThan size={25} color="purple" />
                  </button>
                )}
            </div>
          </div>
        )}
        <Footer></Footer>
      </div>
    </>
  );
}
export default ProductDetailsPage;
