import { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import Select from "react-select";
import { ImUpload3 } from "react-icons/im";
import countryOptions from "./countrylIst";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useChangeProfileMutation } from "../../features/auth/authApiSlice";
import {
  selectCurrentName,
  selectCurrentCart,
  selectSelectedProfileBar,
  setSelectedProfileBar,
  selectCurrentCountry,
  selectCurrentRegion,
  selectCurrentCity,
  selectCurrentStreetAddress,
  selectCurrentPhoneNumber,
  selectCurrentPostalCode,
  selectCurrentHomeNumber,
  selectCurrentUser,
  setBuyer,
  selectCurrentAdditionalStreetAddress,
  selectCurrentZipCode,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFileUploadMutation } from "../../features/auth/fileApiSlice";

function EditProfile() {
  const { t } = useTranslation();
  const email = useSelector(selectCurrentUser);
  const name = useSelector(selectCurrentName);
  const country = useSelector(selectCurrentCountry);
  const region = useSelector(selectCurrentRegion);
  const city = useSelector(selectCurrentCity);
  const streetAddress = useSelector(selectCurrentStreetAddress);
  const additionalStreetAddress = useSelector(
    selectCurrentAdditionalStreetAddress
  );
  const zipCode = useSelector(selectCurrentZipCode);
  const phoneNumber = useSelector(selectCurrentPhoneNumber);
  const postalCode = useSelector(selectCurrentPostalCode);
  const homeNumber = useSelector(selectCurrentHomeNumber);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeProfile, { isLoading }] = useChangeProfileMutation();

  const [message, setMessage] = useState(null); // Add message state

  const [fullName, setFullName] = useState(name);
  const [localCountry, setLocalCountry] = useState(country);
  const [localRegion, setLocalRegion] = useState(region);
  const [localCity, setLocalCity] = useState(city);
  const [localStreetAddress, setLocalStreetAddress] = useState(streetAddress);
  const [localAdditionalStreetAddress, setLocalAdditionalStreetAddress] =
    useState(additionalStreetAddress);
  const [localZipCode, setLocalZipCode] = useState(zipCode);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);
  const [localPostalCode, setLocalPostalCode] = useState(postalCode);
  const [localHomeNumber, setLocalHomeNumber] = useState(homeNumber);

  const [photo, setPhoto] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState({
    label: country, // Assuming country is a string
    value: country,
  });

  // Step 2: Create a list of available countries with their codes
  // const countryOptions = [
  //   { label: 'United States', value: 'US' },
  //   { label: 'United Kingdom', value: 'UK' },
  //   // Add more countries as needed
  // ];

  const [selectedCountryCode, setSelectedCountryCode] = useState();

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handlePhotoChange = (event) => {
    // Handle file selection and update state
    const selectedPhoto = event.target.files[0];
    setPhoto(selectedPhoto);
  };

  const [valuephone, setValue] = useState(phoneNumber);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handlevalueChange = (event) => {
    setValue(event.target.value);
  };

  const handleCountryChange = (event) => {
    setLocalCountry(event.target.value);
  };
  const handleRegionChange = (event) => {
    setLocalRegion(event.target.value);
  };
  const handleCityChange = (event) => {
    setLocalCity(event.target.value);
  };
  const handleStreetAddressChange = (event) => {
    setLocalStreetAddress(event.target.value);
  };
  const handleAdditionalStreetAddressChange = (event) => {
    setLocalAdditionalStreetAddress(event.target.value);
  };

  const handelPhoneNumberChange = (event) => {
    setLocalPhoneNumber(event.target.value);
  };
  const handelPostalCodechange = (event) => {
    setLocalPostalCode(event.target.value);
  };

  const [IsNotValidHomeNumber, setIsNotValidHomeNumber] = useState(false);
  const [IsNotValidZipCode, setIsNotValidZipCode] = useState(false);
  const handleAdditionaZipCodeChange = (event) => {
    if (isNaN(event.target.value)) {
      setIsNotValidZipCode(true);
    } else {
      setIsNotValidZipCode(false);
    }
    setLocalZipCode(event.target.value);
  };
  const handleHomeNumberChange = (event) => {
    if (isNaN(event.target.value)) {
      setIsNotValidHomeNumber(true);
    } else {
      setIsNotValidHomeNumber(false);
    }
    setLocalHomeNumber(event.target.value);
  };

  const [fileUpload] = useFileUploadMutation();

  const getImageUrl = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fileUpload(formData);
        console.log(response);
        return response.data.url;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } catch (error) {
      // Handle errors if the request fails
      console.error(error);
      throw error;
    }
  };

  const successMessageRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message && message.type === "success" && successMessageRef.current) {
      successMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (IsNotValidHomeNumber || IsNotValidZipCode) {
      setMessage({
        text: "Please Check for errors.",
        type: "error",
      });
      return;
    }
    setIsSubmitting(true);

    // Upload all selected images and wait for all promises to resolve
    let uploadPromise = null;
    let imageUrl = null;
    if (photo) {
      imageUrl = await getImageUrl(photo);
    }
    try {
      const userData = await changeProfile({
        fullName,
        email,
        country: selectedCountry ? selectedCountry.value : null,
        state: localRegion,
        city: localCity,
        streetAddress: localStreetAddress,
        phoneNumber: valuephone,
        postalCode: localPostalCode,
        homeNumber: localHomeNumber,
        imgUrl: imageUrl,
        additionalStreetAddress: localAdditionalStreetAddress,
        zipCode: localZipCode,
      }).unwrap();

      const country = userData.country ? userData.country : null;
      const region = userData.state ? userData.state : null;
      const city = userData.city ? userData.city : null;
      const streetAddress = userData.streetAddress
        ? userData.streetAddress
        : null;
      const phoneNumber = userData.phoneNumber ? userData.phoneNumber : null;
      const postalCode = userData.postalCode ? userData.postalCode : null;
      const homeNumber = userData.homeNumber ? userData.homeNumber : null;
      const userImgUrl = userData.imgUrl ? userData.imgUrl : null;

      dispatch(
        setBuyer({
          country: country,
          region: region,
          city: city,
          streetAddress: streetAddress,
          phoneNumber: phoneNumber,
          postalCode: postalCode,
          homeNumber: homeNumber,
          imgUrl: userImgUrl,
        })
      );

      toast.success("Profile Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // After all image uploads are complete, you can perform other actions
    } catch (error) {
      setMessage({
        text: "Failed to update the product. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={`editProfileScroll px-6 py-4 bg-white w-full`}
        style={{ height: "" }}
      >
        <div className="w-full mb-4 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          <div>{t("edit_profile")}</div>
          <button
            onClick={() => {
              dispatch(
                setSelectedProfileBar({
                  SelectedProfileBar: "Reset Password",
                })
              );
            }}
            className="ml-auto block bg-purple-600 hover:bg-purple-500 text-white rounded-md px-2 py-1"
          >
            Reset Password
          </button>
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div className="flex-col">
            <label
              htmlFor="validationDefault01"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("full_name")}
            </label>
            <input
              type="text"
              value={fullName}
              className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
              id="validationDefault01"
              required
              placeholder="Full Name"
              onChange={handleFullNameChange}
              style={{ borderWidth: "1px" }}
            />
          </div>
          <div>
            <label
              htmlFor="Country"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("country")}
            </label>
            <Select
              id="Country"
              value={selectedCountry}
              onChange={handleCountrySelect}
              options={countryOptions}
              className="block w-full    text-neutral-600 text-md font-normal font-['Poppins'] leading-7   placeholder:text-gray-400   mb-4  sm:leading-6"
            />
          </div>

          <div>
            <label
              htmlFor="State"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("state")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="State"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your State"
                value={localRegion}
                onChange={handleRegionChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="City"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("city")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="City"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your City"
                value={localCity}
                onChange={handleCityChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="streetAddress"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("street_address")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="streetAddress"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your StreetAddress Number"
                value={localStreetAddress}
                onChange={handleStreetAddressChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="additionalstreetAddress"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("street_address")}(Additional)
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="additionalstreetAddress"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your StreetAddress Number"
                value={localAdditionalStreetAddress}
                onChange={handleAdditionalStreetAddressChange}
                style={{ borderWidth: "1px" }}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            {/* Country Code Dropdown */}
            <div className="flex-shrink-0">
              <label
                htmlFor="Country Code"
                className="block text-md font-medium leading-6 text-gray-900 mr-2"
              >
                {t("phone_number")}
              </label>
              <PhoneInput
                className="number w-full"
                value={valuephone}
                onChange={setValue}
              />
            </div>
            <div className="relative  border-gray-400  w-80 flex-grow">
              <label
                htmlFor="Postal Code"
                className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
              >
                {t("postal_code")}
              </label>
              <div className="relative  border-gray-400">
                <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3 ml-4"></div>
                <input
                  type="text"
                  id="Postal Code"
                  className="relative block w-full   rounded-md  pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 sm:leading-6"
                  placeholder="Enter your Postal Code"
                  value={localPostalCode}
                  onChange={handelPostalCodechange}
                  style={{
                    borderWidth: "1px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    bottom: "2px",
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="Home Number"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("home_number")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="Home Number"
                className={`block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7        mb-4  sm:leading-6 ${
                  IsNotValidHomeNumber
                    ? "placeholder:text-red-500"
                    : "placeholder:text-gray-400"
                }`}
                placeholder="Enter your Home Number"
                value={localHomeNumber}
                onChange={handleHomeNumberChange}
                style={{ borderWidth: "1px" }}
                required
              />
              {IsNotValidHomeNumber && (
                <p className="text-red-600">Home Number Should be a number</p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="zipCode"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              ZipCode
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="zipCode"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-neutral-600 text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your Home Number"
                value={localZipCode}
                onChange={handleAdditionaZipCodeChange}
                style={{ borderWidth: "1px" }}
                required
              />
              {IsNotValidZipCode && (
                <p className="text-red-600">Zip Code Should be a number</p>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="photo"
              className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
            >
              {t("profile_photo")}
            </label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={handlePhotoChange}
              className="block w-full mb-4"
            />
          </div>

          <div className="w-full flex-col justify-center items-center mt-14">
            <button
              className={` w-64 block  hover:bg-purple-600 text-white rounded-md px-3 py-1 
              ${isSubmitting ? "disabled:bg-purple-200" : ""}`}
              type="submit"
              onSubmit={handleSubmit}
              style={{ backgroundColor: "var(--themeBold)" }}
            >
              {isSubmitting ? t("submitting") : t("edit_profile")}
            </button>
            {message && (
              <div>
                <div
                  id="successMessage"
                  ref={successMessageRef}
                  className={` bg-white my-4 flex h-14 items-center rounded-md w-3/5 al p-2`}
                >
                  <div
                    className={` bg-white w-80  rounded-md  ${
                      message.type === "success"
                        ? "text-green-500"
                        : "text-red-500"
                    } `}
                  >
                    {message.text}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default EditProfile;
