// StarSvg.js
import React from "react";

const StarSvg = ({ def_key, type }) => (
  <svg
    key={type ? `empty-${def_key}` : def_key}
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
      fill="#EAA346"
    />
  </svg>
);

export default StarSvg;
