import { FaRegMoneyBillAlt, FaShippingFast } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetAdvertsMutation } from '../features/auth/authApiSlice';

const Advert = () => {
  const { t } = useTranslation();
  const [adverts, setAdverts] = useState([]);

  const [getAdverts, { isLoading: isGettiing }] = useGetAdvertsMutation();
  const [updateNumber, setUpdateNumber] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAdverts().unwrap();
        console.log('result');
        console.log(result);

        setAdverts(result);

        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [updateNumber]);
  return (
    <div className="flex justify-center">
      <div className="flex gap-16 my-20 flex-wrap">
        {adverts?.length !== 0 &&
          adverts?.map((advert) => (
            <div className="flex items-center gap-2 w-80 md:w-auto">
              <div
                className="h-14 w-14   flex justify-center items-center rounded-full "
                style={{ borderWidth: '3px', borderColor: 'red' }}
              >
                <i class={`bx ${advert.icon} bx-md text-red-500`}></i>{' '}
              </div>
              <div>
                <p className="font-semibold text-lg">{advert.name}</p>
                <p className="opacity-70 text-base">{advert.rule}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Advert;
