// components/AddPopularCategoryForm.js
import React, { useState, useEffect } from "react";
import {
  useAddPopularCatagoryMutation,
  useCreateCatagoryMutation,
  useGetCatagoriesByTypeMutation,
} from "../../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useFileUploadMutation } from "../../features/auth/fileApiSlice";

const AddPopularCategoryForm = () => {
  const { t } = useTranslation();
  const [mainCatagory, setMainCatagory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [getCatagoriesByType, { isLoading: getCatagoriesByTypeIsLoading }] =
    useGetCatagoriesByTypeMutation();
  const [addPopularCatagory, { isLoading: addPopularCatagoryLoading }] =
    useAddPopularCatagoryMutation();

  const [fileUpload] = useFileUploadMutation();
  const getImageUrl = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fileUpload(formData);
        console.log(response);
        return response.data.url;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } catch (error) {
      // Handle errors if the request fails
      console.error(error);
      throw error;
    }
  };

  const [photo, setPhoto] = useState(null);

  const handlePhotoChange = (event) => {
    // Handle file selection and update state
    const selectedPhoto = event.target.files[0];
    setPhoto(selectedPhoto);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getCatagoriesByType().unwrap();

        setCategories(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [ErrMsg, setErrMsg] = useState(null);

  const handleAddAnother = async (e) => {
    e.preventDefault();

    setPhoto(null);

    setMainCatagory(null);
    setSuccessMsg(null);
    setErrMsg(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMsg(null);
    setErrMsg(null);
    // Make a request to your backend API to add the new category
    try {
      const imageUrl = await getImageUrl(photo);

      const response = await addPopularCatagory({
        name: mainCatagory,
        avatar: imageUrl,
      });

      setSuccessMsg("Catagory Added successfully");
    } catch (e) {
      setErrMsg("unable to add Catagory");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t("addPuopularCatagories")}
        </div>
        {isLoading ? (
          <p>{t("loading")}...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              {t("chooseMainCatagory")}:
              <select
                value={mainCatagory}
                onChange={(e) => setMainCatagory(e.target.value)}
                required
                className="w-full h-10  border-gray-400   border 
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              >
                <option value="">Select Main Category</option>
                {categories
                  .filter((category) => category.type === "mainCatagory")
                  .map((mainCategory) => (
                    <option key={mainCategory._id} value={mainCategory.name}>
                      {mainCategory.name}
                    </option>
                  ))}
              </select>
            </label>
            <div>
              <label
                htmlFor="photo"
                className="block text-neutral-600 text-md font-normal font-['Poppins'] leading-7"
              >
                {t("choosePhoto")}
              </label>
              <input
                type="file"
                id="photo"
                accept="image/*"
                onChange={handlePhotoChange}
                className="block w-full mb-4"
                required
              />
            </div>

            <button
              className="bg-violet-800 hover:bg-violet-700 text-white px-2 py-1 rounded-md"
              type="submit"
            >
              {isSubmitting ? t("adding") : t("addCategory")}
            </button>
            {successMsg && (
              <div className="flex gap-4  items-center">
                <div className="bg-white text-green-600 p-2 rounded-md mt-3">
                  {t("catagorysuccessMsg")}
                </div>
                <button
                  onClick={handleAddAnother}
                  className="bg-violet-800 mt-3 h-8 hover:bg-violet-700 px-2 py-1 text-white rounded-md"
                >
                  {t("addAnother")}
                </button>
              </div>
            )}
            {ErrMsg && (
              <p bg-white text-red-600 p-2 rounded-md mt-3>
                {t("catagoryerrMsg")}
              </p>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default AddPopularCategoryForm;
