import { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryOptions from "../Buyer/countrylIst";

import { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { useChangeSellerProfileMutation } from "../../features/auth/authApiSlice";
import {
  selectCurrentName,
  selectCurrentCountry,
  selectCurrentRegion,
  selectCurrentCity,
  selectCurrentStreetAddress,
  selectCurrentPhoneNumber,
  selectCurrentPostalCode,
  selectCurrentHomeNumber,
  selectCurrentUser,
  setBuyer,
  selectCurrentAccountNumber,
  selectCurrentAdditionalAccountNumber,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFileUploadMutation } from "../../features/auth/fileApiSlice";

function SellerEditProfile() {
  const email = useSelector(selectCurrentUser);
  const Companyname = useSelector(selectCurrentName);
  const country = useSelector(selectCurrentCountry);
  const region = useSelector(selectCurrentRegion);
  const city = useSelector(selectCurrentCity);
  const streetAddress = useSelector(selectCurrentStreetAddress);
  const phoneNumber = useSelector(selectCurrentPhoneNumber);
  const postalCode = useSelector(selectCurrentPostalCode);
  const homeNumber = useSelector(selectCurrentHomeNumber);
  const accountNumber = useSelector(selectCurrentAccountNumber);
  const additionalAccountNumber = useSelector(
    selectCurrentAdditionalAccountNumber
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeSellerProfile, { isLoading }] = useChangeSellerProfileMutation();

  const [message, setMessage] = useState(null); // Add message state

  const [name, setFullName] = useState(Companyname);
  const [localAccountNumber, setLocalAccountNumber] = useState(accountNumber);
  const [localAdditionalAccountNumber, setLocalAdditionalAccountNumber] =
    useState(additionalAccountNumber);
  const [localCountry, setLocalCountry] = useState(country);
  const [localRegion, setLocalRegion] = useState(region);
  const [localCity, setLocalCity] = useState(city);
  const [localStreetAddress, setLocalStreetAddress] = useState(streetAddress);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);
  const [localPostalCode, setLocalPostalCode] = useState(postalCode);
  const [localHomeNumber, setLocalHomeNumber] = useState(homeNumber);

  const [photo, setPhoto] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState({
    label: country, // Assuming country is a string
    value: country,
  });

  // Step 2: Create a list of available countries with their codes
  // const countryOptions = [
  //   { label: 'United States', value: 'US' },
  //   { label: 'United Kingdom', value: 'UK' },
  //   // Add more countries as needed
  // ];

  const [selectedCountryCode, setSelectedCountryCode] = useState();

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handlePhotoChange = (event) => {
    // Handle file selection and update state
    const selectedPhoto = event.target.files[0];
    setPhoto(selectedPhoto);
  };

  const [valuephone, setValue] = useState(phoneNumber);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleAccountNumberChange = (event) => {
    setLocalAccountNumber(event.target.value);
  };

  const handleAdditionalAccountNumberChange = (event) => {
    setLocalAdditionalAccountNumber(event.target.value);
  };
  const handlevalueChange = (event) => {
    setValue(event.target.value);
  };

  const handleCountryChange = (event) => {
    setLocalCountry(event.target.value);
  };
  const handleRegionChange = (event) => {
    setLocalRegion(event.target.value);
  };
  const handleCityChange = (event) => {
    setLocalCity(event.target.value);
  };
  const handleStreetAddressChange = (event) => {
    setLocalStreetAddress(event.target.value);
  };
  const handelPhoneNumberChange = (event) => {
    setLocalPhoneNumber(event.target.value);
  };
  const handelPostalCodechange = (event) => {
    setLocalPostalCode(event.target.value);
  };
  const handleHomeNumberChange = (event) => {
    setLocalHomeNumber(event.target.value);
  };

  const [fileUpload] = useFileUploadMutation();
  const getImageUrl = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fileUpload(formData);
        console.log(response);
        return response.data.url;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } catch (error) {
      // Handle errors if the request fails
      console.error(error);
      throw error;
    }
  };

  const successMessageRef = useRef(null);

  useEffect(() => {
    if (message && message.type === "success" && successMessageRef.current) {
      successMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Upload all selected images and wait for all promises to resolve
    let uploadPromise = null;
    let imageUrl = null;
    if (photo) {
      imageUrl = await getImageUrl(photo);
    }
    try {
      // console.log(`fullName: ${name}`);
      // console.log(`email: ${email}`);
      // console.log(`city: ${localCity}`);
      // console.log(`streetAddress: ${localStreetAddress}`);
      // console.log(`phonenUmber: ${valuephone}`);
      // console.log(`postal: ${localPostalCode}`);

      const userData = await changeSellerProfile({
        name,
        email,
        country: selectedCountry ? selectedCountry.value : null,
        state: localRegion,
        city: localCity,
        streetAddress: localStreetAddress,
        phoneNumber: valuephone,
        postalCode: localPostalCode,
        imgUrl: imageUrl,
        accountNumber: localAccountNumber,
        additionalAccountNumber: localAdditionalAccountNumber,
      }).unwrap();

      const country = userData.country ? userData.country : null;
      const region = userData.state ? userData.state : null;
      const city = userData.city ? userData.city : null;
      const streetAddress = userData.streetAddress
        ? userData.streetAddress
        : null;
      const phoneNumber = userData.phoneNumber ? userData.phoneNumber : null;
      const postalCode = userData.postalCode ? userData.postalCode : null;
      const homeNumber = userData.homeNumber ? userData.homeNumber : null;
      const userImgUrl = userData.imgUrl ? userData.imgUrl : null;

      dispatch(
        setBuyer({
          country: country,
          region: region,
          city: city,
          streetAddress: streetAddress,
          phoneNumber: phoneNumber,
          postalCode: postalCode,
          homeNumber: homeNumber,
          imgUrl: userImgUrl,
        })
      );

      console.log(userData);

      setMessage({ text: "Profile Updated successfully!", type: "success" });

      // After all image uploads are complete, you can perform other actions
    } catch (error) {
      console.log(error);
      setMessage({
        text: "Failed to update the Profile. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <div
        className={`  px-6 py-4 bg-gray-100 w-full`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        <div className="w-full mb-4 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t("edit_profile")}
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div className="flex-col">
            <label
              htmlFor="validationDefault01"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("full_name")}
            </label>
            <input
              type="text"
              value={name}
              className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault01"
              required
              placeholder="Full Name"
              onChange={handleFullNameChange}
              style={{ borderWidth: "1px" }}
            />
          </div>
          <div className="flex-col">
            <label
              htmlFor="validationDefault090"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              IBAN
            </label>
            <input
              type="text"
              value={localAccountNumber}
              className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault090"
              required
              placeholder="Account Number"
              onChange={handleAccountNumberChange}
              style={{ borderWidth: "1px" }}
            />
          </div>
          <div className="flex-col">
            <label
              htmlFor="validationDefault0900"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              SWIFT/BIC
            </label>
            <input
              type="text"
              value={localAdditionalAccountNumber}
              className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault0900"
              placeholder="Account Number"
              onChange={handleAdditionalAccountNumberChange}
              style={{ borderWidth: "1px" }}
            />
          </div>
          <div>
            <label
              htmlFor="Country"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("country")}
            </label>
            <Select
              id="Country"
              value={selectedCountry}
              onChange={handleCountrySelect}
              options={countryOptions}
            />
          </div>

          <div>
            <label
              htmlFor="State"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("state")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="State"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your State"
                value={localRegion}
                onChange={handleRegionChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="City"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("city")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="City"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your City"
                value={localCity}
                onChange={handleCityChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="streetAddress"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("street_address")}
            </label>
            <div className="relative  border-gray-400">
              <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
              <input
                type="text"
                id="streetAddress"
                className="block w-full   rounded-md py-2 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                placeholder="Enter your StreetAddress Number"
                value={localStreetAddress}
                onChange={handleStreetAddressChange}
                style={{ borderWidth: "1px" }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            {/* Country Code Dropdown */}
            <div className="flex-shrink-0">
              <label
                htmlFor="Country Code"
                className="block text-md font-medium leading-6 text-gray-900 mr-2"
              >
                {t("phone_number")}
              </label>
              <PhoneInput
                className="number w-full"
                value={valuephone}
                onChange={setValue}
              />
            </div>
            <div className="relative  border-gray-400  w-80 flex-grow">
              <label
                htmlFor="Postal Code"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t("postal_code")}
              </label>
              <div className="relative  border-gray-400">
                <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3 ml-4"></div>
                <input
                  type="text"
                  id="Postal Code"
                  className="relative block w-full   rounded-md  pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 sm:leading-6"
                  placeholder="Enter your Postal Code"
                  value={localPostalCode}
                  onChange={handelPostalCodechange}
                  style={{
                    borderWidth: "1px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    bottom: "2px",
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="photo"
              className="block text-md font-medium leading-6 text-gray-900"
            >
              {t("profile_photo")}
            </label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={handlePhotoChange}
              className="block w-full mb-4"
            />
          </div>

          <div className="w-full flex-col justify-center items-center mt-14">
            <button
              className={`bg-violet-800 w-64 block  hover:bg-violet-400 text-white rounded-md px-3 py-1 
              ${isSubmitting ? "disabled:bg-orange-200" : ""}`}
              type="submit"
              onSubmit={handleSubmit}
            >
              {isSubmitting ? t("submitting") : t("edit_profile")}
            </button>
            {message && (
              <div>
                <div
                  id="successMessage"
                  ref={successMessageRef}
                  className={` bg-white my-4 flex h-14 items-center rounded-md w-3/5 al p-2`}
                >
                  <div
                    className={` bg-white w-80  rounded-md  ${
                      message.type === "success"
                        ? "text-green-500"
                        : "text-red-500"
                    } `}
                  >
                    {message.text}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default SellerEditProfile;
