import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useGetPrivacyPolicyyMutation } from "../features/auth/authApiSlice";
import LoadingPlaceholder from "../components/LoadingPlaceholder";

function Imprint() {
  const [getPrivacyPolicyy, { isLoading: isGettiing }] =
    useGetPrivacyPolicyyMutation();
  const [privacyPolicies, setPrivacyPolicy] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getPrivacyPolicyy({
          type: "imprint",
        }).unwrap();
        console.log("result");
        console.log(result);

        setPrivacyPolicy(result);

        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const linkify = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part, index) =>
      urlPattern.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div>
      <Navbar />
      {isGettiing ? (
        <LoadingPlaceholder />
      ) : (
        <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
          <div className="container mx-auto my-8 p-4">
            {privacyPolicies &&
              privacyPolicies?.map((policy) => (
                <div className="mb-4">
                  <h2 className="text-lg">
                    <strong>{policy.title}</strong>
                  </h2>
                  {policy.paragraph.map((paragraph) => (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {linkify(paragraph)}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Imprint;
// <div>
// <Navbar />
// <div className="font-sans text-gray-800 bg-gray-100 p-4 sm:p-8 md:p-12 lg:p-16">
//   <div className="container mx-auto my-8 p-4">
//     <section>
//       <h3 class="text-lg font-bold mb-2">Contact Information</h3>
//       <p class="mb-2">
//         Adresse: Emil Barth Straße 99, 40595 Düsseldorf, Deutschland
//       </p>
//       <p class="mb-2">Telefon: +4915112359297</p>
//       <p class="mb-2">Email: info@saletoeurope.com</p>
//       <p class="mb-2">Firmenregistrierung / Düsseldorf/Deutschland</p>
//       <p class="mb-2">
//         Für den Kundenservice im Falle von Rückerstattungen/Rückbuchungen
//         für die Zahlung über Paypal und Sofort:
//       </p>
//       <p>
//         Sale to Europe <br /> info@saletoeurope.com
//       </p>
//     </section>

//     <section>
//       <h3 class="text-lg font-bold mb-2">
//         Online Dispute Resolution (ODR)
//       </h3>
//       <p class="mb-2">
//         Die Europäische Kommission stellt eine Plattform zur
//         Online-Streitbeilegung (OS) bereit, die Sie{" "}
//         <a
//           href="https://ec.europa.eu/consumers/odr/"
//           class="text-blue-500 underline"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           hier finden
//         </a>
//         .
//       </p>
//       <p class="mb-2">
//         Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
//         Verbraucherschlichtungsstelle sind wir nicht bereit und
//         verpflichtet.
//       </p>
//     </section>
//   </div>
// </div>
// <Footer />
// </div>
