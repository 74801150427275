import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { FaMinusCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import {
  useAddAdvertMutation,
  useAddPrivacyPolicyyMutation,
  useDeleteAdvertMutation,
  useDeletePrivacyPolicyyMutation,
  useEditAdvertMutation,
  useEditPrivacyPolicyyMutation,
  useGetAdvertsMutation,
  useGetPrivacyPolicyyMutation,
  useStripePayMutation,
} from "../../features/auth/authApiSlice";
import { icons } from "react-icons";

const PrivacyPolicyForm = ({
  onDelete,
  advert,
  handlechangeUpdateNumber,
  limit,
}) => {
  const { t } = useTranslation();

  const [addAdvert, { isLoading: addingprivacypolcy }] = useAddAdvertMutation();
  const [editAdvert, { isLoading: editingprivacypolicy }] =
    useEditAdvertMutation();
  const [deleteAdvert, { isLoading: isdeleting }] = useDeleteAdvertMutation();
  const [icon, setIcon] = useState(advert.icon);
  const handleIconChange = (event) => {
    setIcon(event.target.value);
  };
  const [name, setName] = useState(advert.name);
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [rule, setRule] = useState(advert.rule);
  const handleRuleChange = (event) => {
    setRule(event.target.value);
  };

  const handleDelete = async (e) => {
    console.log(advert);
    console.log("policy");
    if (advert.isNew) {
      console.log(advert._id);
      // If it's a new policy (not yet saved), delete it directly from the state
      onDelete(advert._id);
    } else {
      // If it's an existing policy, use the mutation to delete it from the server
      try {
        await deleteAdvert({ id: advert._id }).unwrap();
        handlechangeUpdateNumber();

        toast.success(`Advert Deleted`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        console.error("Unable to Delete:", error);
        toast.error(`Unable to Delete Advert`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "150px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (advert.isNew) {
        const result = await addAdvert({
          icon,
          name,
          rule,
        }).unwrap();
        console.log(result);
        handlechangeUpdateNumber();
        toast.success(`Advert Added`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.log("not new");
        const result = await editAdvert({
          id: advert._id,
          icon,
          name,
          rule,
        }).unwrap();
        console.log(result);
        handlechangeUpdateNumber();
        toast.success(`Advert Edited`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch {
      toast.error("Unable to do the operation", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="border p-4 mb-8 rounded-md shadow-md">
      <div className="flex">
        <h2 className="text-xl font-bold mb-4">{advert.name}</h2>
        <div onClick={openModal} className="ml-auto cursor-pointer">
          <MdDelete color="#ea580c" size={"30"} />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <label className="block text-md font-medium leading-6 text-gray-900">
          {t("icon")}
        </label>
        <input
          type="text"
          value={icon}
          className="w-full h-10 border-gray-400 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
          required
          placeholder="icon"
          onChange={handleIconChange}
          style={{ borderWidth: "1px" }}
        />
        <label className="block text-md font-medium leading-6 text-gray-900">
          {t("name")}
        </label>
        <input
          type="text"
          value={name}
          className="w-full h-10 border-gray-400 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
          required
          placeholder="icon"
          onChange={handleNameChange}
          style={{ borderWidth: "1px" }}
        />
        <label className="block text-md font-medium leading-6 text-gray-900">
          {t("rule")}
        </label>
        <input
          type="text"
          value={rule}
          className="w-full h-10 border-gray-400 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
          required
          placeholder="icon"
          onChange={handleRuleChange}
          style={{ borderWidth: "1px" }}
        />

        <button
          type="submit"
          className="bg-violet-800 hover:bg-violet-700 text-white px-4 py-2 rounded-md "
        >
          {addingprivacypolcy || editingprivacypolicy
            ? "Loading..."
            : advert.isNew
            ? t("addAdvert")
            : t("editAdvert")}
        </button>
      </form>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div>
          <p className="text-center">
            {" Are You Sure You want to delete this Advert?"}
          </p>

          <div className="flex justify-center mt-5">
            <button
              className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
              type="button"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              className={`${
                isdeleting ? "disabled:bg-red-400" : ""
              } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
              type="button"
              onClick={handleDelete}
            >
              {isdeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const AddAdvert = () => {
  const { t } = useTranslation();

  const [adverts, setAdverts] = useState([]);

  const [getAdverts, { isLoading: isGettiing }] = useGetAdvertsMutation();
  const [updateNumber, setUpdateNumber] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAdverts().unwrap();
        console.log("result");
        console.log(result);

        setAdverts(result);

        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [updateNumber]);
  function generateRandomText(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomText += charset[randomIndex];
    }

    return randomText;
  }
  const handleDeleteAdvert = (advertId) => {
    // Filter out the deleted policy from the state
    const updatedAdverts = adverts.filter((advert) => advert._id !== advertId);
    setAdverts(updatedAdverts);
  };

  const handlechangeUpdateNumber = () => {
    setUpdateNumber(updateNumber + 1);
  };

  const addNewComponent = () => {
    const randomText = generateRandomText(20);

    const newAdvert = {
      _id: randomText,
      icon: "",
      name: "",
      rule: "",
      isNew: true,
    };
    setAdverts([...adverts, newAdvert]);
    console.log(adverts);
  };

  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t("adverts")}
        </div>
        {adverts?.length !== 0 &&
          adverts?.map((advert) => (
            <PrivacyPolicyForm
              onDelete={handleDeleteAdvert}
              key={advert._id}
              advert={advert}
              handlechangeUpdateNumber={handlechangeUpdateNumber}
              limit={adverts.length}
            />
          ))}
        {adverts.length < 4 && (
          <div>
            <button
              type="button"
              onClick={addNewComponent}
              className="bg-violet-800 hover:bg-violet-700 text-white px-4 py-2 rounded-md "
            >
              <FaPlusCircle color="orange" size={"24"} />
            </button>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default AddAdvert;
