import { useState } from "react";
import { RiShoppingCartLine } from "react-icons/ri";
import React from "react";
import { useAddCartMutation } from "../features/auth/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrenRole,
  selectCurrentCart,
  selectCurrentName,
  selectCurrentProductIdTobeDetailed,
  selectCurrentUser,
  setProductToBeDetailedId,
  setUser,
} from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/card.css";
import StarSvg from "../assets/svgs/Stars";

const Card = ({ item }) => {
  const { t } = useTranslation();
  const {
    itemName,
    avatarUrl,
    rating,
    price,
    _id,
    isDiscounted,
    discountAmount,
  } = item;
  const [isHovered, setIsHovered] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const handleMouseEnter = () => {
    //console.log('Hovered');
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderRatingStars = () => {
    //console.log('rating');
    //console.log(rating);
    if (!rating) {
      return null; // Return null if rating is null
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];
    for (let i = 0; i < filledStars; i++) {
      stars.push(<StarSvg type={false} def_key={i} key={i} />);
    }

    if (hasHalfStar) {
      stars.push(<StarSvg def_key={filledStars} type={false} />);
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<StarSvg def_key={i} type={true} key={i} />);
    }
    return stars;
  };

  const dispatch = useDispatch();

  const [addCart, { isLoading }] = useAddCartMutation();
  const email = useSelector(selectCurrentUser);
  const fullName = useSelector(selectCurrentName);
  const cart = useSelector(selectCurrentCart);
  const [isAddingtocart, setIsAddingToCart] = useState(false);
  const currentRole = useSelector(selectCurrenRole);

  function formatPrice(price) {
    // Convert the price to a number
    const numericPrice = parseFloat(price);

    // Check if the price has cents
    if (Number.isInteger(numericPrice)) {
      // If it's a whole number, add .00
      return `${numericPrice.toFixed(2)}`;
    } else {
      // If it has cents, leave it as it is
      return `${numericPrice.toFixed(2)}`;
    }
  }

  const handleAddToCart = async (itemId, event) => {
    event.stopPropagation();
    if (!email && currentRole !== process.env.REACT_APP_BUYER_ROLE) {
      navigate("/login");
      return;
    }
    setIsAddingToCart(true);
    if (cart.includes(itemId)) {
      setIsAdded(true);
      toast.success("Item Added to Cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //console.log(`Item with ID ${itemId} is already in the cart.`);
      setIsAddingToCart(false);

      return;
    }

    // Your logic for adding to cart goes here
    const userData = await addCart({
      fullName,
      email,
      cartItemId: itemId,
    }).unwrap();

    //onsole.log(userData);
    dispatch(
      setUser({
        id: userData._id,
        email: userData.email,
        name: userData.fullName,
        role: userData.role,
        accountStatus: userData.accountStatus,
        cart: userData.cart,
      })
    );
    setIsAdded(true);
    toast.success("Item Added to Cart!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setIsAddingToCart(false);
  };
  const navigate = useNavigate();
  const itemIdgolba = useSelector(selectCurrentProductIdTobeDetailed);

  return (
    <div
      className="w-[200px]  bg-white rounded-[7px] cursor-pointer border border-stone-400 flex-col justify-start items-center pb-1 inline-flex  max-w-sm  overflow-hidden shadow-lg  relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        navigate(`/productdetail?productId=${_id}`);
      }}
    >
      <div className="relative">
        {isDiscounted && (
          <div
            className="absolute z-40 top-1 left-1 h-10 w-10  bg-fuchsia-700 rounded-[50px] justify-center items-center inline-flex"
            // style={{ zIndex: 100 }}
          >
            <div className="text-center text-white text-[12px] font-normal font-['Poppins'] tracking-tight">
              {discountAmount}%
            </div>
          </div>
        )}
        <div className="justify-center items-center gap-2.5 inline-flex  bg-white  ">
          <img
            onClick={() => {
              navigate(`/productdetail?productId=${_id}`);
            }}
            className="w-[200px] h-[200px] max-w-full max-h-full rounded-xl transform hover:scale-105 hover:duration-300"
            src={avatarUrl}
            style={{ objectFit: "contain" }}
            alt="Card"
          />
        </div>

        <div className="px-2 ">
          <div className=" text-neutral-500  h-[30px] text-[16px]  mt-2 font-medium font-['Poppins'] leading-[20px]">
            {itemName?.length > 30 ? `${itemName?.slice(0, 30)}...` : itemName}
          </div>
          <div className="flex items-center mt-3 mb-3">
            {renderRatingStars()}
          </div>
          <div className="flex items-center  gap-4 mb-2">
            {isDiscounted && (
              <div className="text-stone-600 text-base font-normal font-['Poppins'] line-through leading-7 tracking-tight">
                €{formatPrice(price / (1 - discountAmount / 100))}
              </div>
            )}
            <div className=" text-stone-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight">
              €{formatPrice(price)}
            </div>

            {/* <p className="text-gray-400 mx-4">|</p> */}

            {/* <a
            className={`${
              isAddingtocart ? 'disabled:bg-orange-300' : ''
            } text-orange-500  `}
            onClick={() => handleAddToCart(itemId)}
          >
            {isAddingtocart ? 'Adding...' : `${t('addtocart')}`}
          </a> */}
            {/* {isAdded && <span className="ml-3 text-green-500">added</span>} */}
          </div>
          <div
            onClick={(event) => handleAddToCart(_id, event)}
            className={`${isHovered ? "visible" : "md:invisible"} ${
              isAddingtocart ? "disabled:bg-purple-300" : ""
            } px-[20px] w-full z-50 mt-2 py-0.5 rounded-[3px] bg-purple-700 hover:bg-purple-600 justify-center items-center  inline-flex`}
          >
            <div
              className="text-white text-xs font-semibold font-['Poppins'] leading-7 tracking-tight"
              style={{ fontSize: "12px" }}
            >
              {isAddingtocart ? "Adding..." : `${t("addtocart")}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
