import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Us, De, Tr } from "react-flags-select";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const languageOptions = [
    { code: "en", label: "EN", flag: <Us /> },
    { code: "de", label: "DE", flag: <De /> },
    { code: "tr", label: "TR", flag: <Tr /> },
    // Add more language options as needed
  ];

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (language) => {
    //console.log(language);
    i18n.changeLanguage(language);
    setDropdownOpen(false);
  };

  return (
    <div className="relative sm:mr-4">
      <button
        className="flex items-center"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        {languageOptions.find((option) => option.code === i18n.language)?.flag}
        <span className="w-1"></span>
        {languageOptions.find((option) => option.code === i18n.language)?.label}
      </button>
      {/* Dropdown */}
      {isDropdownOpen && (
        <div className="absolute mt-1 w-16 bg-white border border-gray-300 divide-y divide-gray-200">
          {languageOptions.map((option) => (
            <button
              key={option.code}
              onClick={() => changeLanguage(option.code)}
              className="flex items-center gap-1 px-1 py-2 text-sm text-gray-700 hover:bg-gray-100  w-full text-left"
            >
              {option?.flag} {option?.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
