import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentPendingOrders,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import { useGetOrdersByStatusSellerMutation } from "../../features/auth/authApiSlice";
import OrderDetail from "./OrderDetail";

function OrderDetailsPage({ orderStatus }) {
 
  const [fetchedOrders, setFetchedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const pendingOrderIdsList = useSelector(selectCurrentPendingOrders);
  const sellerId = useSelector(selectCurrentUserId);
  const [getOrdersByStatusAndSeller, { isSellerLoading }] =
    useGetOrdersByStatusSellerMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (orderStatus === "pending") {
          const result = await getOrdersByStatusAndSeller({
            orderStatus: "pending",
            sellerId: sellerId,
          }).unwrap();


          setFetchedOrders(result);
        } else if (orderStatus === "preparing") {
          const result = await getOrdersByStatusAndSeller({
            orderStatus: "preparing",
            sellerId: sellerId,
          }).unwrap();

          setFetchedOrders(result);
        } else if (orderStatus === "ontheway") {
          const result = await getOrdersByStatusAndSeller({
            orderStatus: "ontheway",
            sellerId: sellerId,
          }).unwrap();

          setFetchedOrders(result);
        } else if (orderStatus === "delivered") {
          const result = await getOrdersByStatusAndSeller({
            orderStatus: "delivered",
            sellerId: sellerId,
          }).unwrap();

          setFetchedOrders(result);
        } else if (orderStatus === "cancelled") {
          const result = await getOrdersByStatusAndSeller({
            orderStatus: "cancelled",
            sellerId: sellerId,
          }).unwrap();

          setFetchedOrders(result);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [sellerId]); // Include 'Id' as a dependency to refetch data when 'Id' changes

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1)} Orders
          </div>
          {fetchedOrders.map((order, index) => (
            <OrderDetail
              index={index}
              order={order}
              orderStatus={orderStatus}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default OrderDetailsPage;
