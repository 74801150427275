import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { MdCancel } from "react-icons/md";

function Failure() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBackClick = () => {
    // Add the path to your profile screen
    navigate("/buyerprofile");
  };
  return (
    <>
      <div class="bg-gray-100 h-screen">
        <div class="bg-white p-6  md:mx-auto">
          <div class="text-center">
            <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
              {t("Stripe.Payment Cancel")}
            </h3>
            <p class="text-gray-600 my-2">{t("Stripe.incomplete")}</p>
            <p>{t("Stripe.Wish")}</p>
            <div class="py-10 cursor-pointer grid" onClick={handleGoBackClick}>
              <p class="px-12 bg-purple-500 hover:bg-purple-400 text-white font-semibold py-3 w-max justify-self-center rounded">
                {t("Stripe.direct")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Failure;
