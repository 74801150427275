import { useEffect, useState } from "react";
import {
  useAddTrendingProductssMutation,
  useGetAllItemsByPageMutation,
  useSearchAllItemsMutation,
} from "../../features/auth/authApiSlice";
import {
  addSelectedTrendingProducts,
  removeAllSelectedTrendingProducts,
  removeSelectedTrendingProducts,
  selectCurrentSelectedTrendingProducts,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { SiTruenas } from "react-icons/si";
import AdminCard from "./AdminCard";
import { t } from "i18next";

function AllProduct({ selectedSidebarItem, onSelectSidebarItem }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const sellerId = useSelector(selectCurrentUserId);
  const selectedProducts = useSelector(selectCurrentSelectedTrendingProducts);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // You can add logic here to handle the search query (e.g., filter products).
  };
  const [fetchedItems, setFetchedItems] = useState([]);

  const [searchAllItems, { isLoading }] = useSearchAllItemsMutation();

  const handleSearch = async () => {
    setIsFetching(true);
    setIsSearching(true);

    const SearchResults = await searchAllItems({
      query: searchQuery,
    });

    setFetchedItems(SearchResults.data);

    setIsFetching(false);
    // Add logic here to handle the search action based on the searchQuery.
  };
  const [isFetching, setIsFetching] = useState(false);

  const [getAllItemsByPage, { isSellerLoading }] =
    useGetAllItemsByPageMutation();

  const [addTrendingProductss, { isAddingTrendingProduct }] =
    useAddTrendingProductssMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [controlUseEffect, setControlUseEffect] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const result = await getAllItemsByPage({
          currentPage,
        }).unwrap();

        setFetchedItems(result);

        dispatch(removeAllSelectedTrendingProducts());

        setIsFetching(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [sellerId, controlUseEffect]);

  const handlePageChange = async (newPage) => {
    try {
      setIsFetching(true);
      const result = await getAllItemsByPage({
        sellerId: sellerId,
        page: newPage,
      }).unwrap();

      setFetchedItems(result);
      setCurrentPage(newPage);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [selectedTrendingProductIds, setSelectedTrendingProducts] = useState(
    []
  );
  const dispatch = useDispatch();
  const addToSelectedProducts = (productId) => {
    console.log(productId);
    setSelectedTrendingProducts([...selectedTrendingProductIds, productId]);
    dispatch(addSelectedTrendingProducts());
    console.log(selectedTrendingProductIds);
  };
  const removeFromSelectedProducts = (productId) => {
    setSelectedTrendingProducts(
      selectedTrendingProductIds.filter((id) => id !== productId)
    );
    dispatch(removeSelectedTrendingProducts());

    console.log(selectedTrendingProductIds);
  };

  const [isAddingTrending, setIsAddingTrending] = useState(false);
  const handleAddtrendingProducts = async () => {
    try {
      console.log("we are here we are here ");
      setIsAddingTrending(true);
      const result = await addTrendingProductss({
        productIdList: selectedTrendingProductIds,
      }).unwrap();

      console.log("resulttttttttttttttttttttttttttt");
      console.log(result);
      setSelectedTrendingProducts([]);
      setControlUseEffect(controlUseEffect + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsAddingTrending(true);
    }
  };

  return (
    <>
      <div
        className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          <span>{t("allProducts")}</span>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={t("searchPlaceholder")}
            className="ml-4 px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={handleSearch}
            className={`${
              isLoading ? "disabled:bg-violet-400" : ""
            } ml-2 px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md`}
          >
            {isLoading ? t("searching") : t("search")}
          </button>
          <div className="ml-auto">
            ({selectedProducts}){t("selected")}
          </div>
          {selectedProducts > 0 && (
            <button
              onClick={handleAddtrendingProducts}
              className={`${
                isAddingTrending ? "disabled:bg-violet-400" : ""
              } ml-2 px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md`}
            >
              {isAddingTrending ? t("Adding") : t("addTrending")}
            </button>
          )}
        </div>
        {isFetching ? (
          <p>loading...</p>
        ) : (
          <div className="flex md:flex-wrap gap-4 scrollpart">
            {fetchedItems.map((item, index) => (
              <AdminCard
                addToSelectedProducts={addToSelectedProducts}
                removeFromSelectedProducts={removeFromSelectedProducts}
                key={index}
                item={item}
                selectedSidebarItem={selectedSidebarItem}
                onSelectSidebarItem={onSelectSidebarItem}
                handlePageChange={handlePageChange}
                page={currentPage}
              />
            ))}
          </div>
        )}
        {isSearching ? (
          <div>
            <button
              onClick={() => {
                setIsSearching(false);
                setSearchQuery("");
                handlePageChange(currentPage);
              }}
              className="mt-4 px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md mr-2"
            >
              {t("goBack")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md mr-2"
              disabled={currentPage === 1}
            >
              {t("previous")}
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md"
            >
              {t("next")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default AllProduct;
