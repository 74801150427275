import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    refreshtoken: null,
    selectedItem: null,
    currentPage: 1,
    id: null,
    couponIdTobeEdited: null,
    catagoryIdTobeEdited: null,
    accountNumber: null,
    additionalAccountNumber: null,
    email: null,
    name: null,
    role: null,
    accountStatus: null,
    cart: null,
    pendingOrders: [],
    preparingOrders: [],
    onthewayOrders: [],
    deliveredOrders: [],
    canceledOrders: [],
    pendingSellers: [],
    verifiedSellers: null,
    blockedSellers: [],
    blockedBuyers: [],
    verifiedBuyers: null,
    cartItems: [],
    sellerAdminData: null,
    mainCatagoryNames: [],
    country: null,
    region: null,
    city: null,
    streetAddress: null,
    additionalStreetAddress: null,
    zipCode: null,
    phoneNumber: null,
    postalCode: null,
    homeNumber: null,
    userImgUrl: null,
    items: [],
    SelectedProfileBar: "Dashboard",
    totalCartItems: null,
    totalPrice: null,
    productTobeEdited: null,
    productTobeDetailed: null,
    totalNumberOfItems: null,
    totalNumberOfReviews: null,
    selectedTrendingProducts: 0,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, refreshtoken } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.refreshtoken = refreshtoken;
    },
    setProductId: (state, action) => {
      const { productId } = action.payload;
      state.productTobeEdited = productId;
    },
    setCurrentPage: (state, action) => {
      const { page } = action.payload;
      state.currentPage = page;
    },

    addSelectedTrendingProducts: (state, action) => {
      state.selectedTrendingProducts += 1;
    },
    removeSelectedTrendingProducts: (state, action) => {
      state.selectedTrendingProducts -= 1;
    },
    removeAllSelectedTrendingProducts: (state, action) => {
      state.selectedTrendingProducts = 0;
    },
    setTotalReviewsAndItems: (state, action) => {
      const { reviewLength, itemLength } = action.payload;
      state.totalNumberOfItems = itemLength;
      state.totalNumberOfReviews = reviewLength;
    },
    setProductToBeDetailedId: (state, action) => {
      const { productId } = action.payload;
      state.productTobeDetailed = productId;
    },
    setCouponIdTobeEdited: (state, action) => {
      const { couponId } = action.payload;
      state.couponIdTobeEdited = couponId;
    },
    setCatagoryIdTobeEdited: (state, action) => {
      const { catagoryId } = action.payload;
      state.catagoryIdTobeEdited = catagoryId;
    },
    setCheckout: (state, action) => {
      const { totalCartItems, totalPrice } = action.payload;
      state.totalCartItems = totalCartItems;
      state.totalPrice = totalPrice;
    },
    setGlobalCartItems: (state, action) => {
      const { cartItems } = action.payload;
      state.cartItems = cartItems;
    },
    addPendingSeller: (state, action) => {
      const { sellerId } = action.payload;
      if (!state.pendingSellers.includes(sellerId)) {
        state.pendingSellers.push(sellerId);
      }
    },

    setSellerAdminData: (state, action) => {
      const { data } = action.payload;

      state.sellerAdminData = data;
    },

    addMainCatagories: (state, action) => {
      const { catagoryname } = action.payload;
      if (!state.mainCatagoryNames.includes(catagoryname)) {
        state.mainCatagoryNames.push(catagoryname);
      }
    },
    removeFromPendingSeller: (state, action) => {
      const { sellerId } = action.payload;
      state.pendingSellers = state.pendingSellers.filter(
        (id) => id !== sellerId
      );
    },
    addVerifiedSeller: (state, action) => {
      const { number } = action.payload;
      state.verifiedSellers = number;
    },
    removeFromVerifiedSeller: (state, action) => {
      const { number } = action.payload;
      state.verifiedSellers -= number;
    },
    addBlockedSeller: (state, action) => {
      const { sellerId } = action.payload;
      if (!state.blockedSellers.includes(sellerId)) {
        state.blockedSellers.push(sellerId);
      }
    },
    removeFromblockedSeller: (state, action) => {
      const { sellerId } = action.payload;
      state.blockedSellers = state.blockedSellers.filter(
        (id) => id !== sellerId
      );
    },
    addVerifiedBuyer: (state, action) => {
      const { number } = action.payload;
      state.verifiedBuyers = number;
    },
    removeFromVerifiedBuyer: (state, action) => {
      const { number } = action.payload;
      state.verifiedBuyers -= number;
    },
    addBlockedBuyer: (state, action) => {
      const { buyerId } = action.payload;
      if (!state.blockedBuyers.includes(buyerId)) {
        state.blockedBuyers.push(buyerId);
      }
    },
    removeFromBlockedBuyer: (state, action) => {
      const { buyerId } = action.payload;
      state.blockedBuyers = state.blockedBuyers.filter((id) => id !== buyerId);
    },
    addPendingOrder: (state, action) => {
      const { orderId } = action.payload;
      if (!state.pendingOrders.includes(orderId)) {
        state.pendingOrders.push(orderId);
      }
    },
    setAllOrderStatus: (state, action) => {
      state.pendingOrders = [];
      state.preparingOrders = [];
      state.onthewayOrders = [];
      state.deliveredOrders = [];
      state.canceledOrders = [];
    },

    setAllUserStatus: (state, action) => {
      state.pendingSellers = [];
      state.verifiedSellers = null;
      state.blockedSellers = [];
      state.blockedBuyers = [];
      state.verifiedBuyers = null;
    },

    addPreparingOrder: (state, action) => {
      const { orderId } = action.payload;
      if (!state.preparingOrders.includes(orderId)) {
        state.preparingOrders.push(orderId);
      }
    },
    addOntheWayOrder: (state, action) => {
      const { orderId } = action.payload;
      if (!state.onthewayOrders.includes(orderId)) {
        state.onthewayOrders.push(orderId);
      }
    },
    addDeliveredOrders: (state, action) => {
      const { orderId } = action.payload;
      if (!state.deliveredOrders.includes(orderId)) {
        state.deliveredOrders.push(orderId);
      }
    },
    addCanceledOrders: (state, action) => {
      const { orderId } = action.payload;
      if (!state.canceledOrders.includes(orderId)) {
        state.canceledOrders.push(orderId);
      }
    },

    removeFromPendingOrder: (state, action) => {
      const { orderId } = action.payload;
      state.pendingOrders = state.pendingOrders.filter((id) => id !== orderId);
    },
    removeFromPreparingOrder: (state, action) => {
      const { orderId } = action.payload;
      state.preparingOrders = state.preparingOrders.filter(
        (id) => id !== orderId
      );
    },
    removeFromOnthewayOrder: (state, action) => {
      const { orderId } = action.payload;
      state.onthewayOrders = state.onthewayOrders.filter(
        (id) => id !== orderId
      );
    },
    removeFromDeliveredOrder: (state, action) => {
      const { orderId } = action.payload;
      state.deliveredOrders = state.deliveredOrders.filter(
        (id) => id !== orderId
      );
    },
    removeFromCanceledOrder: (state, action) => {
      const { orderId } = action.payload;
      state.canceledOrders = state.canceledOrders.filter(
        (id) => id !== orderId
      );
    },

    setQuantityofCartItems: (state, action) => {
      const { quantity } = action.payload;
      for (let i = 0; i < state.cartItems.length; i++) {
        if (state.cartItems[i].data) {
          state.cartItems[i].data.quantity = quantity;
        }

        // If you only want to update the first matching item, you can break out of the loop
        // break;
      }
    },
    setQuantityofCartItemsById: (state, action) => {
      const { quantity, id } = action.payload;
      for (let i = 0; i < state.cartItems.length; i++) {
        if (state.cartItems[i].data._id === id) {
          state.cartItems[i].data.quantity = quantity;
          // If you only want to update the first matching item, you can break out of the loop
          // break;
        }
      }
    },
    setColorofCartItemsById: (state, action) => {
      const { color, id } = action.payload;
      for (let i = 0; i < state.cartItems.length; i++) {
        if (state.cartItems[i].data._id === id) {
          state.cartItems[i].data.color = color;
          // If you only want to update the first matching item, you can break out of the loop
          // break;
        }
      }
    },

    setUser: (state, action) => {
      const { id, email, name, role, accountStatus, cart } = action.payload;
      state.id = id;
      state.email = email;
      state.name = name;
      state.role = role;
      state.accountStatus = accountStatus;
      state.cart = cart;
    },
    setBuyer: (state, action) => {
      const {
        country,
        region,
        city,
        streetAddress,
        postalCode,
        phoneNumber,
        homeNumber,
        imgUrl,
        additionalStreetAddress,
        zipCode,
        accountNumber,
        additionalAccountNumber,
      } = action.payload;
      state.country = country;
      state.region = region;
      state.city = city;
      state.streetAddress = streetAddress;
      state.postalCode = postalCode;
      state.phoneNumber = phoneNumber;
      state.homeNumber = homeNumber;
      state.userImgUrl = imgUrl;
      state.additionalStreetAddress = additionalStreetAddress;
      state.zipCode = zipCode;
      state.accountNumber = accountNumber;
      state.additionalAccountNumber = additionalAccountNumber;
    },

    logOut: (state, action) => {
      state.user = null;
      state.token = null;
    },
    setSelectedItem: (state, action) => {
      const { selectedItem } = action.payload;
      state.selectedItem = selectedItem;
    },
    setSelectedProfileBar: (state, action) => {
      const { SelectedProfileBar } = action.payload;
      state.SelectedProfileBar = SelectedProfileBar;
    },
    setItems: (state, action) => {
      const { items } = action.payload;
      state.items = items;
    },
  },
});

export const {
  setSellerAdminData,
  setCredentials,
  logOut,
  setSelectedItem,
  setUser,
  setSelectedProfileBar,
  setBuyer,
  setCheckout,
  setGlobalCartItems,
  setQuantityofCartItems,
  setQuantityofCartItemsById,
  addPendingOrder,
  addPreparingOrder,
  addOntheWayOrder,
  addDeliveredOrders,
  addCanceledOrders,
  removeFromPendingOrder,
  removeFromPreparingOrder,
  removeFromOnthewayOrder,
  removeFromDeliveredOrder,
  removeFromCanceledOrder,
  setCouponIdTobeEdited,
  setProductId,
  setProductToBeDetailedId,
  addPendingSeller,
  addVerifiedSeller,
  addBlockedSeller,
  addVerifiedBuyer,
  addBlockedBuyer,
  removeFromPendingSeller,
  removeFromVerifiedSeller,
  removeFromblockedSeller,
  removeFromVerifiedBuyer,
  removeFromBlockedBuyer,
  setCatagoryIdTobeEdited,
  setTotalReviewsAndItems,
  addMainCatagories,
  setCurrentPage,
  setColorofCartItemsById,
  setAllOrderStatus,
  setAllUserStatus,
  addSelectedTrendingProducts,
  removeSelectedTrendingProducts,
  removeAllSelectedTrendingProducts,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectSelectedItem = (state) => state.auth.selectedItem;
export const selectCurrentUserId = (state) => state.auth.id;
export const selectCurrentEmail = (state) => state.auth.email;
export const selectCurrentName = (state) => state.auth.name;
export const selectCurrenRole = (state) => state.auth.role;
export const selectCurrentAccountStatus = (state) => state.auth.accountStatus;
export const selectCurrentCart = (state) => state.auth.cart;
export const selectCurrentSellerAdminData = (state) =>
  state.auth.sellerAdminData;
export const selectCurrentItems = (state) => state.auth.items;
export const selectSelectedProfileBar = (state) =>
  state.auth.SelectedProfileBar;
export const selectCurrentCountry = (state) => state.auth.country;
export const selectCurrentRegion = (state) => state.auth.region;
export const selectCurrentCity = (state) => state.auth.city;
export const selectCurrentStreetAddress = (state) => state.auth.streetAddress;
export const selectCurrentPhoneNumber = (state) => state.auth.phoneNumber;
export const selectCurrentPostalCode = (state) => state.auth.postalCode;
export const selectCurrentHomeNumber = (state) => state.auth.homeNumber;
export const selectCurrentTotalCartItems = (state) => state.auth.totalCartItems;
export const selectCurrentTotalprice = (state) => state.auth.totalPrice;
export const selectCurrentFetchedCartItems = (state) => state.auth.cartItems;
export const selectCurrentPendingOrders = (state) => state.auth.pendingOrders;
export const selectCurrentPreparingOrders = (state) =>
  state.auth.preparingOrders;
export const selectCurrentOntheWayOrders = (state) => state.auth.onthewayOrders;
export const selectCurrentDeliveredOrders = (state) =>
  state.auth.deliveredOrders;
export const selectCurrentCanceledOrders = (state) => state.auth.canceledOrders;
export const selectCurrentCouponIdTobeEdited = (state) =>
  state.auth.couponIdTobeEdited;
export const selectCurrentUserImgUrl = (state) => state.auth.userImgUrl;
export const selectCurrentProductIdTobeEdited = (state) =>
  state.auth.productTobeEdited;
export const selectCurrentProductIdTobeDetailed = (state) =>
  state.auth.productTobeDetailed;
export const selectCurrentPendingSellers = (state) => state.auth.pendingSellers;
export const selectCurrentVerifiedSellers = (state) =>
  state.auth.verifiedSellers;
export const selectCurrentBlockedSellers = (state) => state.auth.blockedSellers;
export const selectCurrentverifiedBuyers = (state) => state.auth.verifiedBuyers;
export const selectCurrentBlockedBuyers = (state) => state.auth.blockedBuyers;
export const selectCurrentCatagoryIdTobeEdited = (state) =>
  state.auth.catagoryIdTobeEdited;
export const selectCurrentTotalNumberOfItems = (state) =>
  state.auth.totalNumberOfItems;
export const selectCurrentTotalNumberOrReviews = (state) =>
  state.auth.totalNumberOfReviews;
export const selectCurrentMainCatagoryNames = (state) =>
  state.auth.mainCatagoryNames;
export const selectCurrentCurrentPage = (state) => state.auth.currentPage;
export const selectCurrentAdditionalStreetAddress = (state) =>
  state.auth.additionalStreetAddress;
export const selectCurrentZipCode = (state) => state.auth.zipCode;
export const selectCurrentAccountNumber = (state) => state.auth.accountNumber;
export const selectCurrentAdditionalAccountNumber = (state) =>
  state.auth.additionalAccountNumber;
export const selectCurrentSelectedTrendingProducts = (state) =>
  state.auth.selectedTrendingProducts;
