import SellerNavbar from "../../components/Seller/SellerNavbar";

function SellerDashBoard(){
    return <>
    <div>
        <SellerNavbar></SellerNavbar>
    </div>
    </>
}

export default SellerDashBoard;