import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetBuyerByAccountStatusAndPageMutation,
  useGetSellerByBusinessStatusAndPageMutation,
  useSignUpBuyerByAdminnMutation,
  useSignUpSelleByAdminMutation,
} from "../../features/auth/authApiSlice";
import {
  addVerifiedBuyer,
  addVerifiedSeller,
  selectCurrentPendingOrders,
  selectCurrentUserId,
  selectCurrentVerifiedSellers,
  selectCurrentverifiedBuyers,
} from "../../features/auth/authSlice";
import UserDetail from "./userDetail";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserStatusDetailsPage({ userStatus }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const verifiedSellers = useSelector(selectCurrentVerifiedSellers);
  const verifiedBuyers = useSelector(selectCurrentverifiedBuyers);

  const [emailForSignUp, setEmailSignUp] = useState("");
  const [passwordForsignUP, setPassWordSignUp] = useState("");
  const [fullName, setFullName] = useState("");

  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordmatch, setPasswordMatch] = useState(false);

  const handleConfirmPwdChange = (e) => setConfirmPassword(e.target.value);
  const handleEmailSignUpchange = (e) => setEmailSignUp(e.target.value);

  const handlefullNameChangeInput = (e) => setFullName(e.target.value);

  const handelPwdSignUpChange = (e) => setPassWordSignUp(e.target.value);

  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [firstFetchedItems, setfirstFetchedItems] = useState(null);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const pendingOrderIdsList = useSelector(selectCurrentPendingOrders);
  const sellerId = useSelector(selectCurrentUserId);
  const [getBuyerByAccountStatusAndPage, { isSellerLoading }] =
    useGetBuyerByAccountStatusAndPageMutation();
  const [getSellerByBusinessStatusAndPage, { isSellerByPageLoading }] =
    useGetSellerByBusinessStatusAndPageMutation();
  const [count, IncrementCount] = useState(0);
  const [signUpSelleByAdmin, { isSignnningUp }] =
    useSignUpSelleByAdminMutation();
  const [signUpBuyerByAdminn, { isSignnningBuyerUp }] =
    useSignUpBuyerByAdminnMutation();
  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const [errMsg, setErrMsg] = useState(null);
  const handleSignup = async (e) => {
    e.preventDefault();
    if (passwordForsignUP !== confirmpassword) {
      setErrMsg("Password Must Match");
      setPasswordMatch(true);
      return;
    }

    setIsSigningUP(true);
    try {
      setErrMsg(null);
      if (userStatus === "Verified Seller") {
        const userData = await signUpSelleByAdmin({
          name: fullName,
          email: emailForSignUp,
          password: passwordForsignUP,
          userLanguage: selectedLanguage,
        }).unwrap();
        if (userData[1] === "Email already exists") {
          setErrMsg("Email Already exists");
          return;
        }
        IncrementCount(count + 1);
        closeTrendinModal();
        toast.success("Seller Successfully Created!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(
          addVerifiedSeller({
            number: verifiedSellers + 1,
          })
        );
      } else if (userStatus === "Verified Buyer") {
        const userData = await signUpBuyerByAdminn({
          fullName,
          email: emailForSignUp,
          password: passwordForsignUP,
          userLanguage: selectedLanguage,
        }).unwrap();
        if (userData[1] === "Email already exists") {
          setErrMsg("Email Already exists");
          return;
        }
        IncrementCount(count + 1);
        closeTrendinModal();
        toast.success("Buyer Successfully Created!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(
          addVerifiedBuyer({
            number: verifiedBuyers + 1,
          })
        );
      }
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    } finally {
      setIsSigningUP(false);
    }
  };

  const [successMessage, setSuccessMsg] = useState();
  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "500px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };

  const [isAdding, setIsAdding] = useState(false);
  const [isSigningUp, setIsSigningUP] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeTrendinModal = () => {
    setIsModalOpen(false);
    setSuccessMsg(null);
    setErrMsg(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (userStatus === "Pending Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "pending",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Verified Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "verified",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Blocked Seller") {
          const page = 1;
          const result = await getSellerByBusinessStatusAndPage({
            businessStatus: "blocked",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Verified Buyer") {
          const page = 1;
          const result = await getBuyerByAccountStatusAndPage({
            accountStatus: "verified",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        } else if (userStatus === "Blocked Buyer") {
          const page = 1;
          const result = await getBuyerByAccountStatusAndPage({
            accountStatus: "blocked",
            page,
          }).unwrap();

          setFetchedUsers(result);
          setfirstFetchedItems(result.length);
        }

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [count]); // Include 'Id' as a dependency to refetch data when 'Id' changes
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (newPage) => {
    try {
      setIsLoading(true);
      if (userStatus === "Pending Seller") {
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "pending",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Verified Seller") {
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "verified",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Blocked Seller") {
        const result = await getSellerByBusinessStatusAndPage({
          businessStatus: "blocked",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Verified Buyer") {
        const result = await getBuyerByAccountStatusAndPage({
          accountStatus: "verified",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      } else if (userStatus === "Blocked Buyer") {
        const result = await getBuyerByAccountStatusAndPage({
          accountStatus: "blocked",
          page: newPage,
        }).unwrap();

        setFetchedUsers(result);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            <div>
              {userStatus.charAt(0).toUpperCase() + userStatus.slice(1)}
            </div>
            {/* {userStatus === 'Verified Buyer' ||
              userStatus === 'Blocked Buyer' ? 'Buyers' : 'Sellers'} */}
            {(userStatus === "Verified Seller" ||
              userStatus === "Verified Buyer") && (
              <button
                onClick={() => openModal()}
                className={` ml-2 px-4 py-1 bg-violet-800 ml-auto hover:bg-violet-700 text-white rounded-md`}
              >
                Add
              </button>
            )}
          </div>
          {fetchedUsers.length !== 0 &&
            fetchedUsers.map((user, index) => (
              <UserDetail key={index} user={user} accountStatus={userStatus} />
            ))}
          {firstFetchedItems > 32 && (
            <div className="mt-4 flex items-center">
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);

                  handlePageChange(currentPage - 1);
                }}
                className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md mr-2"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  handlePageChange(currentPage + 1);
                }}
                className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md"
              >
                Next
              </button>
            </div>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeTrendinModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <form className="mb-12" onSubmit={handleSignup}>
              <div>
                <div className="text-2xl font-normal text-center mb-6">
                  {t("signup")}
                </div>
                <label className="block mb-3">
                  <span className="block text-sm font-medium text-slate-700">
                    {t("fullname")}
                  </span>
                  <input
                    value={fullName}
                    onChange={handlefullNameChangeInput}
                    autoComplete="off"
                    required
                    type="text"
                    placeholder="Full Name"
                    className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
                  />
                </label>
                <label className="block mb-3">
                  <span className="block text-sm font-medium text-slate-700">
                    {t("email")}
                  </span>
                  <input
                    value={emailForSignUp}
                    onChange={handleEmailSignUpchange}
                    autoComplete="off"
                    required
                    type="email"
                    placeholder="Email"
                    className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
                  />
                </label>
                <label className="block">
                  <span className="block text-sm font-medium text-slate-700">
                    {t("password")}
                  </span>
                  <input
                    type="password"
                    id="password"
                    onChange={handelPwdSignUpChange}
                    value={passwordForsignUP}
                    required
                    placeholder="Password"
                    className="mt block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    "
                  />
                </label>
                <label className="block mt-4">
                  <span className="block text-sm font-medium text-slate-700">
                    Confirm Password
                  </span>
                  <input
                    type="password"
                    id="Confirmpassword"
                    onChange={handleConfirmPwdChange}
                    value={confirmpassword}
                    required
                    placeholder="Password"
                    className={`mt block w-full px-3 py-2 bg-white border  ${
                      passwordmatch ? "border-red-500" : "border-slate-300"
                    } rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
       
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
    `}
                  />
                </label>

                {successMessage && (
                  <p className="text-green-600 text-center">{successMessage}</p>
                )}
                {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}

                <div className="flex justify-center mt-5">
                  <button
                    className=" mt-4 text-white  text-center bg-gray-500 hover:bg-gray-400 mr-2 rounded-full w-28 py-1 px-3"
                    type="button"
                    onClick={closeTrendinModal}
                  >
                    {t("close")}
                  </button>
                  <button
                    className={` ${
                      isSigningUp ? "disabled:bg-orange-300" : ""
                    } mt-4 text-white  text-center bg-purple-700 hover:bg-purple-600 rounded-full w-28 py-1 px-3`}
                  >
                    {isSigningUp ? "Processing..." : t("signup")}
                  </button>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
}

export default UserStatusDetailsPage;
