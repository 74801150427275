import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckboxSharp } from "react-icons/io5";

import {
  setProductId,
  setProductToBeDetailedId,
  setSelectedItem,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { useState } from "react";
import {
  useDeleteItemMutation,
  useDeleteeTrendinggProducttMutation,
} from "../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminCard({
  addToSelectedProducts,
  removeFromSelectedProducts,
  item,
  selectedSidebarItem,
  onSelectSidebarItem,
  handlePageChange,
  page,
}) {
  const { t } = useTranslation();
  const [deleteItem, { isLoading: addItemIsLoading }] = useDeleteItemMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTrendingOpen, setIsModalTrendingOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openTrendingModal = () => {
    setIsModalTrendingOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handlePageChange(page);
  };
  const closeTrendinModal = () => {
    setIsModalTrendingOpen(false);
    handlePageChange(page);
  };

  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "150px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };
  const rating = 5;

  const renderRatingStars = (rating) => {
    if (!rating) {
      return null;
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <span key={i} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <span key={filledStars} className="text-orange-600">
          &#9733;
        </span>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span key={`empty-${i}`} className="text-gray-300">
          &#9733;
        </span>
      );
    }

    return stars;
  };

  const dispatch = useDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [successMessage, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsDeleting(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {
      const deltedItem = await deleteItem({
        itemId: item._id,
      });
      setSuccessMsg("Item successfully Deleted");
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1000);
    } catch (error) {
      console.log("Un able to Delete the Item");
      setErrMsg("Un able to Delete the Item");
    } finally {
      setIsDeleting(false);
    }
  };

  const [deleteeTrendinggProductt, { isDeletingTrendingProduct }] =
    useDeleteeTrendinggProducttMutation();

  const handleDelTrending = async (event) => {
    event.preventDefault();
    setIsDeleting(true);
    setErrMsg(null);
    setSuccessMsg(null);

    try {
      console.log("delt");
      const deltedItem = await deleteeTrendinggProductt({
        id: item._id,
      });
      console.log(deltedItem);

      setSuccessMsg("Item successfully Deleted From Trending Product");
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1000);
    } catch (error) {
      console.log("Un able to Delete the Item");
      setErrMsg("Un able to Delete the Item");
    } finally {
      setIsDeleting(false);
    }
  };
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);
  const handleSelect = (id) => {
    if (isSelected) {
      removeFromSelectedProducts(id);
    } else {
      addToSelectedProducts(id);
    }
    setIsSelected(!isSelected);
  };

  return (
    <>
      <div className="w-[200px] mt-2 flex bg-white p-2  rounded-md">
        <div>
          <img
            onClick={() => {
              navigate(`/productdetail?productId=${item._id}`);
            }}
            src={item.avatarUrl[0]}
            style={{ objectFit: "contain" }}
            className="w-20 h-20 shrink-0 transform hover:scale-105 hover:duration-300"
          ></img>
        </div>
        <div className="ml-2">
          <p>{item.itemName}</p>
          <div className="flex items-center">
            {renderRatingStars(item.rating)}
          </div>
          <p>{item.price[0]}€</p>
        </div>
        <div className="flex-col ml-2">
          <div
            type="button"
            onClick={openModal}
            className="self-end  ml-2 cursor-pointer hover:text-gray-400"
            // onClick={openDeleteModal}
          >
            <MdDelete
              size={24}
              color="rgb(156 163 175 / var(--tw-text-opacity))"
            />
          </div>

          {item.isTrendingProduct ? (
            <div
              type="button"
              // onClick={openModal}
              className="self-end  ml-2 cursor-pointer hover:text-gray-400 mt-11"
              onClick={() => openTrendingModal(item._id)}
            >
              {" "}
              <IoCheckboxSharp
                size={24}
                color="rgb(156 163 175 / var(--tw-text-opacity))"
              />
            </div>
          ) : (
            <>
              <div
                type="button"
                // onClick={openModal}
                className="self-end  ml-2 cursor-pointer hover:text-gray-400 mt-11"
                onClick={() => handleSelect(item._id)}
              >
                {isSelected ? (
                  <IoCheckboxSharp size={24} color="blue" />
                ) : (
                  <MdCheckBoxOutlineBlank
                    size={24}
                    color="rgb(156 163 175 / var(--tw-text-opacity))"
                  />
                )}
              </div>
            </>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div>
              {!successMessage && (
                <p className="text-center">{t("confirmDelete")}</p>
              )}
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeModal}
                >
                  {t("close")}
                </button>
                {!successMessage && (
                  <button
                    className={`${
                      isDeleting ? "disabled:bg-red-400" : ""
                    } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
                    type="button"
                    onClick={handleDelete}
                  >
                    {isDeleting ? t("deleting") : t("delete")}
                  </button>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isModalTrendingOpen}
            onRequestClose={closeTrendinModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div>
              {!successMessage && (
                <p className="text-center">
                  Are you sure you want to remove this item from trending
                  products?
                </p>
              )}
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeTrendinModal}
                >
                  {t("close")}
                </button>
                {!successMessage && (
                  <button
                    className={`${
                      isDeleting ? "disabled:bg-red-400" : ""
                    } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
                    type="button"
                    onClick={handleDelTrending}
                  >
                    {isDeleting ? t("deleting") : t("delete")}
                  </button>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default AdminCard;
