import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrenRole,
  selectCurrentCart,
  selectCurrentEmail,
  selectCurrentName,
  setProductToBeDetailedId,
  setUser,
} from "../features/auth/authSlice";
import { useState } from "react";
import { useAddCartMutation } from "../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SearchCard({ item }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  function formatPrice(price) {
    // Convert the price to a number
    const numericPrice = parseFloat(price);

    // Check if the price has cents
    if (Number.isInteger(numericPrice)) {
      // If it's a whole number, add .00
      return `${numericPrice.toFixed(2)}`;
    } else {
      // If it has cents, leave it as it is
      return `${numericPrice.toFixed(2)}`;
    }
  }

  const [addCart, { isLoading }] = useAddCartMutation();
  const email = useSelector(selectCurrentEmail);
  const fullName = useSelector(selectCurrentName);
  const cart = useSelector(selectCurrentCart);
  const [isAddingtocart, setIsAddingToCart] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const currentRole = useSelector(selectCurrenRole);

  const handleAddToCart = async (itemId, event) => {
    event.stopPropagation();
    if (!email && currentRole !== process.env.REACT_APP_BUYER_ROLE) {
      navigate("/login");
      return;
    }
    setIsAddingToCart(true);
    if (cart.includes(itemId)) {
      setIsAdded(true);
      toast.success("Item Added to Cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsAddingToCart(false);

      return;
    }

    // Your logic for adding to cart goes here
    const userData = await addCart({
      fullName,
      email,
      cartItemId: itemId,
    }).unwrap();

    dispatch(
      setUser({
        id: userData._id,
        email: userData.email,
        name: userData.fullName,
        role: userData.role,
        accountStatus: userData.accountStatus,
        cart: userData.cart,
      })
    );
    toast.success("Item Added to Cart!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setIsAdded(true);
    setIsAddingToCart(false);
  };

  const renderRatingStars = (rating) => {
    if (!rating) {
      const stars = Array(5).fill(
        <svg
          width="12"
          height="11"
          viewBox="0 0 14 13"
          fill="#fff" // Set fill to white
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#fff"
          />
        </svg>
      );

      return stars; // Return null if rating is null
    }
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <svg
          key={i}
          width="12"
          height="11"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    if (hasHalfStar) {
      stars.push(
        <svg
          key={filledStars}
          width="12"
          height="11"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <svg
          width="12"
          key={`empty-${i}`}
          height="11"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.80311 10.5019L9.98492 12.4263C10.5676 12.779 11.2806 12.2576 11.1273 11.5983L10.2839 7.97943L13.0977 5.54132C13.6114 5.09664 13.3354 4.25327 12.6607 4.1996L8.95754 3.88525L7.50847 0.46577C7.2478 -0.155257 6.35843 -0.155257 6.09775 0.46577L4.64868 3.87758L0.945524 4.19193C0.270828 4.2456 -0.0051841 5.08897 0.508505 5.53366L3.32229 7.97176L2.47892 11.5906C2.32558 12.2499 3.03861 12.7713 3.62131 12.4186L6.80311 10.5019Z"
            fill="#EAA346"
          />
        </svg>
      );
    }

    return stars;
  };
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        className="w-[170px] h-[275.67px] cursor-pointer  bg-white rounded-[7px] border border-stone-400 flex-col justify-start items-center inline-flex  max-w-sm  overflow-hidden shadow-lg pb-2 relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          navigate(`/productdetail?productId=${item._id}`);
        }}
        style={{ flexShrink: 0 }}
      >
        <div className="relative">
          {item.isDiscounted && (
            <div
              className="absolute z-40 top-1 left-1 h-8 w-8  bg-fuchsia-700 rounded-[50px] justify-center items-center inline-flex"
              // style={{ zIndex: 100 }}
            >
              <div className="text-center text-white text-[10px] font-normal font-['Poppins'] tracking-tight">
                {item.discountAmount}%
              </div>
            </div>
          )}
          <div className="justify-center items-center gap-2.5 inline-flex  bg-white  ">
            <img
              onClick={() => {
                navigate(`/productdetail?productId=${item._id}`);
              }}
              className="w-[150px] h-[150px] max-w-full max-h-full rounded-xl transform hover:scale-105 hover:duration-300"
              src={item.avatarUrl[0]}
              style={{ objectFit: "contain" }}
              alt="Card"
            />
          </div>

          <div className="px-2  ">
            <div className=" text-neutral-500 text-[18px]  mt-1 font-bold font-['Poppins'] leading-[13px]">
              {item.itemName.length > 10
                ? `${item.itemName.slice(0, 10)}...`
                : item.itemName}
            </div>
            <div className="flex items-center mt-3 mb-2">
              {renderRatingStars(item.rating)}
            </div>
            <div className="flex items-center  gap-1 ">
              {item?.isDiscounted && (
                <div className="text-stone-600 text-base font-normal font-['Poppins'] line-through leading-7 tracking-tight">
                  €{formatPrice(item?.price / (1 - item?.discountAmount / 100))}
                </div>
              )}
              <div className=" text-stone-600 text-base font-normal font-['Poppins'] leading-7 tracking-tight">
                €{formatPrice(item.price[0])}
              </div>

              {/* <p className="text-gray-400 mx-4">|</p> */}

              {/* <a
          className={`${
            isAddingtocart ? 'disabled:bg-orange-300' : ''
          } text-orange-500  `}
          onClick={() => handleAddToCart(itemId)}
        >
          {isAddingtocart ? 'Adding...' : `${t('addtocart')}`}
        </a> */}
              {/* {isAdded && <span className="ml-3 text-green-500">added</span>} */}
            </div>
            <div
              onClick={(event) => handleAddToCart(item._id, event)}
              className={`${isHovered ? "visible" : "md:invisible"} ${
                isAddingtocart ? "disabled:bg-purple-300" : ""
              }  w-full z-50 py-0.5 rounded-[3px] bg-purple-700 hover:bg-purple-600 justify-center items-center  inline-flex`}
            >
              <div
                className="text-white  font-semibold font-['Poppins'] leading-7 tracking-tight"
                style={{ fontSize: "11px" }}
              >
                {isAddingtocart ? "Adding..." : `${t("addtocart")}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchCard;
