import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAddShippingCountryMutation,
  useAddShippingRuleMutation,
  useDeleteShippingRegionMutation,
  useDeleteShippingRuleMutation,
  useGetItemsWithDiscountsMutation,
  useGetShippingCountriesMutation,
  useGetShippingRulesMutation,
  useUpdateShippingRuleMutation,
} from '../../features/auth/authApiSlice';
import AdminDiscountCard from './adminDiscountCard';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countryOptions from '../Buyer/countrylIst';
import Modal from 'react-modal';
import { FaMinus } from 'react-icons/fa';

function ShippingRule() {
  //   const [fetchedItems, setFetchedItems] = useState([]);
  //   const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [selectedRule, setSelectedRule] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const getSuffix = () => {
    // Conditionally determine the suffix based on the selected type
    if (type && type.value === 'price') {
      return '$';
    } else if (type && type.value === 'weight') {
      return 'Kg';
    }
    return ''; // Default to an empty string if type is not selected or recognized
  };

  const [shippingCountrySubmit, setshippingCountrySubmit] = useState(null);

  const shippingRangeOptions = [
    { label: t('Price'), value: 'price' },
    { label: t('Weight'), value: 'weight' },
  ];

  const [addShippingCountry, { isCOuntryLOading }] =
    useAddShippingCountryMutation();

  const [updateShippingRule, { isUpdateLoading }] =
    useUpdateShippingRuleMutation();

  const [deleteShippingRule, { isDeleteshippifsdLoading }] =
    useDeleteShippingRuleMutation();

  const [deleteShippingRegion, { isDeleteShippingRegionLoading }] =
    useDeleteShippingRegionMutation();

  const [addShippingRule, { isShippingLoading }] = useAddShippingRuleMutation();

  const [getShippingRules, { isShippingRulesLOading }] =
    useGetShippingRulesMutation();

  const [getShippingCountries, { isShippingCOuntiresLOading }] =
    useGetShippingCountriesMutation();

  const [render, setRender] = useState(false);

  const [successMsg, setSuccessMsg] = useState(null);
  const [ErrMsg, setErrMsg] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const deleteShippingRegionMethod = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const country = fetchedCountriesAll.find(
        (country) => country.name === shippingCountrySubmit,
      );
      let response;
      if (country) {
        response = await deleteShippingRegion({
          itemId: country._id,
        });
      }

      toast.success('Country Deleted Successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRender(!render);
      setSuccessMsg('Country Deleted Successfully');
    } catch (e) {
      setErrMsg('unable to Deleted Country');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddCountry = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await addShippingCountry({
        name: selectedCountry.value,
      });

      toast.success('Country Added Successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRender(!render);
      setSuccessMsg('Country Added Successfully');
    } catch (e) {
      setErrMsg('unable to add Country');
    } finally {
      setIsSubmitting(false);
    }
  };

  function formatPrice(price) {
    // Convert the price to a number
    const numericPrice = parseFloat(price);

    // Check if the price has cents
    if (Number.isInteger(numericPrice)) {
      // If it's a whole number, add .00
      return `${numericPrice.toFixed(2)}`;
    } else {
      // If it has cents, leave it as it is
      return `${numericPrice.toFixed(2)}`;
    }
  }

  const [fetchedItems, setFetchedItems] = useState([]);
  const [fetchedCountries, setFetchedCountries] = useState([]);
  const [fetchedCountriesAll, setFetchedCountriesAll] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openModal2 = () => {
    setIsModal2Open(true);
  };

  const closeModal2 = () => {
    setIsModal2Open(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customStyles = {
    content: {
      maxWidth: '800px', // Adjust the maximum width as needed
      maxHeight: '350px', // Adjust the maximum height as needed
      margin: 'auto',
    },
  };
  const customStyles2 = {
    content: {
      maxWidth: '400px', // Adjust the maximum width as needed
      maxHeight: '150px', // Adjust the maximum height as needed
      margin: 'auto',
    },
  };

  const [successMessage, setSuccessMessage] = useState();
  const [errMsg, setErrorMessage] = useState();

  const [shippingRuleName, setShippingRuleName] = useState();
  const [shippingRuleId, setShippingRuleId] = useState(null);

  const handleShippingRuleChange = (event) => {
    setShippingRuleName(event.target.value);
  };

  const [type, setType] = useState();
  const handleShippingRangeChange = (selectedOption) => {
    setType(selectedOption);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const [from, setFrom] = useState();

  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };

  const [to, setTo] = useState();

  const handleToChange = (event) => {
    setTo(event.target.value);
  };

  const [shippingPrice, setShippingPrice] = useState(null);
  const [globalUniqueCountries, setGlobalUniqueCountries] = useState(null);
  const handlePriceChange = (event) => {
    setShippingPrice(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getShippingRules().unwrap();
        const countries = await getShippingCountries().unwrap();
        

        const uniqueCountries = Array.from(
          new Set(result.map((rule) => rule.country)),
        );

        setGlobalUniqueCountries(uniqueCountries);

        setFetchedItems(result);
        const filteredCountries = countries.filter((country) => {
          // Assuming 'shippingRules' is an array of objects with a 'country' property
          return !result.some((rule) => rule.country === country.name);
        });
        setFetchedCountries(filteredCountries);
        setFetchedCountriesAll(countries);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [render]);

  const handleAddShippingRule = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await addShippingRule({
        name: shippingRuleName,
        type: type.value,
        from,
        to,
        shippingFee: shippingPrice,
        country: shippingCountrySubmit,
      });

      toast.success('Shipping Rule Added Successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRender(!render);
      setSuccessMsg('ShippingRule Added Successfully');
    } catch (e) {
      setErrMsg('unable to add Shipping Rule');
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleUpdateShippingRule = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      
      const response = await updateShippingRule({
        itemId: selectedRule,
        name: shippingRuleName,
        type: type.value,
        from,
        to,
        shippingFee: shippingPrice,
        country: shippingCountrySubmit,
      });

      toast.success('Shipping Rule Updated Successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRender(!render);
      setSuccessMsg('ShippingRule Added Successfully');
    } catch (e) {
      setErrMsg('unable to add Shipping Rule');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteShippingRule = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      
      const response = await deleteShippingRule({
        itemId: selectedRule,
      });

      toast.success('Shipping Rule Deleted Successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRender(!render);
      setSuccessMsg('ShippingRule Added Successfully');
    } catch (e) {
      setErrMsg('unable to add Shipping Rule');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
        style={{ overflowY: 'auto', height: 'calc(100vh - 56px)' }}
      >
        <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t('ShippingRule')}
        </div>
        {/* <div className="flex md:flex-wrap gap-4 scrollpart">
            {fetchedItems.map((item, index) => (
              <AdminDiscountCard
                key={index}
                item={item}
                // selectedSidebarItem={selectedSidebarItem}
                // onSelectSidebarItem={onSelectSidebarItem}
                // handlePageChange={handlePageChange}
                // page={currentPage}
              />
            ))}
          </div> */}
        <div className="flex flex-wrap gap-4">
          <div className="w-[25%] mt-10">
            <label
              htmlFor="Country"
              className="block  text-md font-normal font-['Poppins'] leading-7"
            >
              {t('country')}
            </label>
            <Select
              id="Country"
              value={selectedCountry}
              onChange={handleCountrySelect}
              options={countryOptions}
              className="block w-full     text-md font-normal font-['Poppins'] leading-7   placeholder:text-gray-400   mb-4  sm:leading-6"
            />
            <button
              className="bg-purple-700 hover:bg-purple-600 text-white px-2 py-1 rounded-md"
              onClick={handleAddCountry}
            >
              {isSubmitting ? t('adding') : t('Add Country')}
            </button>
          </div>

          <div className="flex-col w-[70%] pt-10">
            {globalUniqueCountries?.map((country, index) => (
              <div key={index} className="w-[100%]">
                {' '}
                <div className="flex justify-center items-center">
                  <div
                    className={`border-gray-300 ${
                      index !== 0 ? 'border-b' : ''
                    } w-[95%]`}
                  ></div>
                </div>
                <div className="w-[100%] bg-white p-2">
                  <div className="flex">
                    <div>Country: {country}</div>
                    <div
                      onClick={() => {
                        setshippingCountrySubmit(country);
                        openModal2();
                      }}
                      className="text-red-600 cursor-pointer ml-auto"
                    >
                      Delete
                    </div>
                    <div
                      onClick={() => {
                        setshippingCountrySubmit(country);
                        openModal();
                      }}
                      className="text-blue-600 cursor-pointer ml-4"
                    >
                      AddShippingrule
                    </div>
                  </div>
                  {fetchedItems
                    .filter((rule) => rule.country === country)
                    .map((rule, index) => (
                      <div key={index} className="mt-2">
                        <div
                          onClick={() => {
                            if (selectedRule === rule._id) {
                              setSelectedRule(null);
                            } else {
                              setShippingRuleName(rule.name);
                              
                              setFrom(rule.from);
                              if (rule.type == 'weight') {
                                setType(shippingRangeOptions[1]);
                              } else {
                                setType(shippingRangeOptions[0]);
                              }
                              setTo(rule.to);
                              setShippingPrice(rule.shippingFee);
                              setSelectedRule(rule._id);
                            }
                          }}
                          className="text-blue-600 cursor-pointer"
                        >
                          {rule.name}
                        </div>
                        <div className="flex">
                          <div>
                            {formatPrice(rule.from)}{rule.type ==='weight' ? 'Kg' : '$'} - {formatPrice(rule.to)}{rule.type ==='weight' ? 'Kg' : '$'}
                          </div>
                          <div className="ml-auto">{formatPrice(rule.shippingFee)}$</div>
                        </div>
                        {selectedRule && selectedRule === rule._id && (
                          <div className="mt-2">
                            {/* Your additional content goes here */}
                            <form
                              className="border border-gray-50 bg-gray-50 p-2 rounded-sm"
                              onSubmit={handleUpdateShippingRule}
                            >
                              <div className="flex-col">
                                <label
                                  htmlFor="shippingRule"
                                  className="block  text-md font-normal font-['Poppins'] leading-7"
                                >
                                  {t('Name of Shipping Rule')}
                                </label>
                                <input
                                  type="text"
                                  value={shippingRuleName}
                                  className="block w-full   rounded-md py-2 pl-2 pr-20  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                                  id="shippingRule"
                                  required
                                  placeholder="Name of Shipping Rule"
                                  onChange={handleShippingRuleChange}
                                  style={{ borderWidth: '1px' }}
                                />
                              </div>
                              <div className="flex">
                                <div>
                                  <label
                                    htmlFor="type"
                                    className="block  text-md font-normal font-['Poppins'] leading-7"
                                  >
                                    {t('Type')}
                                  </label>
                                  <Select
                                    id="type"
                                    value={type}
                                    onChange={handleShippingRangeChange}
                                    options={shippingRangeOptions}
                                    className="block  w-[200px]    text-md font-normal font-['Poppins'] leading-7   placeholder:text-gray-400   mb-4  sm:leading-6"
                                  />
                                </div>

                                <div className="ml-auto">
                                  <label
                                    htmlFor="from"
                                    className="block  text-md font-normal font-['Poppins'] leading-7"
                                  >
                                    {t('From')}
                                  </label>
                                  <div className="relative  border-gray-400">
                                    <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
                                    <div className="flex">
                                      <input
                                        type="text"
                                        id="From"
                                        className="block  w-[150px]  rounded-md py-2 pl-2 pr-2  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                                        placeholder="From"
                                        value={from}
                                        onChange={handleFromChange}
                                        style={{ borderWidth: '1px' }}
                                        required
                                      />
                                      <div className="text-lg mt-2">
                                        {getSuffix()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="px-5 mt-10">
                                  <FaMinus />
                                </div>
                                <div>
                                  <label
                                    htmlFor="to"
                                    className="block  text-md font-normal font-['Poppins'] leading-7"
                                  >
                                    {t('To')}
                                  </label>
                                  <div className="relative  border-gray-400">
                                    <div className="pointer-events-none flex absolute inset-y-0 left-0   items-center pl-3"></div>
                                    <div className="flex">
                                      <input
                                        type="text"
                                        id="to"
                                        className="block w-[150px]   rounded-md py-2 pl-2 pr-2  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                                        placeholder="to"
                                        value={to}
                                        onChange={handleToChange}
                                        style={{ borderWidth: '1px' }}
                                        required
                                      />
                                      <div className="text-lg mt-2">
                                        {getSuffix()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="ShippingPrice"
                                  className="block  text-md font-normal font-['Poppins'] leading-7"
                                >
                                  {t('Shipping Price')}
                                </label>
                                <div className="relative  border-gray-400">
                                  <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
                                  <div className="flex">
                                    <input
                                      type="text"
                                      id="ShippingPrice"
                                      className="block w-[250px]   rounded-md py-2 pl-2 pr-20  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                                      placeholder="Shipping Fee"
                                      value={shippingPrice}
                                      onChange={handlePriceChange}
                                      style={{ borderWidth: '1px' }}
                                      required
                                    />
                                    <div className="text-lg mt-2">$</div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <button
                                  className="bg-gray-500 ml-auto mt-3 hover:bg-gray-400 h-8 w-20 px-2 py-1 rounded-md text-white"
                                  type="button"
                                  onClick={(e) => {
                                    setShippingRuleId(rule._id);
                                    handleDeleteShippingRule(e);
                                  }}
                                >
                                  {isSubmitting
                                    ? t('deleting...')
                                    : t('delete')}
                                </button>
                                <div className="w-full ml-auto flex-col justify-center items-center mt-6">
                                  <div className="flex">
                                    <button
                                      className="bg-gray-500 ml-auto  hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                                      type="button"
                                      onClick={() => setSelectedRule(null)}
                                    >
                                      {t('close')}
                                    </button>
                                    <button
                                      className={` ml-3 block  hover:bg-purple-600 text-white rounded-md px-4 py-1 
              ${isSubmitting ? 'disabled:bg-purple-200' : ''}`}
                                      onClick={(e) => {
                                        setShippingRuleId(rule._id);
                                        handleUpdateShippingRule(e);
                                      }}
                                      //onSubmit={handleSubmit}
                                      style={{
                                        backgroundColor: 'var(--themeBold)',
                                      }}
                                    >
                                      {isSubmitting
                                        ? t('Saving...')
                                        : t('Save')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ))}
            {fetchedCountries.map((country, index) => (
              <div key={index} className="w-[100%]">
                <div className="flex justify-center items-center">
                  <div className="border-gray-300 border-b w-[95%]"></div>
                </div>
                <div className="w-[100%] bg-white p-2">
                  <div className="flex">
                    <div>Country: {country.name}</div>
                    <div
                      onClick={() => {
                        setshippingCountrySubmit(country.name);
                        openModal2();
                      }}
                      className="text-red-600 cursor-pointer ml-auto"
                    >
                      Delete
                    </div>
                    <div
                      onClick={() => {
                        setshippingCountrySubmit(country.name);
                        openModal();
                      }}
                      className="text-blue-600 cursor-pointer ml-4"
                    >
                      AddShippingrule
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Modal
          isOpen={isModal2Open}
          onRequestClose={closeModal2}
          contentLabel="Example Modal"
          style={customStyles2}
        >
          <div>
            <div>Are you sure you want to Delete this Shipping Region?</div>
            <div className="flex">
              <div className="w-full ml-auto flex-col justify-center items-center mt-6">
                <div className="flex">
                  <button
                    className="bg-gray-500 ml-auto  hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                    type="button"
                    onClick={closeModal2}
                  >
                    {t('close')}
                  </button>
                  <button
                    className={` ml-3 block  hover:bg-purple-600 text-white rounded-md px-4 py-1 
              ${isSubmitting ? 'disabled:bg-purple-200' : ''}`}
                    onClick={deleteShippingRegionMethod}
                    //onSubmit={handleSubmit}
                    style={{ backgroundColor: 'var(--themeBold)' }}
                  >
                    {isSubmitting ? t('deleting...') : t('delete')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Modal>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <form className="" onSubmit={handleAddShippingRule}>
            <div className="flex-col">
              <label
                htmlFor="shippingRule"
                className="block  text-md font-normal font-['Poppins'] leading-7"
              >
                {t('Name of Shipping Rule')}
              </label>
              <input
                type="text"
                value={shippingRuleName}
                className="block w-full   rounded-md py-2 pl-2 pr-20  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                id="shippingRule"
                required
                placeholder="Name of Shipping Rule"
                onChange={handleShippingRuleChange}
                style={{ borderWidth: '1px' }}
              />
            </div>
            <div className="flex">
              <div>
                <label
                  htmlFor="type"
                  className="block  text-md font-normal font-['Poppins'] leading-7"
                >
                  {t('Type')}
                </label>
                <Select
                  id="type"
                  value={type}
                  onChange={handleShippingRangeChange}
                  options={shippingRangeOptions}
                  className="block  w-[200px]    text-md font-normal font-['Poppins'] leading-7   placeholder:text-gray-400   mb-4  sm:leading-6"
                />
              </div>

              <div className="ml-auto">
                <label
                  htmlFor="from"
                  className="block  text-md font-normal font-['Poppins'] leading-7"
                >
                  {t('From')}
                </label>
                <div className="relative  border-gray-400">
                  <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
                  <div className="flex">
                    <input
                      type="text"
                      id="From"
                      className="block  w-[180px]  rounded-md py-2 pl-2 pr-2  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                      placeholder="From"
                      value={from}
                      onChange={handleFromChange}
                      style={{ borderWidth: '1px' }}
                      required
                    />
                    <div className="text-lg mt-2">{getSuffix()}</div>
                  </div>
                </div>
              </div>
              <div className="px-5 mt-10">
                <FaMinus />
              </div>
              <div>
                <label
                  htmlFor="to"
                  className="block  text-md font-normal font-['Poppins'] leading-7"
                >
                  {t('To')}
                </label>
                <div className="relative  border-gray-400">
                  <div className="pointer-events-none flex absolute inset-y-0 left-0   items-center pl-3"></div>
                  <div className="flex">
                    <input
                      type="text"
                      id="to"
                      className="block w-[200px]   rounded-md py-2 pl-2 pr-2  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                      placeholder="to"
                      value={to}
                      onChange={handleToChange}
                      style={{ borderWidth: '1px' }}
                      required
                    />
                    <div className="text-lg mt-2">{getSuffix()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="ShippingPrice"
                className="block  text-md font-normal font-['Poppins'] leading-7"
              >
                {t('Shipping Price')}
              </label>
              <div className="relative  border-gray-400">
                <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3"></div>
                <div className="flex">
                  <input
                    type="text"
                    id="ShippingPrice"
                    className="block w-[250px]   rounded-md py-2 pl-2 pr-20  text-md font-normal font-['Poppins'] leading-7 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4  sm:leading-6"
                    placeholder="Shipping Fee"
                    value={shippingPrice}
                    onChange={handlePriceChange}
                    style={{ borderWidth: '1px' }}
                    required
                  />
                  <div className="text-lg mt-2">$</div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-full ml-auto flex-col justify-center items-center mt-6">
                <div className="flex">
                  <button
                    className="bg-gray-500 ml-auto  hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                    type="button"
                    onClick={closeModal}
                  >
                    {t('close')}
                  </button>
                  <button
                    className={` ml-3 block  hover:bg-purple-600 text-white rounded-md px-4 py-1 
              ${isSubmitting ? 'disabled:bg-purple-200' : ''}`}
                    type="submit"
                    //onSubmit={handleSubmit}
                    style={{ backgroundColor: 'var(--themeBold)' }}
                  >
                    {isSubmitting ? t('Saving...') : t('Save')}
                  </button>
                </div>
                {/* {message && (
              <div>
                <div
                  id="successMessage"
                  // ref={successMessageRef}
                  className={` bg-white my-4 flex h-14 items-center rounded-md w-3/5 al p-2`}
                >
                  <div
                    className={` bg-white w-80  rounded-md  ${
                      message.type === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    } `}
                  >
                    {message.text}
                  </div>
                </div>
              </div>
            )} */}
              </div>
            </div>
          </form>
          {/* <div>
              {!successMessage && <p className="text-center">{t('confirmDelete')}</p>}
              {successMessage && (
                <p className="text-green-600 text-center">{successMessage}</p>
              )}
              {errMsg && <p className="text-red-600 text-center">{errMsg}</p>}
              <div className="flex justify-center mt-5">
                <button
                  className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
                  type="button"
                  onClick={closeModal}
                >
                  {t('close')}
                </button>
                {!successMessage && <button
                  className={`${
                    isDeleting ? 'disabled:bg-red-400' : ''
                  } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
                  type="button"
                  onClick={handleDelete}
                >
                  {isDeleting ? t('deleting') : t('delete')}
                </button>}
              </div>
            </div> */}
          <ToastContainer />
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
}

export default ShippingRule;
