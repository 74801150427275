import { useContext, useEffect, useState } from "react";
import Card from "./card";
import CustomPagination from "./customPagination";
import { useGettTrendingProductMutation } from "../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";
import { WelcomeLoader } from "../contexts/LoaderContext";

const TrendingProds = () => {
  const { t } = useTranslation();
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [fetchedItems, setFetchedItems] = useState([]);
  const { setIsTrendigLoading } = useContext(WelcomeLoader);

  const [gettTrendingProduct, { isdkksdLoading }] =
    useGettTrendingProductMutation();
  const handleCustomPageChange = async (newPage) => {
    const response = await gettTrendingProduct({ page: newPage });

    if (response) {
      const { items, totalItems, totalPages } = response?.data;
      setFetchedItems(items);
      setTotalItems(totalItems);
      setTotalPages(totalPages);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await gettTrendingProduct({ page: 1 });
      console.log("this is it ");
      console.log(response);
      if (response) {
        const { items, totalItems, totalPages } = response?.data;
        setFetchedItems(items);
        setTotalItems(totalItems);
        setTotalPages(totalPages);
        setIsTrendigLoading(false);
      }
    };
    fetchData();
  }, [gettTrendingProduct]);

  return (
    <>
      <div className="font-bold text-2xl  mt-16 mb-4  border-b border-solid border-gray-500 pb-2">
        {t("trendingproducts")}
      </div>
      <div className="scrollable">
        <div className="flex  md:flex-wrap gap-4 scrollpart w-full">
          {fetchedItems &&
            fetchedItems.map((item, index) => <Card key={index} item={item} />)}
        </div>
      </div>
      <CustomPagination
        totalItems={totalItems}
        itemsPerPage={32}
        onPageChange={handleCustomPageChange}
      />
    </>
  );
};

export default TrendingProds;
