import { useEffect, useState } from 'react';
import {
  addCanceledOrders,
  addDeliveredOrders,
  addOntheWayOrder,
  addPendingOrder,
  addPreparingOrder,
  removeFromCanceledOrder,
  removeFromDeliveredOrder,
  removeFromOnthewayOrder,
  removeFromPendingOrder,
  removeFromPreparingOrder,
  selectCurrentPendingOrders,
} from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import '../../styles/buyerProfile.css';

import { useUpdateOrderStatusMutation } from '../../features/auth/authApiSlice';
import { useTranslation } from 'react-i18next';
import PDFViewerComponent from '../PDFViewer';

function BuyerOrderDetail({ index, order, orderStatus }) {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(orderStatus);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusSuccessMsg, setstatusSuccessMsg] = useState(null);
  const [statusErrMsg, setstatusErrMsg] = useState(null);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const [updateOrderStatus, { isLoading }] = useUpdateOrderStatusMutation();
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(false);
  const handleDetailsToggle = () => {
    setShowDetails(!showDetails);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowFatura = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        key={order.order._id}
        className={`bg-white  rounded-md hover:bg-teal-50`}
      >
        <div
          className="felxColumnforMobile md:flex md:flex-wrap md:items-center rounded-md  mt-4  w-full "
          style={{ transition: 'height 2s, width 2s, background 2s' }}
        >
          <div className="ml-2 flex  items-center">
            <img
              src={order.order.avatarUrl}
              className="m-2 h-8 w-8 rounded-md "
            ></img>
            <p className="text-md text-gray-500">{order.order.itemName}</p>
          </div>
          <p className="ml-4 md:ml-20">
            <span className="font-extralight">{t('seller')}:</span>
            {order.order.sellerName}
          </p>
          <div className="flex  ml-4 md:ml-auto">
            <p className="mr-1">{t('selleremail')}:</p>
            <p className="opacity-75" style={{ opacity: '0.75' }}>
              {order.order.sellerEmail}
            </p>
          </div>
          {/* {order.order.orderStatus === 'delivered' ? (
            <div>
              <button
                className="bg-purple-700 hover:bg-purple-600 px-2 py-1 text-white mx-5 mb-2"
                onClick={handleShowFatura}
              >
                Show Fatura
              </button>
            </div>
          ) : (
            <div></div>
          )} */}
          {isModalOpen && (
            <div className="">
              <div className="" onClick={handleCloseModal}></div>
              <div className="">
                <span className="" onClick={handleCloseModal}>
                  &times;
                </span>
                <PDFViewerComponent order={order} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default BuyerOrderDetail;
