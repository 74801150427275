import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useUpdateBuyerByAdminMutation,
  useUpdateBuyerMutation,
  useUpdateOrderStatusMutation,
  useUpdateSellerBusinnessStatusMutation,
} from "../../features/auth/authApiSlice";
import {
  addBlockedSeller,
  addPendingSeller,
  addVerifiedBuyer,
  addVerifiedSeller,
  removeFromBlockedBuyer,
  removeFromPendingSeller,
  removeFromVerifiedSeller,
  removeFromblockedSeller,
} from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";

function UserDetail({ index, user, accountStatus }) {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(() => {
    if (accountStatus.includes("Verified")) {
      return "verified";
    } else if (accountStatus.includes("Pending")) {
      return "pending";
    } else if (accountStatus.includes("Blocked")) {
      return "blocked";
    } else {
      // Set a default value if accountStatus doesn't match the expected patterns
      return "pending";
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusSuccessMsg, setstatusSuccessMsg] = useState(null);
  const [statusErrMsg, setstatusErrMsg] = useState(null);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const [updateSellerBusinnessStatus, { isLoading }] =
    useUpdateSellerBusinnessStatusMutation();
  const [updateBuyerByAdmin, { isBuyerLoading }] =
    useUpdateBuyerByAdminMutation();

  const handleChangeSubmit = async () => {
    try {
      setIsSubmitting(true);
      setstatusErrMsg(null);
      setstatusSuccessMsg(null);

      if (
        accountStatus === "Pending Seller" ||
        accountStatus === "Verified Seller" ||
        accountStatus === "Blocked Seller"
      ) {
        const userData = await updateSellerBusinnessStatus({
          email: user.email,
          businessStatus: selectedStatus,
        });

        dispatch(removeFromPendingSeller({ sellerId: user._id }));
        dispatch(removeFromblockedSeller({ sellerId: user._id }));

        dispatch(
          addVerifiedSeller({
            number: userData.data[1],
          })
        );

        if (selectedStatus === "pending") {
          dispatch(addPendingSeller({ sellerId: user._id }));
        } else if (selectedStatus === "blocked") {
          dispatch(addBlockedSeller({ sellerId: user._id }));
        }
      } else {
        const userData = await updateBuyerByAdmin({
          email: user.email,
          accountStatus: selectedStatus,
        });

        dispatch(removeFromBlockedBuyer({ buyerId: user._id }));
        dispatch(
          addVerifiedBuyer({
            number: userData.data[1],
          })
        );

        if (selectedStatus === "blocked") {
          dispatch(addVerifiedSeller({ buyerId: user._id }));
        }
      }
      // Handle the response from the mutation if needed

      setstatusSuccessMsg("Status Updated Successfully.");
    } catch (error) {
      console.error("Error changing status:", error);
      setstatusErrMsg("Unable to change Status");
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleAdditionalInfo = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div
        key={user._id}
        className={`bg-white mt-4 pb-1  rounded-md hover:bg-teal-50`}
        onClick={toggleAdditionalInfo}
      >
        <div
          className="flex flex-wrap items-center rounded-md  mt-4  w-full "
          style={{ transition: "height 2s, width 2s, background 2s" }}
        >
          {accountStatus !== "Pending Seller" && (
            <img
              src={user.imgUrl}
              className={`m-2 h-8 w-8 rounded-md ${
                !user.imgUrl ? "bg-blue-500" : "" // Apply blue background if buyerImg is null
              }`}
            ></img>
          )}
          <p className="text-md ml-2 w-40 text-gray-500">
            {accountStatus === "Verified Buyer" ||
            accountStatus === "Blocked Buyer"
              ? user.fullName
              : user.name}
          </p>
          <p className="ml-10 w-48">
            <span className="font-extralight">{t("email")}</span>
            {user.email}
          </p>
          <div className="ml-auto">
            <label htmlFor="orderStatus" className="ml-10">
              {t("accountStatus")}
            </label>
            <select
              id="orderStatus"
              value={selectedStatus}
              onChange={handleStatusChange}
              className="px-5 rounded-md py-1 "
            >
              {accountStatus === "Verified Buyer" ||
              accountStatus === "Blocked Buyer" ? (
                <>
                  {" "}
                  <option value="verified">{t("verified")}</option>
                  <option value="blocked">{t("blocked")}</option>
                </>
              ) : (
                <>
                  {" "}
                  <option value="pending">{t("pending")}</option>
                  <option value="verified">{t("verified")}</option>
                  <option value="blocked">{t("blocked")}</option>
                </>
              )}
            </select>
          </div>
          <button
            onClick={handleChangeSubmit}
            className={` ${
              isSubmitting ? "disabled:bg-violet-400" : ""
            }   bg-violet-800 ml-auto hover:bg-violet-800 h-8 my-1 mt-1  mx-2 px-2 py-1 rounded-md text-white`}
          >
            {isSubmitting ? "Processing..." : t("changeStatus")}
          </button>

          {statusSuccessMsg && (
            <p className="text-green-600 ">{statusSuccessMsg}</p>
          )}
          {statusErrMsg && <p className="text-red-600">{statusErrMsg}</p>}
        </div>
        {showDetails && (
          <div class="flex gap-8 flex-wrap mt-2 ml-2 p-2 border rounded-md">
            <div>
              <p>{t("country")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.country}
              </span>
            </div>

            <div>
              <p>{t("state")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.state}
              </span>
            </div>

            <div>
              <p>{t("city")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.city}
              </span>
            </div>

            <div>
              <p>{t("streetAddress")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.streetAddress}
              </span>
            </div>

            <div>
              <p>{t("phoneNumber")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.phoneNumber}
              </span>
            </div>

            <div>
              <p>{t("postalCode")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {user?.postalCode}
              </span>
            </div>
            {user.role === process.env.REACT_APP_SELLER_ROLE && (
              <>
                <div>
                  <p>{t("accountNumber")} </p>
                  <span
                    className="text-opacity-70 text-sm"
                    style={{ opacity: 0.9 }}
                  >
                    {user?.accountNumber}
                  </span>
                </div>

                <div>
                  <p>{t("additionalAccountNumber")} </p>
                  <span
                    className="text-opacity-70 text-sm"
                    style={{ opacity: 0.9 }}
                  >
                    {user?.additionalAccountNumber}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default UserDetail;
