import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { FaMinusCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import {
  useAddPrivacyPolicyyMutation,
  useDeletePrivacyPolicyyMutation,
  useEditPrivacyPolicyyMutation,
  useGetPrivacyPolicyyMutation,
  useStripePayMutation,
} from "../../features/auth/authApiSlice";

const ParagraphBody = ({
  paragraphBody,
  handleBodyChange,
  index,
  handleBodyDelete,
}) => {
  const [thispargraph, setThisParagraph] = useState(paragraphBody);
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <div className="flex ">
        <label className="block text-md font-medium leading-6 text-gray-900">
          {t("body")}
        </label>
        <div
          onClick={() => handleBodyDelete(index)}
          className="ml-auto cursor-pointer relative top-6"
        >
          {index !== 0 && <FaMinusCircle color="#ea580c" size={"16"} />}
        </div>
      </div>
      <textarea
        type="text"
        value={thispargraph}
        className="w-full border-gray-400 h-32 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
        required
        onBlur={() => {
          handleBodyChange(index, thispargraph);
          console.log(thispargraph);
        }}
        placeholder="Body"
        onChange={(e) => {
          setThisParagraph(e.target.value);
        }}
        style={{ borderWidth: "1px" }}
      ></textarea>
    </>
  );
};

const PrivacyPolicyForm = ({
  onDelete,
  policy,
  handlechangeUpdateNumber,
  type,
}) => {
  const { t } = useTranslation();

  const [addPrivacyPolicyy, { isLoading: addingprivacypolcy }] =
    useAddPrivacyPolicyyMutation();
  const [editPrivacyPolicyy, { isLoading: editingprivacypolicy }] =
    useEditPrivacyPolicyyMutation();
  const [deletePrivacyPolicyy, { isLoading: isdeleting }] =
    useDeletePrivacyPolicyyMutation();
  const [title, setTitle] = useState(policy.title);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const [paragraph, setParagraph] = useState(policy.paragraph);
  const handleBodyChange = (index, data) => {
    const updatedParagraphs = [...paragraph];
    updatedParagraphs[index] = data;
    setParagraph(updatedParagraphs);
  };
  const handleBodyDelete = (i) => {
    // Filter out the deleted policy from the state
    const updatedParagraphes = paragraph.filter(
      (paragraph, index) => index !== i
    );
    setParagraph(updatedParagraphes);
  };

  const addNewParagraph = () => {
    setParagraph([...paragraph, ""]);
  };
  const handleDelete = async (e) => {
    console.log(policy);
    console.log("policy");
    if (policy.isNew) {
      console.log(policy._id);
      // If it's a new policy (not yet saved), delete it directly from the state
      onDelete(policy._id);
    } else {
      // If it's an existing policy, use the mutation to delete it from the server
      try {
        await deletePrivacyPolicyy({ id: policy._id }).unwrap();
        handlechangeUpdateNumber();

        toast.success(`${type} Deleted`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        console.error("Unable to Delete:", error);
        toast.error(`Unable to Delete ${type}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customStyles = {
    content: {
      maxWidth: "400px", // Adjust the maximum width as needed
      maxHeight: "150px", // Adjust the maximum height as needed
      margin: "auto",
    },
  };

  const handleSubmit = async (e) => {
    console.log(paragraph);
    e.preventDefault();
    try {
      if (policy.isNew) {
        console.log("new");
        const result = await addPrivacyPolicyy({
          type,
          title,
          paragraph,
        }).unwrap();
        console.log(result);
        handlechangeUpdateNumber();
        toast.success(`${type} Added`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.log("not new");
        const result = await editPrivacyPolicyy({
          id: policy._id,
          title,
          paragraph,
        }).unwrap();
        console.log(result);
        handlechangeUpdateNumber();
        toast.success(`${type} Edited`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch {
      toast.error("Unable to do the operation", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <div className="border p-4 mb-8 rounded-md shadow-md">
      <div className="flex">
        <h2 className="text-xl font-bold mb-4">{policy.title}</h2>
        <div onClick={openModal} className="ml-auto cursor-pointer">
          <MdDelete color="#ea580c" size={"30"} />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <label className="block text-md font-medium leading-6 text-gray-900">
          {t("title")}
        </label>
        <input
          type="text"
          value={title}
          className="w-full h-10 border-gray-400 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
          required
          placeholder="Title"
          onChange={handleTitleChange}
          style={{ borderWidth: "1px" }}
        />
        {paragraph.map((paragraph, index) => (
          <div key={index}>
            {" "}
            <ParagraphBody
              paragraphBody={paragraph}
              index={index}
              handleBodyChange={handleBodyChange}
              handleBodyDelete={handleBodyDelete}
            />
          </div>
        ))}
        <div className="flex">
          <div onClick={addNewParagraph} className="ml-auto cursor-pointer">
            <FaPlusCircle color="#ea580c" size={"30"} />
          </div>
        </div>
        <button
          type="submit"
          className="bg-violet-800 hover:bg-violet-700 text-white px-4 py-2 rounded-md "
        >
          {addingprivacypolcy || editingprivacypolicy
            ? "Loading..."
            : policy.isNew
            ? t("addParagraph")
            : t("editParagraph")}
        </button>
      </form>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div>
          <p className="text-center">
            {" Are You Sure You want to delete this Privacy Policy?"}
          </p>

          <div className="flex justify-center mt-5">
            <button
              className="bg-gray-500 hover:bg-gray-400 w-20 px-2 py-1 rounded-md text-white"
              type="button"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              className={`${
                isdeleting ? "disabled:bg-red-400" : ""
              } bg-red-600 hover:bg-red-500 w-20 mx-4 px-2 py-1 rounded-md text-white`}
              type="button"
              onClick={handleDelete}
            >
              {isdeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const AddPrivacyPolicy = ({ type }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [privacyPolicies, setPrivacyPolicy] = useState([]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const [getPrivacyPolicyy, { isLoading: isGettiing }] =
    useGetPrivacyPolicyyMutation();
  const [updateNumber, setUpdateNumber] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getPrivacyPolicyy({ type }).unwrap();
        console.log("result");
        console.log(result);

        setPrivacyPolicy(result);

        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [updateNumber, type]);
  function generateRandomText(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomText += charset[randomIndex];
    }

    return randomText;
  }
  const handleDeletePolicy = (policyId) => {
    // Filter out the deleted policy from the state
    const updatedPolicies = privacyPolicies.filter(
      (policy) => policy._id !== policyId
    );
    setPrivacyPolicy(updatedPolicies);
  };

  const handlechangeUpdateNumber = () => {
    setUpdateNumber(updateNumber + 1);
  };

  const addNewComponent = () => {
    const randomText = generateRandomText(20);

    const newPolicy = {
      _id: randomText,
      title: "",
      paragraph: [""],
      isNew: true,
    };
    setPrivacyPolicy([...privacyPolicies, newPolicy]);
    console.log(privacyPolicies);
  };

  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t("add")} {t(`${type}`)}
        </div>
        {privacyPolicies?.length !== 0 &&
          privacyPolicies?.map((policy) => (
            <PrivacyPolicyForm
              onDelete={handleDeletePolicy}
              key={policy._id}
              policy={policy}
              handlechangeUpdateNumber={handlechangeUpdateNumber}
              type={type}
            />
          ))}
        <div>
          <button
            type="button"
            onClick={addNewComponent}
            className="bg-violet-800 hover:bg-violet-700 text-white px-4 py-2 rounded-md "
          >
            <FaPlusCircle color="orange" size={"24"} />
          </button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddPrivacyPolicy;
