import { apiSlice } from '../../app/api/apiSlice';

export const stripeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    performPayement: builder.query({
      query: (body) => ({ url: '/stripe/stripe', method: 'POST', body }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { usePerformPayementQuery } = stripeApiSlice;
