// components/AddCategoryForm.js
import React, { useState, useEffect } from "react";
import {
  useCreateCatagoryMutation,
  useGetCatagoriesByTypeMutation,
} from "../../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";

const AddCategoryForm = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [type, setType] = useState("mainCatagory");
  const [parentCategory, setParentCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createCatagory, { isLoading: createCatagoryIsLoading }] =
    useCreateCatagoryMutation();
  const [getCatagoriesByType, { isLoading: getCatagoriesByTypeIsLoading }] =
    useGetCatagoriesByTypeMutation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await getCatagoriesByType().unwrap();

        setCategories(result);

        setIsLoading(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [ErrMsg, setErrMsg] = useState(null);

  const handleAddAnother = async (e) => {
    e.preventDefault();

    setName("");
    setType("mainCatagory");
    setParentCategory(null);
    setSuccessMsg(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Make a request to your backend API to add the new category
    try {
      const response = await createCatagory({
        name,
        type,
        parent: parentCategory,
      });
      const result = await getCatagoriesByType().unwrap();

      setCategories(result);

      setSuccessMsg("Catagory Added successfully");
    } catch (e) {
      setErrMsg("unable to add Catagory");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div
        className={`  px-6 w-full py-4 bg-gray-100`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        {/* Add your list of items or content in this section */}
        <div className="w-full mb-10 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          {t("addCategories")}
        </div>
        {isLoading ? (
          <p>{t("loading")}...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label className="block">{t("categoryName")}:</label>

            <input
              className="w-full h-10  border-gray-400   border 
               focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault01"
              required
              type="text"
              value={name}
              placeholder="Catagory Name"
              onChange={(e) => setName(e.target.value)}
            />
            <label className="block">{t("type")}:</label>

            <select
              className="w-full h-10  border-gray-400   border 
               focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
              id="validationDefault01"
              required
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="mainCatagory">{t("mainCategory")}</option>
              <option value="subCatagory">{t("subCategory")}</option>
              <option value="childCatagory">{t("childCategory")}</option>
            </select>
            {type !== "mainCatagory" &&
              (type === "subCatagory" ? (
                <label>
                  {t("parentCategory")}:
                  <select
                    value={parentCategory}
                    onChange={(e) => setParentCategory(e.target.value)}
                    className="w-full h-10  border-gray-400   border 
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                  >
                    <option value="">Select Parent Category</option>
                    {categories
                      .filter((category) => category.type === "mainCatagory")
                      .map((mainCategory) => (
                        <option key={mainCategory._id} value={mainCategory._id}>
                          {mainCategory.name}
                        </option>
                      ))}
                  </select>
                </label>
              ) : (
                <label>
                  {t("parentCategory")}:
                  <select
                    value={parentCategory}
                    onChange={(e) => setParentCategory(e.target.value)}
                    className="w-full h-10  border-gray-400   border 
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                    style={{ backgroundColor: "#e2e8f0" }}
                  >
                    <option value="">{t("selectParentCategory")}</option>
                    {categories
                      .filter((category) => category.type === "subCatagory")
                      .map((mainCategory) => (
                        <option key={mainCategory._id} value={mainCategory._id}>
                          {mainCategory.name}
                        </option>
                      ))}
                  </select>
                </label>
              ))}
            <button
              className="bg-violet-800 hover:bg-violet-700 text-white px-2 py-1 rounded-md"
              type="submit"
            >
              {isSubmitting ? t("adding") : t("addCategory")}
            </button>
            {successMsg && (
              <div className="flex gap-4  items-center">
                <div className="bg-white text-green-600 p-2 rounded-md mt-3">
                  {t("catagorysuccessMsg")}
                </div>
                <button
                  onClick={handleAddAnother}
                  className="bg-violet-800 mt-3 h-8 hover:bg-violet-700 px-2 py-1 text-white rounded-md"
                >
                  {t("addAnother")}
                </button>
              </div>
            )}
            {ErrMsg && (
              <p bg-white text-red-600 p-2 rounded-md mt-3>
                {t("catagoryerrMsg")}
              </p>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default AddCategoryForm;
