import { useEffect, useState } from "react";
import {
  addCanceledOrders,
  addDeliveredOrders,
  addOntheWayOrder,
  addPendingOrder,
  addPreparingOrder,
  removeFromCanceledOrder,
  removeFromDeliveredOrder,
  removeFromOnthewayOrder,
  removeFromPendingOrder,
  removeFromPreparingOrder,
  selectCurrentPendingOrders,
} from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useUpdateOrderStatusMutation } from "../../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";

function OrderDetail({ index, order, orderStatus }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(orderStatus);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusSuccessMsg, setstatusSuccessMsg] = useState(null);
  const [statusErrMsg, setstatusErrMsg] = useState(null);
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const [updateOrderStatus, { isLoading }] = useUpdateOrderStatusMutation();

  const handleChangeSubmit = async () => {
    try {
      setIsSubmitting(true);
      setstatusErrMsg(null);
      setstatusSuccessMsg(null);

      const orderData = await updateOrderStatus({
        id: order._id,
        orderStatus: selectedStatus,
      });

      // Handle the response from the mutation if needed

      dispatch(removeFromPendingOrder({ orderId: order._id }));
      dispatch(removeFromPreparingOrder({ orderId: order._id }));
      dispatch(removeFromOnthewayOrder({ orderId: order._id }));
      dispatch(removeFromDeliveredOrder({ orderId: order._id }));
      dispatch(removeFromCanceledOrder({ orderId: order._id }));

      if (selectedStatus === "preparing") {
        dispatch(addPreparingOrder({ orderId: order._id }));
      } else if (selectedStatus === "ontheway") {
        dispatch(addOntheWayOrder({ orderId: order._id }));
      } else if (selectedStatus === "delivered") {
        dispatch(addDeliveredOrders({ orderId: order._id }));
      } else if (selectedStatus === "cancelled") {
        dispatch(addCanceledOrders({ orderId: order._id }));
      } else if (selectedStatus === "pending") {
        dispatch(addPendingOrder({ orderId: order._id }));
      }

      setstatusSuccessMsg("Status Updated Successfully.");
    } catch (error) {
      console.error("Error changing status:", error);
      setstatusErrMsg("Unable to change Status");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [showDetails, setShowDetails] = useState(false);
  const handleDetailsToggle = () => {
    setShowDetails(!showDetails);
  };
  return (
    <>
      <div
        key={order._id}
        className={`bg-white ${
          showDetails ? "pb-2 pr-2 " : ""
        } rounded-md hover:bg-teal-50`}
      >
        <div
          className="flex flex-wrap items-center rounded-md  mt-4  w-full "
          style={{ transition: "height 2s, width 2s, background 2s" }}
        >
          <img src={order.avatarUrl} className="m-2 h-8 w-8 rounded-md "></img>
          <p className="text-md w-32 text-gray-500">{order.itemName}</p>
          <p className="ml-16 ">
            <span className="font-extralight">{t("orderedBy")}</span>
            {order.buyerName}
          </p>
          <div className="ml-auto">
            <label htmlFor="orderStatus" className="mr-2">
              {t("orderStatus")}
            </label>
            <select
              id="orderStatus"
              value={selectedStatus}
              onChange={handleStatusChange}
              className="px-5 rounded-md py-1 "
            >
              <option value="pending">{t("pending")}</option>
              <option value="preparing">{t("preparing")}</option>
              <option value="ontheway">{t("ontheway")}</option>
              <option value="delivered">{t("delivered")}</option>
              <option value="cancelled">{t("cancelled")}</option>
            </select>
          </div>
          <button
            onClick={handleDetailsToggle}
            className="bg-violet-800 hover:bg-teal-500 px-2 py-1 rounded-md text-sm text-white ml-14 mr-4"
          >
            {t("details")}
          </button>
        </div>
        {showDetails && (
          <div className=" flex gap-8 flex-wrap mt-2 ml-2 p-2 border rounded-md">
            {/* Display details here (country, city, phone number, etc.) */}
            <div>
              <p>{t("country")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerCountry}
              </span>
            </div>
            <div>
              <p>{t("state")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerState}
              </span>
            </div>
            <div>
              <p>{t("city")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerCity}
              </span>
            </div>
            <div>
              <p>{t("phoneNumber")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerPhoneNumber}
              </span>
            </div>
            <div>
              <p>{t("postalCode")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerPostalCode}
              </span>
            </div>
            <div>
              <p>{t("streetAddress")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerStreetAddress}
              </span>
            </div>
            <div>
              <p>{t("homeNumber")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerHomeNUmber}
              </span>
            </div>
            <div>
              <p>{t("email")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.buyerEmail}
              </span>
            </div>
            <div>
              <p>{t("sellerEmail")} </p>
              <span
                className="text-opacity-70 text-sm"
                style={{ opacity: 0.9 }}
              >
                {order.sellerEmail}
              </span>
            </div>
            <button
              onClick={handleChangeSubmit}
              className={` ${
                isSubmitting ? "disabled:bg-violet-400" : ""
              }   bg-violet-800  hover:bg-violet-700 h-8 px-2 py-1 rounded-md text-white`}
            >
              {isSubmitting ? t("processing") : t("changeStatus")}
            </button>
            {statusSuccessMsg && (
              <p className="text-green-600 ">{statusSuccessMsg}</p>
            )}
            {statusErrMsg && <p className="text-red-600">{statusErrMsg}</p>}
          </div>
        )}
      </div>
    </>
  );
}
export default OrderDetail;
