import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCurrentPage,
  setCurrentPage,
} from "../features/auth/authSlice";
import { useTranslation } from "react-i18next";

const CustomPagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const { t } = useTranslation();
  //   const [currentPage, setCurrentPage] = useState(1);
  const currentPage = useSelector(selectCurrentCurrentPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const dispatch = useDispatch();

  const prevPage = useRef(currentPage);

  useEffect(() => {
    if (currentPage !== prevPage.current) {
      onPageChange(currentPage);
      prevPage.current = currentPage;
    }
  }, [currentPage, onPageChange]);

  const handlePageChange = (newPage) => {
    //console.log(totalPages);
    //console.log(currentPage);
    //console.log('newPage');

    //console.log(newPage);
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      dispatch(setCurrentPage({ page: newPage }));
      //console.log('new currentPage');

      //console.log(currentPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`${
            currentPage === i
              ? "active bg-violet-800 rounded-sm text-white w-8 h-8 text-center"
              : ""
          } mx-5`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-center mt-10 bg-white py-2">
      <div className="custom-pagination">
        <button
          className={`${
            currentPage === 1
              ? "opacity-70"
              : "bg-gray-400 hover:bg-gray-300 text-white px-2 py-1 rounded-md"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t("previous")}
        </button>
        {renderPageNumbers()}
        <button
          className={`${
            currentPage === totalPages
              ? "opacity-70"
              : "bg-gray-400 hover:bg-gray-300 text-white px-2 py-1 rounded-sm"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
