import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-gray-200 px-[4%] py-8 h-max flex flex-wrap justify-between gap-y-6">
        <div className="min-w-[19rem]">
          <div className="text-violet-800 text-[35px] font-bold font-['Poppins'] leading-7 tracking-tight">
            Saletoeurope
          </div>
          <div className="text-opacity-80  text-base mt-4 mb-2">
            {t("Footer.time")}
          </div>

          <div>
            <span className="mt-2">{t("Footer.support")}</span>
          </div>
          <div className="mt-2">Whatsapp.</div>
          <div className="mt-2">+4915112359297</div>
        </div>

        <div className="min-w-[19rem]">
          <h3 className="text-black text-opacity-70 font-semibold mb-2">
            {t("Footer.Company")}
          </h3>
          <ul className="list-none">
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/">
                {t("home")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/login">
                {t("Footer.login_signin")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/contact">
                {t("Footer.Contact")}
              </a>
            </li>
          </ul>
        </div>

        <div className="min-w-[19rem]">
          <h3 className="text-black text-opacity-70 font-semibold mb-2">
            Links
          </h3>
          <ul className="list-none">
            <li className="mt-1">
              <a
                className="text-opacity-80  text-sm"
                href="https://www.facebook.com"
              >
                facebook
              </a>
            </li>
            <li className="mt-1">
              <a
                className="text-opacity-80  text-sm"
                href="https://twitter.com"
              >
                twitter
              </a>
            </li>
            <li className="mt-1">
              <a
                className="text-opacity-80  text-sm"
                href="https://www.instagram.com"
              >
                instagram
              </a>
            </li>
          </ul>
        </div>

        <div className="min-w-[19rem]">
          <h3 className="text-black text-opacity-70 font-semibold mb-2">
            {t("Footer.E_Commerce")}
          </h3>
          <ul className="list-none">
            <li className="mt-1">
              <a
                className="text-opacity-80  text-sm"
                href="/Right_Of_Withdrawal"
              >
                {t("Footer.RightOfWithdrawal")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/Conditions">
                {t("Footer.Conditions")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/Imprint">
                {t("Footer.Imprint")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/Privacy_Policy">
                {t("Footer.Privacy_Policy")}
              </a>
            </li>
            <li className="mt-1">
              <a className="text-opacity-80  text-sm" href="/Cookie_Policy">
                {t("Footer.Cookie_Policy")}
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <div
        className="flex w-full py-3 justify-center items-center
    bg-zinc-300 border-t border-neutral-100"
      >
        <div className="text-opacity-80  text-base">
          Copyright © 2024 saletoeurope | By{" "}
          <a className=" text-indigo-600" href="https://ibrahimali.net">
            Ibrahim Ali
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
