import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import Navbar from "../Navbar";
import { TfiShoppingCartFull } from "react-icons/tfi";
import { PiShoppingCartFill } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";

import {
  selectCurrentName,
  selectCurrentCart,
  selectSelectedProfileBar,
  setSelectedProfileBar,
  setUser,
  setBuyer,
  setCredentials,
  setCheckout,
  selectCurrentTotalCartItems,
  selectCurrentTotalprice,
  selectCurrentFetchedCartItems,
  setGlobalCartItems,
  setQuantityofCartItems,
  selectCurrentUserId,
  selectCurrentPendingOrders,
  selectCurrentPreparingOrders,
  selectCurrentOntheWayOrders,
  selectCurrentCanceledOrders,
  selectCurrentDeliveredOrders,
  selectCurrentUserImgUrl,
  selectCurrentEmail,
  selectCurrentCountry,
  selectCurrentRegion,
  selectCurrentCity,
  selectCurrentStreetAddress,
  selectCurrentAdditionalStreetAddress,
  selectCurrentZipCode,
  selectCurrentPhoneNumber,
  selectCurrentPostalCode,
  selectCurrentHomeNumber,
  selectCurrenRole,
  setAllOrderStatus,
} from "../../features/auth/authSlice";
import {
  useGetCartItemsMutation,
  useGetShippingRulesMutation,
  useRemoveCartMutation,
  useStripePayMutation,
} from "../../features/auth/authApiSlice";
import "../../styles/buyerProfile.css";
import { FaHome, FaShoppingCart } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { useState } from "react";
// import Watch from '../../assets/watch.jpeg';
import Cart from "./cart";
import EditProfile from "./EditProfile";
import { useLocation, useNavigate } from "react-router-dom";
import CheckOutButton from "../../features/stripe_test/stripe";
import NotifyBuyerOrder from "./BuyerSocketIo";
import BuyerOrderDetailsPage from "./buyerOrderDetailsPage";
import { useTranslation } from "react-i18next";
import ResetPasswordBuyer from "./ResetPaswordBuyer";
import Footer from "../Footer";
import PaypalCheckoutButton from "./paypalCheckout";

function BuyerProfile() {
  const [removeCart, { isRemoveCArtLoading }] = useRemoveCartMutation();

  const country = useSelector(selectCurrentCountry);
  const region = useSelector(selectCurrentRegion);
  const city = useSelector(selectCurrentCity);
  const streetAddress = useSelector(selectCurrentStreetAddress);
  const additionalStreetAddress = useSelector(
    selectCurrentAdditionalStreetAddress
  );
  const zipCode = useSelector(selectCurrentZipCode);
  const phoneNumber = useSelector(selectCurrentPhoneNumber);
  const postalCode = useSelector(selectCurrentPostalCode);
  const homeNumber = useSelector(selectCurrentHomeNumber);

  const { t } = useTranslation();
  const currentRole = useSelector(selectCurrenRole);
  const name = useSelector(selectCurrentName);
  const cart = useSelector(selectCurrentCart);
  const buyerId = useSelector(selectCurrentUserId);
  const SelectedProfileBar = useSelector(selectSelectedProfileBar);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [getCartItems, { isLoading }] = useGetCartItemsMutation();
  const [stripePay, { isStripeLoading }] = useStripePayMutation();

  const [selectedSidebarItem, setSelectedSidebarItem] = useState("Dashboard");

  const [cartItems, setCartItems] = useState([]);
  const [isCartLoading, SetIsCartLoading] = useState(false);
  const [isCheckouting, setIsCheckOuting] = useState(false);

  const product = {
    description: "Design+Code React Hooks Course",
    price: 19,
  };
  const email = useSelector(selectCurrentEmail);

  useEffect(() => {
    const fetchData = async () => {
      SetIsCartLoading(true);
      try {
        const itemsData = await Promise.all(
          cart.map(async (itemId) => {
            if (!itemId) {
              const userData = await removeCart({
                name,
                email,
                cartItemId: itemId,
              }).unwrap();
              dispatch(
                setUser({
                  id: userData._id,
                  email: userData.email,
                  name: userData.fullName,
                  role: userData.role,
                  accountStatus: userData.accountStatus,
                  cart: userData.cart,
                })
              );
            } else {
              const cartItemData = await getCartItems({ itemId });

              if (!cartItemData.data) {
                const userData = await removeCart({
                  name,
                  email,
                  cartItemId: itemId,
                }).unwrap();
                dispatch(
                  setUser({
                    id: userData._id,
                    email: userData.email,
                    name: userData.fullName,
                    role: userData.role,
                    accountStatus: userData.accountStatus,
                    cart: userData.cart,
                  })
                );
                return;
              }
              return cartItemData;
            }
          })
        );

        if (cart) {
          setCartItems(itemsData);
          dispatch(
            setGlobalCartItems({
              cartItems: itemsData,
            })
          );
        }
      } catch (error) {
        // Handle error (e.g., log an error message, show a notification)
        console.error("Error fetching items:", error);
      }
    };

    // Call the fetchData function
    fetchData();
    SetIsCartLoading(false);
  }, [cart]);

  useEffect(() => {
    // Check if the user is already logged in
    if (name && location.pathname === "/login") {
      // If logged in, navigate to a different route (e.g., home/dashboard)
      navigate("/"); // Update the route as needed
    }
  }, [name, navigate]);

  const [getShippingRules, { isShippingRulesLoading }] =
    useGetShippingRulesMutation();

  const [globalShippingRules, setGlobalShippingRules] = useState(null);

  function getadditionalPrice(shippingruls, itemresult) {
    let addtionalPrice = 0;
    if (
      currentRole &&
      currentRole === process.env.REACT_APP_BUYER_ROLE &&
      country
    ) {
      shippingruls?.map((rule, index) => {
        if (
          rule.country === country &&
          itemresult?.weight >= rule.from &&
          itemresult?.weight <= rule.to
        ) {
          addtionalPrice = rule.shippingFee;
        }
      });
    }
    return addtionalPrice;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shippingRules = await getShippingRules().unwrap();
        setGlobalShippingRules(shippingRules);

        const calculateTotals = () => {
          let totalPrice = 0;

          if (cart) {
            if (cartItems && cartItems.length > 0) {
              for (const item of cartItems) {
                if (
                  item &&
                  item.data &&
                  item.data.price &&
                  item.data.price.length > 0
                ) {
                  totalPrice += parseFloat(
                    item.data.price[0] +
                      parseFloat(getadditionalPrice(shippingRules, item.data))
                  );
                } else {
                  console.error("Invalid item or item data:", item);
                } // Assuming your price is a string, convert it to a number
              }

              dispatch(
                setQuantityofCartItems({
                  quantity: 1,
                })
              );
            }
          }

          return { totalPrice };
        };

        const updateCartTotals = () => {
          const { totalPrice } = calculateTotals();
          const totalItems = cartItems.length;

          // Dispatch actions to update Redux state
          dispatch(
            setCheckout({
              totalCartItems: totalItems,
              totalPrice: totalPrice,
            })
          );
        };

        updateCartTotals();
      } catch (error) {
        console.error("Error fetching shipping rules:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [cartItems, dispatch]);

  const itemTotal = useSelector(selectCurrentTotalCartItems);
  const itemTotalPrice = useSelector(selectCurrentTotalprice);
  const fetchedCartfromback = useSelector(selectCurrentFetchedCartItems);

  // const items = [
  //   ['Laptop', '256 SSD', Watch, 5, '256$'],
  //   ['Laptop', '256 SSD', Watch, 5, '256$'],
  //   ['Laptop', '256 SSD', Watch, 5, '256$'],
  //   ['Laptop', '256 SSD', Watch, 5, '256$'],
  //   ['Laptop', '256 SSD', Watch, 5, '256$'],
  // ];

  const handelLogout = () => {
    dispatch(
      setCredentials({
        user: null,
        accessToken: null,
        refreshtoken: null,
      })
    );
    dispatch(
      setUser({
        id: null,
        email: null,
        name: null,
        role: null,
        accountStatus: null,
        cart: null,
      })
    );
    dispatch(
      setBuyer({
        country: null,
        region: null,
        city: null,
        streetAddress: null,
        phoneNumber: null,
        postalCode: null,
        homeNumber: null,
        additionalStreetAddress: null,
        zipCode: null,
      })
    );
    dispatch(setAllOrderStatus());

    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    navigate("/");
  };

  const [paypalOrders, setPayPalOrders] = useState([]);
  const [paypalOrders2, setPayPalOrders2] = useState([]);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [showPayPalButtonMain, setShowPayPalButtonMain] = useState(false);

  const paywithPayPal = async () => {
    if (
      !country &&
      !region &&
      !city &&
      !streetAddress &&
      !additionalStreetAddress &&
      !zipCode &&
      !phoneNumber &&
      !postalCode &&
      !homeNumber
    ) {
      toast.warning("Please fill address info in your profile.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const orders = [];

    if (fetchedCartfromback) {
      fetchedCartfromback.forEach((item) => {
        const order = {
          itemId: item.data._id,
          price:
            item.data.price[0] +
            parseFloat(getadditionalPrice(globalShippingRules, item.data)),
          quantity: item.data.quantity,
          buyerId: buyerId,
          isUsingCoupon: false,
          couponId: "653d77701dc0d80c33c22746",
          color: item.data.color,
        };
        orders.push(order);
      });
    }
    const fetchedOrderList = orders.map((order, index) => {
      return {
        description: "Payment description",
        amount: {
          value: order.price * order.quantity,
        },
        reference_id: index,
      };
    });

    setPayPalOrders2(fetchedOrderList);

    setPayPalOrders(orders);
    setShowPayPalButton(!showPayPalButton);
  };

  const handleCheckoutButton = async () => {
    if (
      !country &&
      !region &&
      !city &&
      !streetAddress &&
      !additionalStreetAddress &&
      !zipCode &&
      !phoneNumber &&
      !postalCode &&
      !homeNumber
    ) {
      toast.warning("Please fill address info in your profile.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setIsCheckOuting(true);

    const orders = [];

    if (fetchedCartfromback) {
      fetchedCartfromback.forEach((item) => {
        const order = {
          itemId: item.data._id,
          quantity: item.data.quantity,
          buyerId: buyerId,
          isUsingCoupon: false,
          couponId: "653d77701dc0d80c33c22746",
          color: item.data.color,
        };
        orders.push(order);
      });
    }

    const userData = await stripePay({ orders }).unwrap();
    setIsCheckOuting(false);

    window.location = userData.url;
  };
  const pendingOrders = useSelector(selectCurrentPendingOrders);
  const preparingOrders = useSelector(selectCurrentPreparingOrders);
  const onthewayOrders = useSelector(selectCurrentOntheWayOrders);
  const canceledOrders = useSelector(selectCurrentCanceledOrders);
  const deliveredOrders = useSelector(selectCurrentDeliveredOrders);

  const renderContent = () => {
    switch (SelectedProfileBar) {
      case "Dashboard":
        return (
          <div className=" flex-col  dashboardWidth dashboardlgWidth">
            <div className={`flex flex-wrap gap-4 heightForDiv rounded-lg `}>
              <div
                onClick={() => {
                  dispatch(
                    setSelectedProfileBar({
                      SelectedProfileBar: "OntheWay Orders",
                    })
                  );
                }}
                className="firstRowWidth over:scale-105 hover:duration-300 flex-col gap-3  h-40  mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="flex justify-center">
                  <div
                    className="text-white hover:scale-105 hover:duration-300 mt-2 h-16 w-16 rounded-full flex justify-center items-center"
                    style={{ backgroundColor: "var(--themeBold)" }}
                  >
                    <i class="bx bxs-cart-alt bx-md"></i>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <p className="my-4">{t("ontheway")}</p>
                    <p className="text-center font-bold">
                      {onthewayOrders.length}
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  dispatch(
                    setSelectedProfileBar({
                      SelectedProfileBar: "Preparing Orders",
                    })
                  );
                }}
                className="firstRowWidth hover:scale-105 hover:duration-300 flex-col gap-3  h-40  mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="flex justify-center">
                  <div
                    className="text-white hover:scale-105 hover:duration-300 mt-2 h-16 w-16  rounded-full flex justify-center items-center"
                    style={{ backgroundColor: "var(--themeBold)" }}
                  >
                    <i class="bx bxs-cart-alt bx-md"></i>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <p className="my-4">{t("preparingorders")}</p>
                    <p className="text-center font-bold">
                      {preparingOrders.length}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-wrap heightForDiv mt-4 gap-4 rounded-lg `}
            >
              <div
                onClick={() => {
                  dispatch(
                    setSelectedProfileBar({
                      SelectedProfileBar: "Pending Orders",
                    })
                  );
                }}
                className="firstRowWidth hover:scale-105 hover:duration-300 flex-col gap-3  h-40  mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="flex justify-center">
                  <div
                    className="text-white  hover:scale-105 hover:duration-300 mt-2 h-16 w-16  rounded-full flex justify-center items-center"
                    style={{ backgroundColor: "var(--themeBold)" }}
                  >
                    <i class="bx bxs-cart-alt bx-md"></i>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <p className="my-4">{t("pendingorders")}</p>
                    <p className="text-center font-bold">
                      {pendingOrders.length}
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  dispatch(
                    setSelectedProfileBar({
                      SelectedProfileBar: "Delivered Orders",
                    })
                  );
                }}
                className="firstRowWidth hover:scale-105 hover:duration-300 flex-col gap-3  h-40  mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="flex justify-center">
                  <div
                    className="text-white hover:scale-105 hover:duration-300 mt-2 h-16 w-16  rounded-full flex justify-center items-center"
                    style={{ backgroundColor: "var(--themeBold)" }}
                  >
                    <i class="bx bxs-cart-alt bx-md"></i>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <p className="my-4">{t("deliveredorders")}</p>
                    <p className="text-center font-bold">
                      {deliveredOrders.length}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-wrap gap-4 heightForDiv mariginTop rounded-lg`}
            >
              <div
                onClick={() => {
                  dispatch(
                    setSelectedProfileBar({
                      SelectedProfileBar: "Cancelled Orders",
                    })
                  );
                }}
                className="firstRowWidth lastrowWidth over:scale-105 hover:duration-300 flex-col gap-3  h-40  mr-3 rounded-md bg-white justify-center items-center"
              >
                <div className="flex justify-center">
                  <div
                    className="text-white hover:scale-105 hover:duration-300 mt-2 h-16 w-16 rounded-full flex justify-center items-center"
                    style={{ backgroundColor: "var(--themeBold)" }}
                  >
                    <i class="bx bxs-cart-alt bx-md"></i>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div>
                    <p className="my-4">{t("cancelledorders")}</p>
                    <p className="text-center font-bold">
                      {canceledOrders.length}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "Profile":
        return (
          <div className="flex-col  EditProfileWidth">
            <EditProfile></EditProfile>
            <div></div>
          </div>
        );

      case "Reset Password":
        return (
          <div className="flex-col  EditProfileWidth">
            <ResetPasswordBuyer></ResetPasswordBuyer>
          </div>
        );
      case "Cart":
        return (
          <div className="flexForsmall md:flex">
            {isCartLoading ? (
              <p style={{ width: "450px" }}>Loading...</p>
            ) : (
              <div className="flex-col">
                <div className="flexOrderForSmall flex-col">
                  {cart &&
                    cartItems.map(
                      (item, index) =>
                        item?.data && (
                          <Cart
                            key={index}
                            title={item.data.itemName}
                            description={item.data.description}
                            imageUrl={item.data.avatarUrl?.[0]}
                            price={item.data.price?.[0]}
                            rating={item.data.rating}
                            stock={item.data.quantity}
                            id={item.data._id}
                            colorImgList={item.data.colorImgList}
                            color={item.data.color}
                            globalShippingRulescart={globalShippingRules}
                            additionalPriceCart={parseFloat(
                              getadditionalPrice(globalShippingRules, item.data)
                            )}
                          />
                        )
                    )}
                </div>
              </div>
            )}
            <div
              // style={{ height: '400px' }}
              className="checkOutwidthForSmall sticky top-0 checkoutvarticalPad md:ml-8  bg-white md:w-3/4 py-5 rounded-md px-5 flex-col items-center  "
            >
              <button
                onClick={() => {
                  if (fetchedCartfromback) {
                    const regex = /^\w+$/;
                    for (const item of fetchedCartfromback) {
                      if (
                        !(item.data?.color.length === 1 || !item.data?.color)
                      ) {
                        if (!regex.test(item.data.color[0])) {
                          toast.warning(
                            "Please Choose Color For each Item Before Checking out.",
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );
                          setIsCheckOuting(false);
                          return; // Stop the function here
                        }
                      }
                    }
                    handleCheckoutButton();
                  }
                }}
                className={` hover:bg-purple-600 w-full  rounded-3xl px-4 py-1 text-center text-white text-lg ${
                  isCheckouting ? "disabled:bg-purple-500" : ""
                }`}
                style={{ height: "45px", backgroundColor: "var(--themeBold)" }}
              >
                {isCheckouting ? "Processing..." : t("gotocheckout")}
              </button>

              <div className="mt-2">
                {t("items")} ({itemTotal})
              </div>
              <div
                className="flex mt-8 pt-4 mb-5"
                style={{ borderTopWidth: "1px" }}
              >
                <span className="text-xl ">{t("subtotal")}: </span>{" "}
                <span className="text-xl justify-self-end  ml-auto">
                  EUR {itemTotalPrice}€
                </span>
              </div>
              <div style={{ borderTopWidth: "0.1px" }} className="border-black">
                <div className="mt-5 mb-2">{t("paywithpaypal")}</div>
                <button
                  onClick={() => {
                    if (fetchedCartfromback) {
                      const regex = /^\w+$/;
                      for (const item of fetchedCartfromback) {
                        if (
                          !(item.data?.color.length === 1 || !item.data?.color)
                        ) {
                          if (!regex.test(item.data.color[0])) {
                            toast.warning(
                              "Please Choose Color For each Item Before Checking out.",
                              {
                                position: toast.POSITION.TOP_RIGHT,
                              }
                            );
                            setIsCheckOuting(false);
                            return; // Stop the function here
                          }
                        }
                      }
                    }
                    paywithPayPal();
                  }}
                  style={{ backgroundColor: "var(--themeBold)" }}
                  className=" mb-2 rounded-md hover:bg-purple-600 text-white px-2 py-1 "
                >
                  {t("paypal")}
                </button>
                {showPayPalButton && (
                  <div className={`${showPayPalButton ? "" : "hidden"} `}>
                    <div className="paypal-button-container">
                      <PaypalCheckoutButton
                        product={paypalOrders}
                        product2={paypalOrders2}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case "Logout":
        return (
          <div className="dashboardWidth flex-col  " style={{ width: "55%" }}>
            <div className="bg-white w-96 h-32 rounded-md">
              <p className="pl-2 py-4 text-lg text-gray-700 text-center">
                {t("logout_confirmation")}
              </p>
              <div className="flex justify-center">
                <button
                  onClick={handelLogout}
                  className="w-24 px-2 text-center py-1 rounded-md hover:bg-red-500 bg-red-600 text-white"
                >
                  {t("logout")}
                </button>
              </div>
            </div>
          </div>
        );
      // Add cases for other sidebar items as needed
      case "Pending Orders":
        return (
          <div className="dashboardWidth dashboardlgWidth">
            <BuyerOrderDetailsPage orderStatus="pending"></BuyerOrderDetailsPage>
          </div>
        );
      case "Preparing Orders":
        return (
          <div className="dashboardWidth dashboardlgWidth">
            <BuyerOrderDetailsPage orderStatus="preparing"></BuyerOrderDetailsPage>
          </div>
        );
      case "OntheWay Orders":
        return (
          <div className="dashboardWidth dashboardlgWidth">
            <BuyerOrderDetailsPage orderStatus="ontheway"></BuyerOrderDetailsPage>
          </div>
        );
      case "Delivered Orders":
        return (
          <div className="dashboardWidth dashboardlgWidth">
            <BuyerOrderDetailsPage orderStatus="delivered"></BuyerOrderDetailsPage>
          </div>
        );
      case "Cancelled Orders":
        return (
          <div className="dashboardWidth dashboardlgWidth">
            <BuyerOrderDetailsPage orderStatus="cancelled"></BuyerOrderDetailsPage>
          </div>
        );
      default:
        return null; // Render nothing by default
    }
  };
  // const customStyles = {
  //   height: SelectedProfileBar == 'Profile' ? '800px' : '',
  //   // Add more styles as needed
  // };

  const buyerImg = useSelector(selectCurrentUserImgUrl);
  return (
    <>
      <div className={` ${SelectedProfileBar == "Profile" ? "" : ""}`}>
        <Navbar></Navbar>
        <div
          className={` bg-slate-50 md:justify-center  md:gap-8 pt-12 heightFormobile h-full md:flex`}
          //style={customStyles}
        >
          <div
            className={`md:sticky md:top-0 mobilewhiteSize bg-white md:pt-7 md:flex-col   rounded-lg `}
          >
            <div className="photoNameCenter">
              <div className="md:flex   md:justify-center">
                <img
                  src={buyerImg}
                  className={`ml-2 md:ml-0 block md:h-24 h-12 w-16 md:w-24 rounded-md md:rounded-full ${
                    !buyerImg ? "bg-blue-500" : "" // Apply blue background if buyerImg is null
                  }`}
                />
              </div>
              <NotifyBuyerOrder></NotifyBuyerOrder>
              <div className="flex justify-center Name">
                <div className="mt-4  ">{name}</div>
              </div>
            </div>
            <div className="md:mt-6  ml-6 md:ml-0 mobile">
              <div
                className={`py-1 iconsWidth h-ful hover:bg-gray-100 ${
                  SelectedProfileBar == "Dashboard"
                    ? "borderIcons md:border-l-2  md:border-l-purple-700 text-purple-700"
                    : ""
                }`}
                style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
              >
                <div
                  className={`flex naviTopDown gap-2 items-center  w-full py-2 cursor-pointer`}
                  onClick={() => {
                    dispatch(
                      setSelectedProfileBar({
                        SelectedProfileBar: "Dashboard",
                      })
                    );
                  }}
                  justify-center
                  items-center
                >
                  <FaHome
                    className="opacity-70 md:ml-2"
                    style={{ fontSize: "24px" }}
                  />
                  <p
                    className="naviTextHidden text-opacity-75"
                    style={{ fontSize: "16px" }}
                  >
                    {t("dashboard")}
                  </p>
                </div>
              </div>

              <div
                className={`py-1 iconsWidth hover:bg-gray-100 ${
                  SelectedProfileBar == "Cart"
                    ? "borderIcons md:border-l-2  md:border-l-purple-700 text-purple-700"
                    : ""
                }`}
                style={{ borderBottomWidth: "1px" }}
              >
                <div
                  className={` flex naviTopDown gap-2 items-center  w-full py-2 cursor-pointer`}
                  onClick={() => {
                    dispatch(
                      setSelectedProfileBar({
                        SelectedProfileBar: "Cart",
                      })
                    );
                  }}
                >
                  <PiShoppingCartFill
                    className="opacity-70 ml-2"
                    style={{ fontSize: "24px" }}
                  />
                  <p
                    className="naviTextHidden text-opacity-75"
                    style={{ fontSize: "16px" }}
                  >
                    {t("cart")}
                  </p>
                </div>
              </div>
              <div
                className={`py-1 iconsWidth hover:bg-gray-100 ${
                  SelectedProfileBar == "Profile"
                    ? "borderIcons md:border-l-2  md:border-l-purple-700 text-purple-700"
                    : ""
                }`}
                style={{ borderBottomWidth: "1px" }}
              >
                <div
                  className={` flex naviTopDown gap-2 items-center  w-full py-2 cursor-pointer`}
                  onClick={() => {
                    dispatch(
                      setSelectedProfileBar({
                        SelectedProfileBar: "Profile",
                      })
                    );
                  }}
                >
                  <BiUser
                    className="opacity-70 ml-2"
                    style={{ fontSize: "24px" }}
                  />
                  <p
                    className="naviTextHidden text-opacity-75"
                    style={{ fontSize: "16px" }}
                  >
                    {t("profile")}
                  </p>
                </div>
              </div>
              <div
                className={`py-1 iconsWidth hover:bg-gray-100 ${
                  SelectedProfileBar == "Logout"
                    ? "borderIcons md:border-l-2  md:border-l-purple-700 text-purple-700"
                    : ""
                }`}
                style={{ borderBottomWidth: "1px" }}
              >
                <div
                  className={` flex naviTopDown gap-2 items-center  w-full py-2 cursor-pointer`}
                  onClick={() => {
                    dispatch(
                      setSelectedProfileBar({
                        SelectedProfileBar: "Logout",
                      })
                    );
                  }}
                >
                  <FiLogOut
                    className="opacity-70 ml-2"
                    style={{ fontSize: "24px" }}
                  />
                  <p
                    className="naviTextHidden text-opacity-75"
                    style={{ fontSize: "16px" }}
                  >
                    {t("logout")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {renderContent()}
        </div>
        <Footer></Footer>
      </div>
    </>
  );
}

export default BuyerProfile;
