import { useEffect, useState } from "react";
import {
  useGetSellerItemsByPageMutation,
  useSearchItemsOfSellerMutation,
} from "../../features/auth/authApiSlice";
import { selectCurrentUserId } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import SellerNavbar from "./SellerNavbar";
import SellerCard from "./sellerCard";
import { SiTruenas } from "react-icons/si";
import { useTranslation } from "react-i18next";

function AllProduct({ selectedSidebarItem, onSelectSidebarItem }) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const sellerId = useSelector(selectCurrentUserId);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // You can add logic here to handle the search query (e.g., filter products).
  };
  const [fetchedItems, setFetchedItems] = useState([]);

  const [searchItemsOfSeller, { isLoading }] = useSearchItemsOfSellerMutation();

  const handleSearch = async () => {
    setIsFetching(true);
    setIsSearching(true);

    const SearchResults = await searchItemsOfSeller({
      query: searchQuery,
      sellerId: sellerId,
    });
    setFetchedItems(SearchResults.data);

    setIsFetching(false);
    // Add logic here to handle the search action based on the searchQuery.
  };
  const [isFetching, setIsFetching] = useState(false);

  const [getSellerItemsByPage, { isSellerLoading }] =
    useGetSellerItemsByPageMutation();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const page = 1;
        const result = await getSellerItemsByPage({
          sellerId: sellerId,
          page,
        }).unwrap();

        setFetchedItems(result);

        setIsFetching(false);
        // Update state with the fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [sellerId]);

  const handlePageChange = async (newPage) => {
    try {
      setIsFetching(true);
      const result = await getSellerItemsByPage({
        sellerId: sellerId,
        page: newPage,
      }).unwrap();

      setFetchedItems(result);
      setCurrentPage(newPage);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div
        className={` text-gray-800 px-6 py-4 bg-gray-100 w-full`}
        style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
      >
        <div className="w-full bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
          <span>{t("allProducts")}</span>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={t("searchPlaceholder")}
            className="ml-4 px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={handleSearch}
            className={`${
              isLoading ? "disabled:bg-violet-400" : ""
            } ml-2 px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md`}
          >
            {isLoading ? t("searching") : t("search")}
          </button>
        </div>
        {isFetching ? (
          <p>loading...</p>
        ) : (
          <div className="flex md:flex-wrap gap-4 scrollpart">
            {fetchedItems.map((item, index) => (
              <SellerCard
                key={index}
                item={item}
                selectedSidebarItem={selectedSidebarItem}
                onSelectSidebarItem={onSelectSidebarItem}
                handlePageChange={handlePageChange}
                page={currentPage}
              />
            ))}
          </div>
        )}
        {isSearching ? (
          <div>
            <button
              onClick={() => {
                setIsSearching(false);
                setSearchQuery("");
                handlePageChange(currentPage);
              }}
              className="mt-4 px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md mr-2"
            >
              {t("goBack")}
            </button>
          </div>
        ) : (
          <div className="mt-4 flex items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md mr-2"
              disabled={currentPage === 1}
            >
              {t("previous")}
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-4 py-1 bg-violet-800 hover:bg-violet-700 text-white rounded-md"
            >
              {t("next")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
export default AllProduct;
