import { useRef, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import Select from "react-select";
import { ImUpload3 } from "react-icons/im";
import { useSelector } from "react-redux";
import {
  selectCurrentCouponIdTobeEdited,
  selectCurrentUserId,
} from "../../features/auth/authSlice";
import { selectCurrentName } from "../../features/auth/authSlice";
import {
  useEditCouponMutation,
  useEditCouponPageMutation,
  useGetAllItemsOfSellerMutation,
  useGetChildCatagoriesSellerMutation,
  useGetCouponByIdMutation,
  useGetMainCatagoriesSellerMutation,
  useGetSubCatagoriesSellerMutation,
} from "../../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";

function EditCouponPage({ Id }) {
  const [couponFetched, setCouponFetched] = useState(null);
  const SellerId = useSelector(selectCurrentUserId);
  const couponId = useSelector(selectCurrentCouponIdTobeEdited);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const Maincatagories = await getMainCatagoriesSeller({
          sellerId: SellerId,
        });
        const Subcatagories = await getSubCatagoriesSeller({
          sellerId: SellerId,
        });
        const Childcatagories = await getChildCatagoriesSeller({
          sellerId: SellerId,
        });
        const ItemsList = await getAllItemsOfSeller({
          sellerId: SellerId,
        });
        const Coupon = await getCouponByID({
          couponId: couponId,
        });

        setCouponFetched(Coupon.data);

        setCouponTitle(Coupon.data.couponTitle);
        setDiscountAmount(Coupon.data.discountamount);
        setMaximumUsage(Coupon.data.maximumNo);
        setMaximumUsageBySingleUser(Coupon.data.maxUsageByuser);
        setExpiryDate(Coupon.data.expiryDate);
        setMainCatagoriesSeller(transformCategories(Maincatagories.data));
        setSubCatagoriesSeller(transformCategories(Subcatagories.data));
        setChildCatagoriesSeller(transformCategories(Childcatagories.data));
        SetItemsOfSeller(transformItems(ItemsList.data));

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetchData function when the component mounts or when 'Id' changes
  }, [SellerId]);

  const [editCoupon, { isLoading: EditCouponPageIsLoading }] =
    useEditCouponMutation();
  const [getMainCatagoriesSeller, { isLoading: getMAinCatagoriesIsLoading }] =
    useGetMainCatagoriesSellerMutation();
  const [getCouponByID, { isLoading: getCouponByIdIsLoading }] =
    useGetCouponByIdMutation();
  const [getSubCatagoriesSeller, { isLoading: getSubcatagoriesIsLoading }] =
    useGetSubCatagoriesSellerMutation();
  const [getChildCatagoriesSeller, { isLoading: getChildCatagoriesIsLoading }] =
    useGetChildCatagoriesSellerMutation();
  const [getAllItemsOfSeller, { isLoading: getAllSellerItemsIsLoading }] =
    useGetAllItemsOfSellerMutation();

  const [expiryDate, setExpiryDate] = useState(
    couponFetched?.expiryDate || null
  );
  const [couponCode, setCouponCode] = useState("");

  const [selectedColor, setSelectedColor] = useState("");
  const [mainCatagotiesSeller, setMainCatagoriesSeller] = useState(null);
  const [subCatagotiesSeller, setSubCatagoriesSeller] = useState(null);
  const [childCatagotiesSeller, setChildCatagoriesSeller] = useState(null);
  const [itemsOfSeller, SetItemsOfSeller] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Include 'Id' as a dependency to refetch data when 'Id' changes

  const [colorChoices, setColorChoices] = useState([]);
  const [message, setMessage] = useState(null); // Add message state

  const companyName = useSelector(selectCurrentName);

  const forWhichItemsOptions = [
    { label: t("forAll"), value: "forall" },
    { label: t("forMain"), value: "forMain" },
    { label: t("forSub"), value: "forSub" },
    { label: t("forChild"), value: "forChild" },
    { label: t("forSpecific"), value: "foreach" },
    // Add more main category options as needed
  ];

  function transformCategories(categories) {
    return categories.map((category) => ({
      label: category,
      value: category.toLowerCase(),
    }));
  }
  function transformItems(items) {
    return items.map((item) => ({ label: item.itemName, value: item._id }));
  }

  const mainsubchildcatagoryOptions = {
    forMain: mainCatagotiesSeller,
    forSub: subCatagotiesSeller,
    forChild: childCatagotiesSeller,
    foreach: itemsOfSeller,

    // Add subcatagory options for other main categories
  };

  const [couponTitle, setCouponTitle] = useState(
    couponFetched?.couponTitle || null
  );
  const [forWhichItems, setForWhichitems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsLabel, setSelectedItemsLabel] = useState([]);
  const [childCatagory, setChildCatagory] = useState(null);
  const [dimention, setDimention] = useState(null);
  const [color, setColor] = useState(null);
  const [customColor, setCustomColor] = useState("");
  const [showCustomColorInput, setShowCustomColorInput] = useState(false);

  const [discountAmount, setDiscountAmount] = useState(
    couponFetched?.discountamount || null
  );
  const [currency, setCurrency] = useState("USD"); // Set the initial currency value to USD
  const [quantity, setQuantity] = useState("");
  const [maximumUsage, setMaximumUsage] = useState(
    couponFetched?.maximumNo || null
  );
  const [maximumUsageBySingleUser, setMaximumUsageBySingleUser] = useState(
    couponFetched?.maxUsageByuser || null
  );
  const [couponType, setCouponType] = useState(null);
  const [description, setDescription] = useState("");
  const couponTypeOptions = [
    { value: "moneyOff", label: "Money Off" },
    { value: "percentageOff", label: "Percerntage Off" },
  ];

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleCouponTitleChange = (event) => {
    setCouponTitle(event.target.value);
  };

  const handleForWhichItemsChange = (selectedOption) => {
    setForWhichitems(selectedOption);
    setSelectedItem(null);
  };

  const handleDimentionChange = (selectedOption) => {
    setDimention(selectedOption);
  };
  const handleSelectedItemChange = (selectedOption) => {
    setSelectedItem(selectedOption);
  };

  const addAddtionalItem = (e) => {
    e.preventDefault();
    // Create a string representation of the color choice with quantity

    // Push it into the colorChoices array
    setSelectedItems([...selectedItems, selectedItem.value]);
    setSelectedItemsLabel([...selectedItemsLabel, selectedItem.label]);
    // Clear the selected color and quantity fields
    setSelectedItem("");
  };

  const removeSelectedItem = (index) => {
    // Filter the colorChoices array to remove the chosen color choice
    const updatedSelectedItems = selectedItems.filter((_, i) => i !== index);
    const updatedSelectedItemsLabel = selectedItemsLabel.filter(
      (_, i) => i !== index
    );

    setSelectedItems(updatedSelectedItems);
    setSelectedItemsLabel(updatedSelectedItemsLabel);
  };

  const updateColorQuantity = (index, quantity) => {
    // Update the quantity for a specific color choice
    const updatedColorChoices = [...colorChoices];
    updatedColorChoices[index].quantity = quantity;
    setColorChoices(updatedColorChoices);
  };

  // const handleColorQuantityChange = (colorValue, quantity) => {
  //   setColorQuantities({ ...colorQuantities, [colorValue]: quantity });
  // };
  const handleCustomColorChange = (event) => {
    const customColorValue = event.target.value;
    setCustomColor(customColorValue);

    // When the user specifies a custom color, set it as the selected color
    // You can customize the label and value as needed
    setColor({
      label: customColorValue,
      value: customColorValue.toLowerCase().replace(/\s/g, "_"),
    });
    setSelectedColor({
      label: customColorValue,
      value: customColorValue.toLowerCase().replace(/\s/g, "_"),
    });
  };
  const handleCouponTypeChange = (selectedOption) => {
    setCouponType(selectedOption);
  };

  const handleChildCatagoryChange = (selectedOption) => {
    setChildCatagory(selectedOption);
  };

  const handleDiscountAmountChange = (event) => {
    setDiscountAmount(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleMaximumUsage = (event) => {
    setMaximumUsage(event.target.value);
  };

  const handleMaximumUsageBYSingleUser = (event) => {
    setMaximumUsageBySingleUser(event.target.value);
  };
  const handleAddAnother = async (event) => {
    event.preventDefault();
    setCouponTitle("");
    setForWhichitems(null);
    setSelectedItem(null);
    setSelectedItems([]);
    setCouponType(null);
    setDiscountAmount();
    setMaximumUsage(null);
    setMaximumUsageBySingleUser("");
    setExpiryDate("");
    setMessage(null);
  };

  const successMessageRef = useRef(null);

  useEffect(() => {
    if (message && message.type === "success" && successMessageRef.current) {
      successMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let selectedItemList = [];

    if (selectedItems.length == 0 && selectedItem) {
      selectedItemList = [`${selectedItem.value}`];

      // Push it into the colorChoices array
    } else {
      selectedItemList = couponFetched.itemsChoosen;
    }

    // // Upload all selected images and wait for all promises to resolve

    try {
      let selectedItemListTotheForm;
      if (selectedItems.length === 0) {
        selectedItemListTotheForm = selectedItemList;
      } else {
        selectedItemListTotheForm = selectedItems;
      }

      const updatedForwhichItems = forWhichItems
        ? forWhichItems.value
        : couponFetched.forWhichItems;
      const updatedCouponType = couponType
        ? couponType.value
        : couponFetched.couponType;

      const userData = await editCoupon({
        id: couponId,
        couponTitle,

        forWhichItems: updatedForwhichItems,
        itemsChoosen: selectedItemListTotheForm,
        expiryDate,
        couponType: updatedCouponType,
        discountamount: parseInt(discountAmount),
        maximumNo: maximumUsage,
        maxUsageByuser: maximumUsageBySingleUser,
      }).unwrap();

      setMessage({ text: "Coupon Updated successfully!", type: "success" });

      // After all image uploads are complete, you can perform other actions
    } catch (error) {
      setMessage({
        text: "Failed to update Coupon. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`  px-6 py-4 bg-gray-100 w-full`}
          style={{ overflowY: "auto", height: "calc(100vh - 56px)" }}
        >
          <div className="w-full mb-4 bg-white h-16 flex items-center px-2 rounded-md  text-lg text-opacity-70">
            {t("createCoupon")}
          </div>
          <form className="" onSubmit={handleSubmit}>
            <div className="flex-col">
              <label
                htmlFor="validationDefault01"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t("couponTitle")}
                <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                value={couponTitle}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault01"
                required
                onChange={handleCouponTitleChange}
                placeholder="Item Name"
                style={{ borderWidth: "1px" }}
              />
            </div>
            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t("forWhichItems")}
                <span className="text-red-600">*</span>
              </div>
              <Select
                options={forWhichItemsOptions}
                value={forWhichItems}
                isSearchable={true}
                onChange={handleForWhichItemsChange}
              />
            </div>

            {forWhichItems && forWhichItems.value !== "forall" && (
              <div className="w-full flex-col mb-4">
                <div className="block text-md font-medium leading-6 text-gray-900">
                  Select
                  <span className="text-red-600">*</span>
                </div>
                <Select
                  options={mainsubchildcatagoryOptions[forWhichItems.value]}
                  value={selectedItem}
                  isSearchable={true}
                  onChange={handleSelectedItemChange}
                  required
                />
              </div>
            )}
            <button
              onClick={addAddtionalItem}
              className="bg-violet-800 text-white rounded-md hover:bg-violet-700 px-4 py-1 "
            >
              {t("addAnother")}
            </button>
            <ul className="bg-gray-100 p-4 rounded-lg space-y-2">
              {selectedItemsLabel.map((choice, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-white p-2 rounded"
                >
                  {choice}
                  <button
                    onClick={() => removeSelectedItem(index)}
                    className="px-2 py-1 ml-2 text-red-600 bg-red-100 rounded hover:bg-red-200 focus:outline-none"
                  >
                    {t("delete")}
                  </button>
                </li>
              ))}
            </ul>
            <div className="w-full flex-col mb-4">
              <div className="block text-md font-medium leading-6 text-gray-900">
                {t("couponType")}
                <span className="text-red-600">*</span>
              </div>
              <Select
                options={couponTypeOptions}
                value={couponType}
                onChange={handleCouponTypeChange}
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t("discountAmount")}
                <span className="text-red-600">*</span>
              </label>
              <div className="relative  border-gray-400">
                <div className="pointer-events-none absolute inset-y-0 left-0  flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="block w-full   rounded-md py-2 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:invalid:border-pink-500 focus:invalid:ring-pink-500 mb-4 sm:text-sm sm:leading-6"
                  placeholder="0.00"
                  value={discountAmount}
                  onChange={handleDiscountAmountChange}
                  style={{ borderWidth: "1px" }}
                  required
                />
              </div>
            </div>

            <div className="flex-col">
              <label
                htmlFor="validationDefault03"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t("maximumUsage")}
              </label>
              <input
                type="text"
                value={maximumUsage}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault03"
                onChange={handleMaximumUsage}
                placeholder={`Weight`}
                style={{ borderWidth: "1px" }}
              />
            </div>

            <div className="flex-col">
              <label
                htmlFor="validationDefault03"
                className="block text-md font-medium leading-6 text-gray-900"
              >
                {t("maxUsageBySingleUser")}
              </label>
              <input
                type="text"
                value={maximumUsageBySingleUser}
                className="w-full h-10  border-gray-400   
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded-md px-2 mb-4"
                id="validationDefault03"
                onChange={handleMaximumUsageBYSingleUser}
                placeholder={`Weight`}
                style={{ borderWidth: "1px" }}
              />
            </div>

            <div>
              <label htmlFor="expiryDate">{t("expiryDate")}:</label>
              <Datetime
                inputProps={{ id: "expiryDate" }}
                value={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                dateFormat="DD/MM/YYYY" // Customize the date format if needed
                closeOnSelect
                timeFormat={false} // Set to true if you want to include time
                input={true}
                isValidDate={(current) =>
                  current.isAfter(Datetime.moment().subtract(1, "day"))
                }
              />
            </div>

            <div className="w-full flex-col justify-center items-center mt-14">
              <button
                className={`bg-violet-800 w-64 block  hover:bg-violet-400 text-white rounded-md px-3 py-1 
              ${isSubmitting ? "disabled:bg-orange-200" : ""}`}
                type="submit"
                onSubmit={handleSubmit}
              >
                {isSubmitting ? t("submitting") : t("createCouponBtn")}
              </button>
              {message && (
                <div>
                  <div
                    id="successMessage"
                    ref={successMessageRef}
                    className={` bg-white my-4 flex h-14 items-center rounded-md w-3/5 al p-2`}
                  >
                    <div
                      className={` bg-white mr-5  rounded-md  ${
                        message.type === "success"
                          ? "text-green-500"
                          : "text-red-500"
                      } `}
                    >
                      {message.text}{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default EditCouponPage;
